/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import Card from '../../../common/components/card';
import { useTheme } from '@emotion/react';
import { useEffect, useState } from 'react';
import { Table, TableSection } from '../../../common/components/table';
import Paginator from './paginator';
import TooltipWrap from 'common/components/tooltip-wrap';
import { OrderItemSummed, adminGetOrderItems, withParams } from 'common/utils/make-api-request';

export default function OrderItemLeaderboard({...restProps}) {
  //bolt on computed field this endpoint provides
  const theme = useTheme();
  const [topTen, setTopTen] = useState<Array<OrderItemSummed>>([])
  const [page, setPage] = useState<number>(0)
  const pageQuantity = 10

    useEffect(() => {
      adminGetOrderItems(withParams().Limit(pageQuantity).Offset(page * pageQuantity))
      .then(res => {
          setTopTen(res)
      })
    }, [page])

    return (
        <div {...restProps}>
            <Card toggleable={false} css={{
                boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.5)",
                borderRadius: 10,
            }}>
            <header>
                <div style={{display: "flex", justifyContent: "space-between"}}>
                <TooltipWrap text="catalog items with the highest quantity redeemed">
                    <h3 css={{color: theme.palette.accent.color}}>Hot items</h3>
                </TooltipWrap>
                  <Paginator
                    setPage={setPage}
                    currentPage={page}
                    pageMaxLength={pageQuantity}
                    pageCurrentLength={topTen && topTen.length}
                    pageContainsData={topTen !== null}
                  />
                </div>
            </header>
            <Table>
                <TableSection as="thead">
                    <tr>
                        <th>Rank</th>
                        <th>Title</th>
                        <th>Value</th>
                        <th>Total Redeemed</th>
                    </tr>
                </TableSection>
                <TableSection as="tbody">
                    {topTen && topTen.map((item: OrderItemSummed, index: number) => (
                            <tr key={index}>
                                <td>{(page * pageQuantity) + (index + 1)}</td>
                                <td>{item.title}</td>
                                <td>{item.points}</td>
                                <td>{item.total_quantity}</td>
                            </tr>
                        ))}
                </TableSection>
            </Table>
            </Card>
        </div>
    )
}
