import { Dispatch } from 'redux';
import { getType, isActionOf } from 'typesafe-actions';

import User from '../types/user';
import { Api } from '../../common/redux/make-api';
import { USER } from '../../common/redux/types/feature';
import { UserAction, fetchUser, setUser } from './user-action-creators';
import { setLoader } from '../../common/redux/ui/loader/loader-action-creators';
import { setNotification } from '../../common/redux/ui/notification/notification-action-creators';

const makeUserRouteMiddleware = (userApi: Api<User>) => () => (next: Dispatch) => (
  action: UserAction
) => {
  next(action);

  switch (action.type) {
    case getType(fetchUser):
      next(setLoader(true, USER));
      next(userApi.actions.fetch());
      break;
    case getType(userApi.actions.fetchSuccess):
      next(setLoader(false, USER));
      if (isActionOf(userApi.actions.fetchSuccess, action)) {
        next(setUser(action.payload.data));
      }
      break;
    case getType(userApi.actions.createFailure):
      next(setLoader(false, USER));
      next(setNotification('failure', 'Could not fetch user profile', USER));
      break;
  }
};

export default makeUserRouteMiddleware;
