/** @jsxRuntime classic */
/** @jsx jsx */
import { faSpinnerThird } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { jsx } from '@emotion/core';
import { useSelector } from 'react-redux';
import { getCatalogLoadingState, getCSVGeneratorLoadingState, getImageUploadLoadingState, getOrderLoadingState, getPDFGeneratorLoadingState, getShopSettingsLoadingState, getSizeChartUploadLoadingState, getVariantUploadLoadingState } from '../../common/redux/ui/loader/loader-selectors';
import { useTheme } from '@emotion/react';


interface LoadingIndicatorProps {
  color?: string;
}

export default function LoadingIndicator({ color }: LoadingIndicatorProps) {
  const theme = useTheme();
  const ImageUploadState = useSelector(getImageUploadLoadingState)
  const SizeChartUploadState = useSelector(getSizeChartUploadLoadingState)
  const VariantUploadState = useSelector(getVariantUploadLoadingState)
  const CatalogLoadingState = useSelector(getCatalogLoadingState)
  const SettingsLoadingState = useSelector(getShopSettingsLoadingState)
  const PDFGeneratorLoadingState = useSelector(getPDFGeneratorLoadingState)
  const CSVGeneratorLoadingState = useSelector(getCSVGeneratorLoadingState)
  const OrderLoadingState = useSelector(getOrderLoadingState)

  return (
    <div className="loading-indicator">
      { PDFGeneratorLoadingState || CSVGeneratorLoadingState || ImageUploadState || SizeChartUploadState || VariantUploadState || SettingsLoadingState || CatalogLoadingState || OrderLoadingState? (
        <div css={{
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: 9999,
            width: "100vw",
            height: "100vh",
            transition: "opacity 0.2s",
            background: "rgba(0,0,0,0.5)",
        }}>
          <div css={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%"
          }}>
            <FontAwesomeIcon icon={faSpinnerThird} color={color ? color : theme.palette.accent.color} size="5x" spin/>
          </div>
        </div>
      ) : (
        <div/>
      )}
    </div>
  );
}
