/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { withTheme, Theme } from '@emotion/react';
import { useSelector } from 'react-redux';
import { getShopSettings } from '../../shop-settings/redux/shop-settings-selectors';
import { getFormattedRemainingUserPoints } from '../redux/user-selectors';

interface UserPointsBadgeProps {
  theme: Theme;
}

function UserPointsBadge({ theme }: UserPointsBadgeProps) {
  const shopSettings = useSelector(getShopSettings);
  const formattedUserPoints = useSelector(getFormattedRemainingUserPoints);

  return (
    <div
      css={{
        fontSize: '.875em',
        width: theme.spacing(6),
        height: theme.spacing(6),
        borderRadius: theme.spacing(6),
        lineHeight: `${theme.spacing(6)}px`,
        textAlign: 'center',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        color: theme.palette.accent.contrastColor,
        backgroundColor: theme.palette.accent.color,
        backgroundImage: `url(${shopSettings.shopPointsBadgeUrl})`,
      }}
      data-testid="shop-points-badge"
    >
      {formattedUserPoints}
    </div>
  );
}

export default withTheme(UserPointsBadge);
