/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import throttle from 'lodash.throttle';
import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { withTheme, Theme } from '@emotion/react';

import ShopLogo from './shop-logo';
import AdminNav from '../../admin/components/admin-nav/admin-nav';
import CartBadge from '../../cart/components/cart-badge';
import UserImageBadge from '../../user/components/user-image-badge';
import UserPointsBadge from '../../user/components/user-points-badge';
import { getShopSettings } from '../../shop-settings/redux/shop-settings-selectors';
import { getUser } from '../../user/redux/user-selectors';
import { USER_ROLE_ADMIN } from '../../user/types/user-role';

interface ShopHeaderProps {
  theme: Theme;
}

function ShopHeader({ theme }: ShopHeaderProps) {
  const shopSettings = useSelector(getShopSettings);
  const user = useSelector(getUser);
  const [scrollY, setScrollY] = useState(window.scrollY);
  useEffect(() => {
    const onScroll = throttle(() => {
      setScrollY(window.scrollY);
    }, 500);
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);
  return (
    <div
      css={{
        top: 0,
        left: 0,
        right: 0,
        position: 'fixed',
        display: 'flex',
        padding: theme.spacing(1),
        justifyContent: 'space-between',
        zIndex: theme.zIndex.header,
        background: theme.palette.body.background,
        boxShadow: scrollY === 0 ? 'none' : '0 0 5px 0 rgba(0, 0, 0, .15)',
      }}
    >
      <a href={shopSettings.homeUrl}>
        <ShopLogo />
      </a>
      <div css={{ display: 'flex' }}>
        <UserPointsBadge />
        <CartBadge css={{ marginLeft: theme.spacing(1.5) }} />
        <UserImageBadge css={{ marginLeft: theme.spacing(1.5) }} />
        {user.role === USER_ROLE_ADMIN && <AdminNav />}
      </div>
    </div>
  );
}

export default withTheme(ShopHeader);
