import { createAction, ActionType } from 'typesafe-actions';

import { CATALOG_ITEM_VARIANT } from '../../common/redux/types/feature';
import { CREATE, UPDATE, DELETE } from '../../common/redux/constants';
import { NewVariant, Variant } from '../../catalog/types/variant';

export const createCatalogItemVariant = createAction(`${CATALOG_ITEM_VARIANT} ${CREATE}`, action => (item: NewVariant) =>  action(item) );

export const updateCatalogItemVariant = createAction(`${CATALOG_ITEM_VARIANT} ${UPDATE}`, action => (item: Variant) =>  action(item) );

export const deleteCatalogItemVariant = createAction(`${CATALOG_ITEM_VARIANT} ${DELETE}`, action => (item: Variant) =>  action(item) );

export type CatalogItemVariantAction = ActionType<typeof updateCatalogItemVariant | typeof createCatalogItemVariant | typeof deleteCatalogItemVariant>;
