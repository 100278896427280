/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useDispatch, useSelector } from 'react-redux';

import EditableInventoryList from './editable-inventory-list';
import { getShopSettingsLoadingState } from '../../../common/redux/ui/loader/loader-selectors';
import { getInventory } from 'admin/inventory/redux/inventory-selectors';
import { useEffect } from 'react';
import { fetchInventory } from 'admin/inventory/redux/inventory-action-creators';

export default function EditableInventory() {
  const isLoading = useSelector(getShopSettingsLoadingState);
  const inventory = useSelector(getInventory);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchInventory());
  }, [dispatch])

  return (
    <EditableInventoryList
      entityName="Inventory"
      isLoading={isLoading}
      items={inventory}
    />
  );
}
