import React from "react";
import { HexColorPicker } from "react-colorful";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

interface PopoverProps {
  color: string;
  onChange: (color: string) => void;
}

export const PopoverPicker = (props: PopoverProps) => {

  const popover = (
    <Popover style={{boxShadow: "0 6px 12px rgba(0, 0, 0, 0.15)"}} id="popover-basic">
      <Popover.Body>
        <HexColorPicker color={props.color} onChange={props.onChange} />
      </Popover.Body>
    </Popover>
  );

  return (
    <OverlayTrigger trigger="click" placement="bottom-end" overlay={popover} rootClose={true}>
      <div style={{position: "relative"}}>
        <div
          style={{
            backgroundColor: props.color,
            width: "28px",
            height: "28px",
            borderRadius: "8px",
            border: "3px solid #fff",
            boxShadow: "0 0 0 1px rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(0, 0, 0, 0.1)",
            cursor: "pointer",
          }}
        />
      </div>
    </OverlayTrigger>
  )
}
