import { ConfigOptions, configFactory } from './common/utils/config-factory';

export interface AppConfig {
  apiUrl: string;
  sentryDsn: string;
}

const commonConfig: AppConfig = {
  apiUrl: '/api/v1', // use current url and add /api/v1 as a prefix to all requests
  sentryDsn: '', //'https://659979865c9d4ababb6d541b853bd669@sentry.io/1728318' // all environments for now
};

const envConfig: ConfigOptions<AppConfig> = {
  development: {
    ...commonConfig,
    apiUrl: process.env.REACT_APP_ENGAGE_STORE_API_URL || commonConfig.apiUrl,
  },
  production: {
    ...commonConfig,
    apiUrl: process.env.REACT_APP_ENGAGE_STORE_API_URL || commonConfig.apiUrl,
    sentryDsn: process.env.REACT_APP_ENGAGE_STORE_SENTRY_DSN || commonConfig.sentryDsn,
  },
  staging: {
    ...commonConfig,
  },
  test: {
    ...commonConfig,
  },
};

const appConfig = configFactory<AppConfig>(envConfig);

export default appConfig;
