import { getType } from 'typesafe-actions';
import { MiddlewareAPI, Dispatch } from 'redux';

import {
  CartAction,
  setCart,
  getCartFromCache,
  addCartToCache,
  removeCartFromCache,
} from './cart-action-creators';
import Cart from '../types/cart';
import { getCart } from './cart-selectors';
import assoc from '../../common/utils/assoc'
import { getCatalogArray } from '../../catalog/redux/catalog-selectors';
import { parseCartItemId } from '../cart-utils';
import { CartItemsById } from '../types/cart';

const CART_CACHE_ITEM_KEY = 'viddlerEngageStore_Cart';

const makeCartCacheMiddleware = (storage: Storage) => ({ getState }: MiddlewareAPI) => (
  next: Dispatch
) => (action: CartAction) => {
  next(action);
  switch (action.type) {
    case getType(getCartFromCache): {
      const cartCache = storage.getItem(CART_CACHE_ITEM_KEY);
      var newItems: CartItemsById = {};
      if (cartCache) {
        var cart: Cart = JSON.parse(cartCache);
        const items = Object.keys(cart.items).map((cartId) => {
          return parseCartItemId(cartId)
        })
        // validate
        const apiItems = getCatalogArray(getState())
        apiItems.map((catalogItem) => {
          items.forEach((cartItem, i) => {
            // eslint-disable-next-line eqeqeq
            if (cartItem[0] == catalogItem.id) {
              catalogItem.variants && catalogItem.variants.forEach((variant) => {
                // eslint-disable-next-line eqeqeq
                if (cartItem[1] == variant.id) {
                  console.log(`Item: ${cartItem} found, persisting in cart`);
                  newItems = assoc(`${cartItem[0]}_${cartItem[1]}`, cart.items[`${cartItem[0]}_${cartItem[1]}`], newItems)
                }
              })
            }
          })
          return null
        })

        cart.items = newItems ? newItems : cart.items
        next(setCart(cart));
      }
      break;
    }
    case getType(addCartToCache): {
      const cart = getCart(getState());
      storage.setItem(CART_CACHE_ITEM_KEY, JSON.stringify(cart));
      break;
    }
    case getType(removeCartFromCache): {
      storage.removeItem(CART_CACHE_ITEM_KEY);
    }
  }
};

export default makeCartCacheMiddleware;
