import { createSelector } from 'reselect';

import State, { UIState } from '../types/state';

const getUIState = (state: State) => state.ui;

export const getLoaderState = createSelector(
  getUIState,
  (uiState: UIState) => uiState.loader
);
