/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useCallback } from 'react';

import VariantPickerSelect from './variant-picker-select';
import { Variants, VariantAttrName } from '../../types/variant';
import { singleAttrVariantsUtils } from './variant-picker-utils';

interface SingleVariantPickerProps {
  variants: Variants;
  attrName: VariantAttrName;
  attr1Value: string
  setAttr1Value: (attr: string) => void;
  onPick: (variantId: string) => void;
}

export default function SingleVariantPicker({
  variants,
  attrName,
  attr1Value,
  setAttr1Value,
  onPick,
}: SingleVariantPickerProps) {
  const { getOptions, getVariantId } = singleAttrVariantsUtils(variants, attrName);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSelect = useCallback(
    (val: string) => {
      setAttr1Value(val);
      onPick(getVariantId(val));
    },
    [onPick, getVariantId]
  );

  return (
    <VariantPickerSelect
      name={attrName}
      value={attr1Value}
      options={getOptions()}
      onSelect={onSelect}
    />
  );
}
