/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import Card from '../../../common/components/card';
import { useTheme } from '@emotion/react';

import { useEffect, useState } from 'react';
import { Table, TableSection } from '../../../common/components/table';
import Paginator from './paginator';
import TooltipWrap from 'common/components/tooltip-wrap';
import { adminGetUserBalances, withParams, UserBalance } from 'common/utils/make-api-request';

export default function BalanceLeaderboard({...restProps}) {
  //bolt on computed field this endpoint provides
  const [topTen, setTopTen] = useState<Array<UserBalance>>([])
  const [page, setPage] = useState<number>(0)
  const pageQuantity = 10
  const theme = useTheme();

    useEffect(() => {
      adminGetUserBalances(withParams().Limit(pageQuantity).Offset(page * pageQuantity))
      .then(res => {
            setTopTen(res)
      })
    }, [page])

    return (
        <div {...restProps}>
            <Card toggleable={false} css={{
                boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.5)",
                borderRadius: 10,
            }}>
            <header>
                <div style={{display: "flex", justifyContent: "space-between"}}>
                  <TooltipWrap text="users who save the most points">
                    <h3 css={{color: theme.palette.accent.color}}>Penny Pinchers</h3>
                  </TooltipWrap>
                  <Paginator
                    setPage={setPage}
                    currentPage={page}
                    pageMaxLength={pageQuantity}
                    pageCurrentLength={topTen && topTen.length}
                    pageContainsData={topTen != null}
                  />
                </div>
            </header>
            <Table>
                <TableSection as="thead">
                    <tr>
                        <th>Rank</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Balance</th>
                    </tr>
                </TableSection>
                <TableSection as="tbody">
                    {topTen && topTen.map((user: UserBalance, index: number) => (
                            <tr key={index}>
                                <td>{(page * pageQuantity) + (index + 1)}</td>
                                <td>{user.firstName} {user.lastName}</td>
                                <td>{user.email}</td>
                                <td>{user.balance}</td>
                            </tr>
                    ))}
                </TableSection>
            </Table>
            </Card>
        </div>
    )
}
