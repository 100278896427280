/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useDispatch, useSelector } from 'react-redux';
import { withTheme, Theme } from '@emotion/react';

import Card from '../../common/components/card';
import UserProfile from '../components/user-profile';
import { Container } from '../../common/components/layout';
import { Heading, Small, P } from '../../common/components/typography';
import { getShopSettings } from '../../shop-settings/redux/shop-settings-selectors';
import UserParticipationHistory from '../../user-participation-history/components/user-participation-history';
import UserOrderHistory from '../../user-order-history/components/user-order-history';
import { getUserPointsBalance } from '../redux/user-selectors';
import Page from '../../common/components/page';
import { useEffect, useState } from 'react';
import { UserParticipation } from 'user-participation-history/types/user-participation-history';
import { getUserOrderHistory, getUserRewards } from 'common/utils/make-api-request';
import { setNotification } from 'common/redux/ui/notification/notification-action-creators';
import { USER } from 'common/redux/types/feature';
import { UserOrderItem } from 'user-order-history/types/user-order-history';
import { ordersAsItems } from 'common/utils/api-request-utils';

interface UserPageProps {
  theme: Theme;
}

function UserPage({ theme }: UserPageProps) {
  const shopSettings = useSelector(getShopSettings);
  const pointsBalance = useSelector(getUserPointsBalance);
  const dispatch = useDispatch();
  const [userRewards, setUserRewards] = useState<UserParticipation[]>([]);
  const [userOrderItems, setUserOrderItems] = useState<UserOrderItem[]>([]);

  useEffect(() => {
    getUserRewards()
      .then((rewards) => setUserRewards(rewards))
      .catch((err) => dispatch(setNotification('failure', err.message, USER)));

    getUserOrderHistory()
      .then(orders => setUserOrderItems(ordersAsItems(orders)))
      .catch((err) => dispatch(setNotification('failure', err.message, USER)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Page title="Your Account">
      <UserProfile />
      <Container css={{ [theme.breakpoint('md')]: { padding: theme.spacing(4) } }}>
        <Card css={{ borderTopRightRadius: '10px', borderTopLeftRadius: '10px', [theme.breakpoint('md')]: { padding: theme.spacing(4) } }}>
          <Heading
            as="h3"
            css={{
              textAlign: 'center',
              [theme.breakpoint('md')]: { textAlign: 'right' },
              fontSize: '1.5em',
              textTransform: 'capitalize',
            }}
          >
            Points Balance: {pointsBalance} <Small>Points</Small>
          </Heading>
          <Heading
            as="h3"
            css={{
              textTransform: 'capitalize',
              marginTop: theme.spacing(4),
              marginBottom: theme.spacing(1),
            }}
          >
            Participation History
          </Heading>
          <UserParticipationHistory userRewards={userRewards}/>
          <Heading
            as="h3"
            css={{
              textTransform: 'capitalize',
              marginTop: theme.spacing(4),
              marginBottom: theme.spacing(1),
            }}
          >
            Order History
          </Heading>
          <UserOrderHistory orderItems={userOrderItems}/>
        </Card>
        <P css={{  borderBottomRightRadius: '10px', borderBottomLeftRadius: '10px', backgroundColor: theme.palette.accent.color, color: theme.palette.body.color, padding: theme.spacing(2) }}>
          <strong>Things not adding up?</strong> First,{' '}
          <a href={shopSettings.homeUrl}
            css={{ color: theme.palette.accent.contrastColor }}
          >
            make sure you finished your videos
          </a>
          ! Points are only awarded when videos are watched 100% of the way through. Still have
          questions?{' '}
          <a
            css={{color: theme.palette.accent.contrastColor}}
            href={`mailto:${shopSettings.contactEmail}`}
          >
            Email us here
          </a>
          .
        </P>
      </Container>
    </Page>
  );
}

export default withTheme(UserPage);
