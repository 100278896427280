/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useTheme } from '@emotion/react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useCallback, useState, ChangeEvent, FormEvent } from 'react';

import Button from '../../../common/components/button';
import Select from '../../../common/components/select';
import ListItem from '../../../common/types/list-item';
import { Form, FormField, Input, FormRow } from '../../../common/components/form';
import { getShopSettings } from '../../../shop-settings/redux/shop-settings-selectors';
import { setNotification } from '../../../common/redux/ui/notification/notification-action-creators';
import { USER } from '../../../common/redux/types/feature';
import { adminAddUserPoints } from 'common/utils/make-api-request';

interface EditablePointsProps {
  updatedPoints(hasUpdated: boolean): void
}

export default function EditablePoints(props:EditablePointsProps) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const params = useParams<{ userId: string }>();
  const { pointTitles } = useSelector(getShopSettings);

  const [event, setEvent] = useState('');
  const [points, setPoints] = useState('');

  const handleSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      adminAddUserPoints(params.userId, event, Number.parseInt(points))
      .then(() => {
        dispatch(setNotification('success', 'User has recieved the points', USER));
        props.updatedPoints(true);
      }).catch(err => {
        dispatch(setNotification('failure', "there was some trouble giving the user points: " + err.message, USER));
      })
      setEvent('');
      setPoints('');
    },
    // deps: props
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, event, points, params]
  );

  const handleEventSelect = useCallback((e: ChangeEvent<HTMLSelectElement>) => {
    setEvent(e.target.value);
  }, []);

  const handlePointsChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setPoints(e.target.value);
  }, []);

  return (
    <Form onSubmit={handleSubmit}>
      <FormRow>
        <FormField css={{ marginTop: 0 }} inputId="event" label="Event">
          <Select id="event" value={event} onChange={handleEventSelect}>
            <option key="unset location" value="">
              Select event
            </option>
            {pointTitles &&
              pointTitles.map((event: ListItem) => (
                <option key={event.id} value={event.description}>
                  {event.description}
                </option>
              ))}
          </Select>
        </FormField>
        <FormField css={{ marginTop: 0 }} inputId="points" label="Number of points">
          <Input value={points} id="points" type="number" onChange={handlePointsChange} />
        </FormField>
        <Button
          disabled={!event || !points}
          css={{
            height: theme.spacing(4.5),
            marginTop: theme.spacing(3),
            marginLeft: theme.spacing(0.75),
          }}
          type="submit"
          size="sm"
        >
          Save
        </Button>
      </FormRow>
    </Form>
  );
}
