/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useSelector, useDispatch } from 'react-redux';
import { useCallback, ChangeEvent, useState, FormEvent } from 'react';

import { Form, FormRow, FormField, Input, InputWithChildren } from '../../../common/components/form';
import { getShopSettings } from '../../../shop-settings/redux/shop-settings-selectors';
import { updateShopSettings } from '../../../shop-settings/redux/shop-settings-action-creators';
import Button from '../../../common/components/button';
import { PopoverPicker } from './popover-picker';

export default function DesignSettings() {
  const dispatch = useDispatch();
  const settings = useSelector(getShopSettings);
  const [settingsTheme, setSettingsTheme] = useState(settings.theme);

  const updateSettingTheme = useCallback((key: string, value: string) => {
    setSettingsTheme(prevSettingsTheme => {
      return key in prevSettingsTheme ? { ...prevSettingsTheme, [key]: value } : prevSettingsTheme;
    });
  }, []);

  const onInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { id, value } = e.target;
      updateSettingTheme(id, value);
    },
    [updateSettingTheme]
  );

  const onSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      dispatch(updateShopSettings({ ...settings, theme: settingsTheme }));
    },
    [dispatch, settings, settingsTheme]
  );

  return (
    <Form onSubmit={onSubmit}>
      <FormRow>
        <FormField inputId="accentColor" label="Accent Color">
          <InputWithChildren id="accentColor" value={settingsTheme.accentColor} onChange={onInputChange}>
            <PopoverPicker color={settingsTheme.accentColor} onChange={(color: string) => {updateSettingTheme("accentColor", color);}}/>
          </InputWithChildren>
        </FormField>
        <FormField inputId="bodyBg" label="Background Color">
          <InputWithChildren id="bodyBg" value={settingsTheme.bodyBg} onChange={onInputChange}>
            <PopoverPicker color={settingsTheme.bodyBg} onChange={(color: string) => {updateSettingTheme("bodyBg", color);}} />
          </InputWithChildren>
        </FormField>
        <FormField inputId="bodyColor" label="Text Color">
          <InputWithChildren id="bodyColor" value={settingsTheme.bodyColor} onChange={onInputChange}>
            <PopoverPicker color={settingsTheme.bodyColor} onChange={(color: string) => {updateSettingTheme("bodyColor", color);}}/>
          </InputWithChildren>
        </FormField>
        <FormField inputId="bodyFontFamily" label="Font Family">
          <Input
            id="bodyFontFamily"
            value={settingsTheme.bodyFontFamily}
            onChange={onInputChange}
          />
        </FormField>
      </FormRow>

      <Button css={{ margin: 8, height: 36 }} type="submit" size="sm">
        Save Change
      </Button>
    </Form>
  );
}
