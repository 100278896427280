/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useTheme } from '@emotion/react';
import { useState, useCallback } from 'react';
import CardHeader from './card-header';

type CardProps = React.ComponentProps<'div'> & {
  title?: string;
  closed?: boolean;
  toggleable?: boolean;
  isAddNew?: boolean;
  previewImage?: string;
  headerWarning?: boolean;
};

export default function Card({ title, toggleable, closed, isAddNew, previewImage, headerWarning, children, ...restProps }: CardProps) {
  const theme = useTheme();
  const [open, setOpen] = useState(!closed);
  const onToggle = useCallback(() => {
    setOpen(prev => !prev);
  }, []);

  return (
    <div
      css={{
        padding: theme.spacing(2),
        color: theme.palette.card.color,
        backgroundColor: isAddNew ? theme.palette.accent.contrastColor : theme.palette.card.background,
        boxShadow: '0 0 2px 0 rgba(0, 0, 0, .15)',
      }}
      {...restProps}
    >
      {!!title && <CardHeader open={open} title={title} onToggle={onToggle} toggleable={toggleable} isAddNew={isAddNew} previewImage={previewImage} headerWarning={headerWarning} />}
      {open && children}
    </div>
  );
}
