import { Dispatch } from 'redux';
import { getType, isActionOf } from 'typesafe-actions';

import { Api } from '../../../common/redux/make-api';
import { USERS } from '../../../common/redux/types/feature';
import { arrayToObject } from '../../../common/utils/array-to-object';
import { setLoader } from '../../../common/redux/ui/loader/loader-action-creators';
import User from '../../../user/types/user';
import { UsersAction, fetchUsers, setUsers, setUserPoints } from './users-action-creators';
import { HttpClient } from '../../../common/http-client/make-http-client';
import { setNotification } from '../../../common/redux/ui/notification/notification-action-creators';
import { USER_SET_POINTS_API_URL } from '../../../common/types/api-url';

const makeUsersRouteMiddleware = (usersApi: Api<User[]>, httpClient: HttpClient) => () => (
  next: Dispatch
) => (action: UsersAction) => {
  next(action);

  switch (action.type) {
    case getType(fetchUsers):
      next(setLoader(true, USERS));
      if (isActionOf(fetchUsers, action)) {
        next(usersApi.actions.fetch(action.payload));
      }
      break;
    /**
     * PS. updating the API from here instead of from "usersApi" because
     * the endpoint to set user points is NOT consistent with the endpoint to CRUD users.
     */
    case getType(setUserPoints):
      next(setLoader(true, USERS));
      if (isActionOf(setUserPoints, action)) {
        httpClient
          .post(USER_SET_POINTS_API_URL, action.payload)
          .then(() => {
            next(setLoader(false, USERS));
            next(setNotification('success', 'User points updated successfully', USERS));
          })
          .catch(() => {
            next(setLoader(false, USERS));
            next(
              setNotification(
                'failure',
                'Error updating user points. Please, try again later',
                USERS
              )
            );
          });
      }
      break;
    case getType(usersApi.actions.fetchSuccess):
      next(setLoader(false, USERS));
      if (isActionOf(usersApi.actions.fetchSuccess, action)) {
        const usersById = arrayToObject<User, 'id'>('id', action.payload.data);
        next(setUsers(usersById));
      }
      break;
  }
};

export default makeUsersRouteMiddleware;
