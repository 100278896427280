import { Axios } from "axios";
import makeAxiosInstance from "./make-axios-instance";
import appConfig from "app-config";
import { UserParticipation } from "user-participation-history/types/user-participation-history";
import User from "user/types/user";
import { UserOrder } from "user-order-history/types/user-order-history";
import CatalogItem from "catalog/types/catalog-item";

const getAuthAxios = () : Axios => {
  var authToken = window.localStorage.getItem('authToken');
  return makeAxiosInstance(appConfig.apiUrl, authToken != null ? authToken : "", () => {})
}

const get = (endpoint : string, params? : Params) : Promise<any> => {
  if (params !== undefined) {
    endpoint += params.Build()
  }
  return new Promise<any>((resolve, reject) => {
    getAuthAxios().get(endpoint).then((res) => resolve(res.data)).catch((err) => reject(new Error(err.response.data.message)));
  })
}

const post = (endpoint : string, data? : any, params? : Params) : Promise<any> => {
  if (params !== undefined) {
    endpoint += params.Build()
  }
  return new Promise<any>((resolve, reject) => {
    getAuthAxios().post(endpoint, data).then((res) => resolve(res.data)).catch((err) => reject(new Error(err.response.data.message)));
  })
}

enum Order {
  ascending = "asc",
  descending = "dsc",
}

enum Operator {
  lessThanOrEqualTo = "lte",
  greaterThanOrEqualTo = "gte",
  lessThan = "lt",
  greaterThan = "gt",
  notEqualTo = "ne",
  equalTo = "eq",
  like = "like",
  orLessThanOrEqualTo = "orlte",
  orGreaterThanOrEqualTo = "orgte",
  orLessThan = "orlt",
  orGreaterThan = "orgt",
  orNotEqualTo = "orne",
  orEqualTo = "oreq",
  orLike = "orlike",
  in = "in",
  orIn = "orin",
}

const withParams = () : Params => {
  return new Params();
}

class Params {
  params: string;
  constructor() {
    this.params = "?";
  }

  Limit(limit : number) : Params {
    this.params += `limit=${limit}&`
    return this
  }

  Offset(offset: number) : Params {
    this.params += `offset=${offset}&`
    return this
  }

  SortBy(key: string, direction: Order) : Params {
    this.params += `sort_by=${key}~${direction}&`
    return this
  }

  Search(key: string, operator: Operator, pattern: string) : Params {
    this.params += `${key}=${operator}~${pattern}&`
    return this
  }

  Build() : string {
    return this.params.substring(0, this.params.length-1)
  }
}

//golang marshals empty slices into nil in json, if we are okay with empty sets (non error) then lets give the frontend an empty set.
const handleNullArray = (req : Promise<any>) : Promise<any[]> => {
  return new Promise<any[]>((resolve, reject) => {
    req.then((data) => {
      if (data === null) {
        resolve([])
      }
      resolve(data)
    }).catch((err) => {
      reject(err)
    })
  })
}

const getUserRewards = () : Promise<UserParticipation[]> => {
  return handleNullArray(get(`/user/rewards`));
}

const getUserOrderHistory = () : Promise<UserOrder[]> => {
  return handleNullArray(get(`/user/order`));
}

// admin only methods
const adminGetUser = (userId : string) : Promise<User> => {
  return get(`/admin/user/${userId}`);
}

const adminUserSearch = (params? : Params) : Promise<User[]> => {
  return get('/admin/user/search', params)
}


type OrderItemSummed = CatalogItem & {total_quantity: number}
const adminGetOrderItems = (params? : Params) : Promise<OrderItemSummed[]> => {
  return get(`/admin/tools/order-items`, params)
}

const adminGetUserOrderHistory = (userId : string) : Promise<UserOrder[]> => {
  return handleNullArray(get(`/admin/user/${userId}/orders`));
}

const adminGetUserRewards = (userId : string) : Promise<UserParticipation[]> => {
  return handleNullArray(get(`/admin/user/${userId}/rewards`));
}

type UserBalance = User & {balance: number}
const adminGetUserBalances = (params? : Params) : Promise<UserBalance[]> => {
  return handleNullArray(get('/admin/tools/balance', params))
}


enum ReportType {
  pdf = "pdf",
  csv = "csv"
}

interface ExternalFile {
  Location: string;
}

const adminGetInventoryReport = (type : ReportType) : Promise<ExternalFile> => {
  return get(`/admin/tools/inventory.${type}`)
}

const adminGetOrderReport = (type: ReportType, data?: any, params? : Params) : Promise<ExternalFile> => {
  return post(`/admin/tools/order.${type}`, data, params)
}

const adminAddUserPoints = (userId: string, title: string, points: Number) : (Promise<UserParticipation>) => {
  return post('/admin/user/points', {
    UserID: userId,
    Title: title,
    Points: points,
  })
}

interface ImageUploadSuccess {
  Msg: string;
  Location: string;
}

const uploadImage = (data: FormData) : Promise<ImageUploadSuccess> => {
  return post('/shop/upload-image', data);
}

export {
  getUserRewards,
  getUserOrderHistory,
  adminGetUserRewards,
  adminGetUserOrderHistory,
  adminGetUser,
  adminUserSearch,
  adminGetOrderItems,
  adminGetUserBalances,
  uploadImage,
  adminGetInventoryReport,
  adminGetOrderReport,
  adminAddUserPoints,
  withParams,
  Params,
  Order,
  Operator,
  ReportType,
}

export type {
  UserBalance,
  OrderItemSummed,
}
