import { Dispatch } from 'redux';
import { getType, isActionOf } from 'typesafe-actions';

import { Api } from '../../common/redux/make-api';
import CatalogItem from '../../catalog/types/catalog-item';
import { CATALOG_ITEM } from '../../common/redux/types/feature';
import { setLoader } from '../../common/redux/ui/loader/loader-action-creators';
import { CatalogItemAction, updateCatalogItem, createCatalogItem, deleteCatalogItem } from './catalog-item-action-creators';
import { setNotification } from '../../common/redux/ui/notification/notification-action-creators';
import { fetchCatalog } from '../../catalog/redux/catalog-action-creators';
import { fetchInventory } from 'admin/inventory/redux/inventory-action-creators';

const makeCatalogItemRouteMiddleware = (catalogItemApi: Api<CatalogItem>) => () => (next: Dispatch) => (
  action: CatalogItemAction
) => {
  next(action);
  switch (action.type) {
    case getType(updateCatalogItem):
      next(setLoader(true, CATALOG_ITEM));
      if (isActionOf(updateCatalogItem, action)) {
        next(catalogItemApi.actions.update(action.payload));
      }
      break;
    case getType(catalogItemApi.actions.updateSuccess):
      next(setLoader(false, CATALOG_ITEM));
      if (isActionOf(catalogItemApi.actions.updateSuccess, action)) {
        next(setNotification('success', 'Item updated successfully', CATALOG_ITEM));
        next(fetchCatalog());
        next(fetchInventory())
      }
      break;
    case getType(catalogItemApi.actions.updateFailure):
      next(setLoader(false, CATALOG_ITEM));
      next(setNotification('failure', 'Could not update this item', CATALOG_ITEM));

      break;
// create
    case getType(createCatalogItem):
      next(setLoader(true, CATALOG_ITEM));
      if (isActionOf(createCatalogItem, action)) {
        next(catalogItemApi.actions.create(action.payload));
      }
      break;
    case getType(catalogItemApi.actions.createSuccess):
      next(setLoader(false, CATALOG_ITEM));
      if (isActionOf(catalogItemApi.actions.createSuccess, action)) {
        next(setNotification('success', 'Item created successfully', CATALOG_ITEM));
        next(fetchCatalog());
        next(fetchInventory());
      }
      break;
    case getType(catalogItemApi.actions.createFailure):
      next(setLoader(false, CATALOG_ITEM));
      next(setNotification('failure', 'Could not create this item', CATALOG_ITEM));

      break;
// delete
    case getType(deleteCatalogItem):
      next(setLoader(true, CATALOG_ITEM));
      if (isActionOf(deleteCatalogItem, action)) {
        next(catalogItemApi.actions.deleteAction(action.payload));
      }
      break;
    case getType(catalogItemApi.actions.deleteSuccess):
      next(setLoader(false, CATALOG_ITEM));
      if (isActionOf(catalogItemApi.actions.deleteSuccess, action)) {
        next(setNotification('success', 'Item deleted successfully', CATALOG_ITEM));
        next(fetchCatalog());
        next(fetchInventory());
      }
      break;
    case getType(catalogItemApi.actions.deleteFailure):
      next(setLoader(false, CATALOG_ITEM));
      next(setNotification('failure', 'Could not delete this item', CATALOG_ITEM));
      break;
  }
};

export default makeCatalogItemRouteMiddleware;
