import { Dispatch, MiddlewareAPI } from 'redux';
import { getType, isActionOf } from 'typesafe-actions';

import{ fetchUser } from '../../user/redux/user-action-creators';
import Order from '../types/order';
import { Api } from '../../common/redux/make-api';
import { ORDER } from '../../common/redux/types/feature';
import { OrderAction, createOrder } from './order-action-creators';
import { setLoader } from '../../common/redux/ui/loader/loader-action-creators';
import { resetCart, removeCartFromCache, setOrderPlaced } from '../../cart/redux/cart-action-creators';
import { fetchCatalog } from '../../catalog/redux/catalog-action-creators';
import { setNotification, setNotificationWithTimeout } from '../../common/redux/ui/notification/notification-action-creators';

const makeOrderRouteMiddleware = (orderApi: Api<Order>) => ({ getState }: MiddlewareAPI) => (
  next: Dispatch
) => (action: OrderAction) => {
  next(action);
  switch (action.type) {
    case getType(createOrder):
      if (isActionOf(createOrder, action)) {
        next(setLoader(true, ORDER));
        next(orderApi.actions.create(action.payload));
      }
      break;
    case getType(orderApi.actions.createFailure):
      if (isActionOf(orderApi.actions.createFailure, action)) {
        next(setLoader(false, ORDER));
        next(setNotificationWithTimeout('failure', action.payload, ORDER, 10 * 1000))
        next(setOrderPlaced(false))
      }
      break;
    case getType(orderApi.actions.createSuccess):
      next(setLoader(false, ORDER));
      next(fetchUser());
      next(resetCart());
      next(removeCartFromCache());
      next(fetchCatalog());
      next(setOrderPlaced(true))
      next(setNotification('success', "Order placed successfully", ORDER))
      break;
  }
};

export default makeOrderRouteMiddleware;
