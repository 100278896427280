/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTheme } from '@emotion/react';

import Thumbnail from '../../common/components/thumbnail';
import CatalogItem from '../../catalog/types/catalog-item';
import { removeItemFromCart } from '../redux/cart-action-creators';
import { Variant } from '../../catalog/types/variant';
import { getVariantImageUrl } from '../../catalog/catalog-utils';
import { faTrash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface CatalogItemInCart {
  cartItemId: string;
  cartItemVariantId?: string;
  cartItemQuantity: number;
  catalogItem: CatalogItem;
}

export default function CartItem({
  catalogItem,
  cartItemId,
  cartItemVariantId,
  cartItemQuantity,
}: CatalogItemInCart) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const onRemove = useCallback(() => {
    dispatch(removeItemFromCart(cartItemId));
  }, [dispatch, cartItemId]);

  const variant =
    cartItemVariantId &&
    catalogItem.variants &&
    catalogItem.variants.find((variant: Variant) => variant.id === cartItemVariantId);

  return (
    <div
      css={{
        fontSize: '.875em',
        position: 'relative',
        padding: "0px 8px 8px 8px",
        textTransform: 'capitalize',
        color: theme.palette.accent.contrastColor,
        background: theme.palette.accent.color,
        height: "100%",
        boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.5)",
        borderRadius: theme.spacing(1)
      }}
    >
      <div
        css={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <p css={{
          paddingLeft: "15%",
          margin: theme.spacing(1),
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          lineHeight: theme.spacing(.2),
        }}>
            {/* {cartItemQuantity > 1 && `(${cartItemQuantity}) `} */}
            {catalogItem.title} {!!variant && '-'} {!!variant && !!variant.size && variant.size}{' '}
            {!!variant && !!variant.color && variant.color}{' '}
        </p>
        <button onClick={onRemove} style={{background: "transparent", border: "none", borderRadius: "40px"}}>
          <FontAwesomeIcon icon={faTrash} color={theme.palette.accent.contrastColor} size="sm" css={{'&:hover': {color: theme.palette.common.red}}}/>
        </button>
      </div>
      {catalogItem &&
      <div>
        <div style={{
          color: theme.palette.accent.color,
          textAlign: "center",
          backgroundColor: theme.palette.accent.contrastColor,
          boxShadow: "1px 1px 3px black",
          height: "25px",
          paddingLeft: "5px",
          paddingRight: "5px",
          maxWidth: "25%",
          borderRadius: " 8px 0px 16px 0px",
          top: 0,
          left: 0,
          position: "absolute"}}>
          {cartItemQuantity}
        </div>
        <Thumbnail
        alt={catalogItem.title + " thumbnail"}
        imageUrl={getVariantImageUrl(cartItemVariantId, catalogItem.imageUrl ? catalogItem.imageUrl : "", catalogItem.variants)}
        css={{ borderColor: theme.palette.accent.color, height: "100px", maxHeight: "100px"}}
        />
        <div style={{
          color: theme.palette.accent.color,
          textAlign: "center",
          backgroundColor: theme.palette.accent.contrastColor,
          boxShadow: "-1px -1px 3px black",
          height: "25px",
          maxWidth: "50%",
          paddingLeft: "10px",
          paddingRight: "5px",
          borderRadius: "16px 0px 8px 0px",
          bottom: 0,
          right: 0,
          position: "absolute"}}>
          {catalogItem.points * cartItemQuantity}
        </div>
      </div>
      }
    </div>
  );
}
