import React from 'react';
import { AxiosError } from 'axios';
import { useState, useEffect, useCallback, ReactElement } from 'react';

import LoginPage from './login-page';
import AuthUtils, { AuthStatusResponse, AuthStatusResponseData } from '../types/auth-utils';

export interface AuthWrapperProps {
  utils: AuthUtils;
  renderPrivateApp: (authToken: string, onAuthFailure: () => void) => ReactElement;
}

export default function AuthWrapper({ utils, renderPrivateApp }: AuthWrapperProps) {
  const [authToken, setAuthToken] = useState('');
  const [authStatus, setAuthStatus] = useState(false);
  const [authLocation, setAuthLocation] = useState('');
  const [authMessage, setAuthMessage] = useState('You must login in order to see this page');

  const [isProcessingAuth, setIsProcessingAuth] = useState(true);

  const checkAuthStatus = useCallback(() => {
    utils
      .fetchAuthStatus()
      .then(({ data }: AuthStatusResponse) => {
        setAuthStatus(data.status);
        setAuthToken(utils.getAuthTokenFromLocalStorage());

        setIsProcessingAuth(false);
      })
      .catch((e: AxiosError<AuthStatusResponseData>) => {
        if (e.response) {
          const { data, headers } = e.response;
          setAuthLocation(headers.location);
          /**
           * TODO: remove typeof check once backend endpoint auth/status
           * consistently returns { status:boolean, message:string }
           */
          if (data.message && typeof data.message === 'string') {
            setAuthMessage(data.message);
          }
        }
        setAuthStatus(false);
        utils.removeAuthTokenFromLocalStorage();

        setIsProcessingAuth(false);
      });
  }, [utils]);

  const processCallback = useCallback(() => {
    const authTokenFromUrl = utils.getAuthTokenFromUrl();
    const authStatusFromUrl = utils.getAuthStatusFromUrl();
    const authMessageFromUrl = utils.getAuthMessageFromUrl();

    if (authStatusFromUrl === 'SUCCESS') {
      setAuthStatus(true);
      setAuthToken(authTokenFromUrl);
      utils.addAuthTokenToLocalStorage(authTokenFromUrl);

      setIsProcessingAuth(false);
    } else {
      if (authMessageFromUrl) {
        setAuthMessage(authMessageFromUrl);
      }
      checkAuthStatus();
    }

    utils.redirectTo('/');
  }, [utils, checkAuthStatus]);

  useEffect(() => {
    function startProcessingAuth() {
      if (utils.isOnCallbackPathname()) {
        processCallback();
      } else {
        checkAuthStatus();
      }
    }

    startProcessingAuth();
  }, [utils, checkAuthStatus, processCallback]);

  /**
   * To be called by the private app when there's an auth failure.
   * Most of the times, when auth token has expired and the server responds with 40x status
   */
  const onAuthFailure = useCallback(() => {
    setIsProcessingAuth(true);
    checkAuthStatus();
  }, [setIsProcessingAuth, checkAuthStatus]);

  if (isProcessingAuth) {
    return <div>Loading...</div>;
  }

  return authStatus && authToken ? (
    renderPrivateApp(authToken, onAuthFailure)
  ) : (
    <LoginPage loginUrl={authLocation} loginMessage={authMessage} />
  );
}
