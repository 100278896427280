/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';


import formatDate from '../../common/utils/format-date';
import { Table, TableSection } from '../../common/components/table';
import { UserParticipation } from '../types/user-participation-history';

interface ParticipationHistoryProps {
  userRewards: UserParticipation[];
}
export default function UserParticipationHistory(props: ParticipationHistoryProps) {
  var total = 0;
  props.userRewards.forEach((reward) => {
    if (reward !== undefined) {
      total += reward.points
    }
  })

  return (
    <Table>
      <TableSection as="thead">
        <tr css={{ th: { width: '33.33%' } }}>
          <th>Date</th>
          <th>Videos Watched &#38; Shared</th>
          <th>Points Earned</th>
        </tr>
      </TableSection>
      <TableSection as="tbody">
      {props.userRewards &&
        props.userRewards.map((userParticipation: UserParticipation, index: number) => {
          return userParticipation &&
            <tr key={index}>
              <td>{formatDate(userParticipation.timeEarned)}</td>
              <td>{userParticipation.name}</td>
              <td>{userParticipation.points}</td>
            </tr>
        })
      }
      </TableSection>
      <TableSection as="tfoot">
        {props.userRewards &&
        <tr>
          <td>Totals:</td>
          <td>
            {props.userRewards.length}
            {props.userRewards.length === 1 ? ' Activity' : ' Activities'}
          </td>
          <td>{total} Points</td>
        </tr>
        }
      </TableSection>
    </Table>
  );
}
