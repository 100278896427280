import { createAction, ActionType } from 'typesafe-actions';

import User from '../types/user';
import { USER } from '../../common/redux/types/feature';
import { FETCH, SET } from '../../common/redux/constants';

export const fetchUser = createAction(`${USER} ${FETCH}`, action => () => action());

export const setUser = createAction(`${USER} ${SET}`, action => (user: User) => action(user));

export type UserAction = ActionType<typeof setUser | typeof fetchUser>;
