import { createAction, ActionType } from 'typesafe-actions';

import { FETCH, SET } from '../../../common/redux/constants';
import { USERS } from '../../../common/redux/types/feature';
import { UsersById } from '../../../common/redux/types/state';
import QueryParams from '../../../common/redux/types/query-params';

export const fetchUsers = createAction(`${USERS} ${FETCH}`, action => (queryParams?: QueryParams) =>
  action(queryParams)
);

export const setUsers = createAction(`${USERS} ${SET}`, action => (users: UsersById) =>
  action(users)
);

export const setUserPoints = createAction(
  `${USERS} SET_USER_POINTS`,
  action => (userId: string, title: string, points: number) => action({ userId, title, points })
);

export type UsersAction = ActionType<typeof fetchUsers | typeof setUsers | typeof setUserPoints>;
