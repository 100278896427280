import { getType, isActionOf } from 'typesafe-actions';

import {
  CartAction,
  setCart,
  addItemToCart,
  removeItemFromCart,
  setCartShipping,
  setCartShippingLocation,
  showAdditionalAddress,
  resetCart,
  setOrderPlaced,
} from './cart-action-creators';
import Cart from '../types/cart';
import assoc from '../../common/utils/assoc';
import dissoc from '../../common/utils/dissoc';

const initialState = {
  items: {},
  shipping: undefined,
  showAdditionalAddress: false,
  orderPlaced: false,
};

export default function cartReducer(state: Cart = initialState, action: CartAction): Cart {
  switch (action.type) {
    case getType(setCart):
      if (isActionOf(setCart, action)) {
        return action.payload;
      }
      return state;
    case getType(resetCart):
      return initialState;
    case getType(setOrderPlaced): {
      if (isActionOf(setOrderPlaced, action)) {
        return {
          ...state,
          orderPlaced: action.payload,
        }
      }
      return state;
    }
    case getType(addItemToCart): {
      if (isActionOf(addItemToCart, action)) {
        const cartItemId = action.payload;
        const cartItemQuantity = state.items[cartItemId];
        return !!cartItemQuantity
          ? {
              ...state,
              items: assoc(cartItemId, cartItemQuantity + 1, state.items),
            }
          : { ...state, items: assoc(cartItemId, 1, state.items) };
      }
      return state;
    }
    case getType(removeItemFromCart): {
      if (isActionOf(removeItemFromCart, action)) {
        const cartItemId = action.payload;
        return state.items[cartItemId]
          ? {
              ...state,
              items: dissoc(cartItemId, state.items),
            }
          : state;
      }
      return state;
    }
    case getType(setCartShippingLocation): {
      if (isActionOf(setCartShippingLocation, action)) {
        return {
          ...state,
          shippingLocation: action.payload,
        };
      }
      return state;
    }
    case getType(setCartShipping): {
      if (isActionOf(setCartShipping, action)) {
        return {
          ...state,
          shipping: action.payload,
        };
      }
      return state;
    }
    case getType(showAdditionalAddress): {
      if (isActionOf(showAdditionalAddress, action)) {
        return {
          ...state,
          showAdditionalAddress: action.payload,
        };
      }
      return state;
    }
    default:
      return state;
  }
}
