/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { withTheme, Theme } from '@emotion/react';
import { useSelector } from 'react-redux';

import CartItem, { CatalogItemInCart } from './cart-item';
import { Row, Col } from '../../common/components/layout';
import { getCatalogItemsInCart } from '../redux/cart-selectors';

interface CartItemsProps {
  theme: Theme;
}

function CartItems({ theme }: CartItemsProps) {
  const catalogItemsInCart = useSelector(getCatalogItemsInCart);
  return (
    <Row
      css={{
        padding: theme.spacing(1),
        [theme.breakpoint('md')]: {
          padding: theme.spacing(1),
        },
      }}
    >
      {catalogItemsInCart.map((catalogItemInCart: CatalogItemInCart) =>
        catalogItemInCart.catalogItem ? (
          <Col
            key={catalogItemInCart.cartItemId}
            css={{
              width: '50%',
              padding: theme.spacing(1),
              [theme.breakpoint('sm')]: {
                width: '33.33%',
              },
              [theme.breakpoint('md')]: {
                width: '50%',
                padding: theme.spacing(1),
              },
              [theme.breakpoint('lg')]: {
                width: '33.33%',
              },
            }}
          >
            <CartItem {...catalogItemInCart} />
          </Col>
        ) : null
      )}
    </Row>
  );
}

export default withTheme(CartItems);
