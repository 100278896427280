/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { withTheme, Theme } from '@emotion/react';
import { useCallback, ChangeEvent } from 'react';

import { VariantAttrName } from '../../types/variant';
import Select from '../../../common/components/select';

interface VariantPickerSelectProps {
  theme: Theme;
  name: VariantAttrName;
  value: string;
  options: string[];
  onSelect: (value: string) => void;
}

function VariantPickerSelect({ theme, name, value, options, onSelect }: VariantPickerSelectProps) {
  const onChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      onSelect(e.target.value);
    },
    [onSelect]
  );

  return (
    <Select
      value={value}
      onChange={onChange}
      id={`${name}-picker`}
      borderColor={theme.palette.accent.color}
    >
      <option value="">{name}</option>
      {options.map((opt: string) => (
        <option key={opt} value={opt}>
          {opt}
        </option>
      ))}
    </Select>
  );
}

export default withTheme(VariantPickerSelect);
