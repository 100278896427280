/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { withTheme, Theme } from '@emotion/react';
import { useSelector } from 'react-redux';

import { getUser } from '../redux/user-selectors';
import AccentBg from '../../common/components/accent-bg';
import { P, Heading } from '../../common/components/typography';
import BackToStore from '../../common/components/back-to-store';
import { Container, Row, Col } from '../../common/components/layout';
import {faUser} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface UserProfileProps {
  theme: Theme;
}

function UserProfile({ theme }: UserProfileProps) {
  const user = useSelector(getUser);
  return (
    <div
      css={{
        position: 'relative',
        margin: '0 auto',
        [theme.breakpoint('md')]: {
          maxWidth: theme.spacing(130),
        }
      }}
      data-testid="user-profile"
    >
      <AccentBg css={{ borderRadius: '10px', bottom: 0 }} />
      <Container>
        <Row>
          <div
            css={{
              margin: 'auto',
              textAlign: 'center',
              alignItems: 'center',
            }}
          >
            <div
              css={{
                display: 'inline-block',
                lineHeight: `${theme.spacing(6)}px`,
                marginTop: theme.spacing(6),
              }}
            >
            <FontAwesomeIcon
              icon={faUser}
              color={theme.palette.body.background}
              border={true}
              css={{
                borderRadius: "50%",
                borderColor: theme.palette.accent.color,
                backgroundColor: theme.palette.common.darkGrey,
                borderWidth: theme.spacing(1.5),
                minWidth: "150px",
                minHeight: "150px",
                padding: theme.spacing(4),
              }}
            />
            </div>
          </div>
          <Col
            css={{
              textAlign: 'center',
              [theme.breakpoint('md')]: { width: '50%', textAlign: 'left' },
            }}
          >
            <BackToStore
              css={{
                display: 'block',
                marginTop: theme.spacing(2),
                marginBottom: theme.spacing(2),
                [theme.breakpoint('md')]: {
                  marginTop: theme.spacing(14),
                },
              }}
            />
            <Heading
              as="h1"
              css={{
                marginBottom: theme.spacing(2),
                color: theme.palette.accent.contrastColor,
              }}
            >
              {`${user.firstName} ${user.lastName}`}
            </Heading>
            <P
              css={{
                marginBottom: theme.spacing(2),
                [theme.breakpoint('md')]: {
                  marginBottom: theme.spacing(12),
                },
                color: theme.palette.accent.contrastColor,
              }}
            >
              {user.email}
            </P>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default withTheme(UserProfile);
