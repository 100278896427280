import { createSelector } from 'reselect';

import State, { InventoryState } from 'common/redux/types/state';

const getInventoryState = (state: State) => state.inventory;

export const getInventory = createSelector(
  getInventoryState,
  (inventoryState: InventoryState) => inventoryState.data
);

