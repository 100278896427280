/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { withTheme, Theme } from '@emotion/react';

import Linkify from '../../../common/components/linkify';
import { P } from '../../../common/components/typography';

interface DescriptionProps {
  theme: Theme;
  description: string;
  sizeChart?: string;
}

const Description = withTheme(({ theme, description, sizeChart }: DescriptionProps) => (
  <P
    css={{
      marginBottom: theme.spacing(5),
      color: theme.palette.accent.contrastColor,
      background: theme.palette.accent.color,
      a: {
        color: theme.palette.accent.contrastColor,
      },
    }}
  >
    <Linkify>{description}</Linkify>
    {sizeChart && <span><br /><Linkify label='Size chart'>{sizeChart}</Linkify></span>}
  </P>
));

export default Description;
