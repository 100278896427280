/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { withTheme, Theme } from '@emotion/react';

import {faUser} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface UserImageBadgeProps {
  theme: Theme;
}

function UserImageBadge({ theme, ...restProps }: UserImageBadgeProps) {

  const onClickHandler = () => {
    window.location.href="/user";
  }

  return (
      <button
        title="My Account"
        onClick={onClickHandler}
        css={{
          cursor: 'pointer',
          textAlign: 'center',
          position: 'relative',
          width: theme.spacing(6),
          height: theme.spacing(6),
          lineHeight: `${theme.spacing(6)}px`,
          marginLeft: theme.spacing(1.5),
          borderStyle: 'solid',
          borderRadius: theme.spacing(6),
          background: theme.palette.body.background,
          borderColor: theme.palette.accent.color,
          borderWidth: 1,
          '&:focus': {
            outline: 'none',
          },
        }}
      {...restProps}
    >
        <FontAwesomeIcon icon={faUser} color={theme.palette.accent.color} css={{
          width: theme.spacing(3),
          height: theme.spacing(3),
          marginTop: theme.spacing(1.3),
        }}/>
      </button>
  );
}

export default withTheme(UserImageBadge);
