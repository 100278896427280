/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { withTheme, Theme } from '@emotion/react';


import { getNumberOfItemsInCart } from '../redux/cart-selectors';
import { ReactComponent as CartIcon } from '@fortawesome/fontawesome-pro/svgs/solid/shopping-cart.svg';

interface CartBadgeProps {
  theme: Theme;
}

function CartBadge({ theme, ...restProps }: CartBadgeProps) {
  const numberOfItemsInCart = useSelector(getNumberOfItemsInCart);
  return (
    <Link
      to="?cart=open"
      css={{
        textAlign: 'center',
        position: 'relative',
        width: theme.spacing(6),
        height: theme.spacing(6),
        lineHeight: `${theme.spacing(6)}px`,
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: theme.spacing(6),
        borderColor: theme.palette.accent.color,
      }}
      {...restProps}
    >
      <CartIcon
        width={theme.spacing(3)}
        height={theme.spacing(3)}
        fill={theme.palette.accent.color}
      />
      {!!numberOfItemsInCart && (
        <div
          css={{
            bottom: -4,
            right: 0,
            fontSize: '.75em',
            position: 'absolute',
            lineHeight: `${theme.spacing(2)}px`,
            width: theme.spacing(2),
            height: theme.spacing(2),
            borderRadius: theme.spacing(2),
            background: theme.palette.accent.color,
            color: theme.palette.accent.contrastColor,
          }}
        >
          {numberOfItemsInCart}
        </div>
      )}
    </Link>
  );
}

export default withTheme(CartBadge);
