import { jsx } from '@emotion/core';
import AdminPageContainer from '../../components/admin-page-container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useTheme } from '@emotion/react';
import React from 'react';
import OrdersCsvGenerator from '../components/orders-report-generator';
import SpendLeaderboard from '../components/spend-leaderboard';
import EarnedLeaderboard from '../components/earn-leaderboard';
import BalanceLeaderboard from '../components/balance-leaderboard';
import OrderItemLeaderboard from '../components/order-item-leaderboard';
import InventoryReportGenerator from '../components/inventory-report-generator';
/** @jsxRuntime classic */
/** @jsx jsx */

export default function AdminToolsPage() {
  const theme = useTheme();
  const verticalPadding = {paddingTop: 12, paddingBottom: 12}

  return (
    <div css={{ padding: theme.spacing(1), paddingBottom: theme.spacing(8) }}>
      <AdminPageContainer title="Tools">
        <Row>
          <Col>
            <OrdersCsvGenerator/>
            <SpendLeaderboard style={verticalPadding}/>
            <EarnedLeaderboard/>
          </Col>
          <Col>
            <InventoryReportGenerator/>
            <OrderItemLeaderboard style={verticalPadding}/>
            <BalanceLeaderboard/>
          </Col>
        </Row>
      </AdminPageContainer>
    </div>
  );
}
