/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { withTheme, Theme } from '@emotion/react';
import { Link } from 'react-router-dom';

import { P } from '../../../common/components/typography';

interface PointsAwayMessageProps {
  theme: Theme;
  pointsAway: number;
}

const PointsAwayMessage = withTheme(({ theme, pointsAway }: PointsAwayMessageProps) => (
  <P css={{ textTransform: 'uppercase', color: theme.palette.accent.color }}>
    You are <strong>{pointsAway}</strong> points away
    from claiming this item.{' '}
    <Link css={{ color: theme.palette.accent.color }} to="?cart=open">
      View cart
    </Link>
  </P>
));

export default PointsAwayMessage;
