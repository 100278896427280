/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useDispatch } from 'react-redux';
import { useCallback, ChangeEvent, useState, FormEvent } from 'react';
import { useTheme } from '@emotion/react';

import { Form, FormRow, FormField, Input } from '../../../common/components/form';
import Button from '../../../common/components/button';
import { createCatalogItemVariant } from '../../../catalog-item-variant/redux/catalog-item-variant-action-creators';
import { NewVariant } from '../../../catalog/types/variant';

type AddInventoryItemVariantProps = React.ComponentProps<'div'> & {
  itemId: string | undefined;
};

export default function AddInventoryItemVariant({itemId} : AddInventoryItemVariantProps) {
  const dispatch = useDispatch();
  const theme = useTheme();

  const newItemDefaults: NewVariant = {
    size: "",
    color: "",
    imageUrl: "",
    availableQuantity: 0,
    visible: true,
    buyerItemNumber: "",
    unitOfMeasure: "",
    itemId: itemId || ""
  };

  const [newItem, setNewItem] = useState<NewVariant>(newItemDefaults);

  const updateNewItem = useCallback((key: string, value: string | number | boolean) => {
    setNewItem(prevNewItem => {
      return key in prevNewItem ? { ...prevNewItem, [key]: value } : prevNewItem;
    });
  }, []);

  const isNumber = (x: any) => {
    const parsed = parseInt(x);
    if (isNaN(parsed)) return false;
    return parsed;
  }

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
      let { id, value } = e.target;
      if (id !== "points" && id !== "availableQuantity") {
        updateNewItem(id, value);
        return;
      }
      if (value !== '-' && value !== '0') {
        if (!isNumber(value)) {
          value = '';
          return;
        }
      }
      updateNewItem(id, parseInt(value));

  }

  const onSubmit = (e: FormEvent) => {
      e.preventDefault();
      dispatch(createCatalogItemVariant(newItem));
      setNewItem(newItemDefaults);
  }

  const onCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
      const { id, checked } = e.target;
      if (checked) updateNewItem(id, true);
      else  updateNewItem(id, false);
  }

  return (
    <Form data-testid="add-inventory-item-variant" onSubmit={onSubmit} css={{ border: theme.palette.card.color, padding: theme.spacing(1)}}>
      <h3>New Item Variant</h3>
      <FormRow>
        <FormField inputId="size" label="Size" >
          <Input id="size" value={newItem.size} onChange={onInputChange} css={{}} />
        </FormField>
        <FormField inputId="color" label="Color" >
          <Input id="color" value={newItem.color} onChange={onInputChange} css={{}} />
        </FormField>
        <FormField inputId="availableQuantity" label="Quantity">
          <Input id="availableQuantity" value={newItem.availableQuantity} onChange={onInputChange} css={{width:"100px"}} />
        </FormField>
        <FormField inputId="visible" label="Visible">
          <Input type="checkbox" id="visible" checked={newItem.visible} onChange={onCheckboxChange} css={{width:"40px"}} />
        </FormField>
      </FormRow>
      <FormRow>
        <FormField inputId="buyerItemNumber" label="Buyer Number">
          <Input id="buyerItemNumber" value={newItem.buyerItemNumber} onChange={onInputChange} />
        </FormField>
        <FormField inputId="unitOfMeasure" label="Unit Of Measure">
          <Input id="unitOfMeasure" value={newItem.unitOfMeasure} onChange={onInputChange} />
        </FormField>
      </FormRow>
      <Button css={{ margin: 8, height: 36 }} type="submit" size="sm">
        Save
      </Button>
    </Form>
    );
}
