/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useTheme } from '@emotion/react';
import { ReactComponent as ToolsIcon } from '@fortawesome/fontawesome-pro/svgs/solid/tools.svg';

export default function AdminNavToggle({ ...restProps }: React.ComponentProps<'button'>) {
  const theme = useTheme();

  return (
    <button
      title="Shop Admin"
      css={{
        cursor: 'pointer',
        textAlign: 'center',
        position: 'relative',
        width: theme.spacing(6),
        height: theme.spacing(6),
        lineHeight: `${theme.spacing(2)}px`,
        marginLeft: theme.spacing(1.5),
        borderStyle: 'solid',
        borderRadius: theme.spacing(6),
        background: theme.palette.body.background,
        borderColor: theme.palette.accent.color,
        borderWidth: 1,
        '&:focus': {
          outline: 'none',
        },
      }}
      {...restProps}
    >
      <ToolsIcon
        width={theme.spacing(3)}
        height={theme.spacing(3)}
        fill={theme.palette.accent.color}
      />
    </button>
  );
}
