/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useTheme } from '@emotion/react';
import { useState, useCallback } from 'react';

import AdminNavItem from '.';
import AdminNavToggle from './admin-nav-toggle';

export default function AdminNav() {
  const [open, setOpen] = useState(false);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const theme = useTheme();
  return (
    <div css={{ position: 'relative' }} onMouseEnter={handleOpen}>
      <AdminNavToggle onClick={handleOpen} />
      {open && (
        <nav
          onMouseLeave={handleClose}
          css={{
            textAlign: 'left',
            fontSize: '.875em',
            position: 'absolute',
            width: theme.spacing(22),
            top: theme.spacing(7.5),
            right: -theme.spacing(0.5),
            background: theme.palette.common.white,
            boxShadow: '0 0 2px 0 rgba(0, 0, 0, .15)',
          }}
        >
          <ul css={{ padding: 0, margin: 0, listStyle: 'none', backgroundColor: theme.palette.accent.color }}>
            <AdminNavItem onClick={handleClose} to="/admin/users">
              Manage Users
            </AdminNavItem>
            <AdminNavItem onClick={handleClose} to="/admin/settings">
              Shop Settings
            </AdminNavItem>
            <AdminNavItem onClick={handleClose} to="/admin/inventory">
              Manage Inventory
            </AdminNavItem>
            <AdminNavItem onClick={handleClose} to="/admin/tools">
              Tools
            </AdminNavItem>
          </ul>
        </nav>
      )}
    </div>
  );
}
