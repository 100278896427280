/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { withTheme, Theme } from '@emotion/react';

type ElementProps = React.ComponentProps<'div'> & {
  theme: Theme;
};

export const Container = withTheme((props: ElementProps) => (
  <div
    css={{
      margin: '0 auto',
      [props.theme.breakpoint('md')]: {
        maxWidth: props.theme.spacing(130),
      },
    }}
    {...props}
  >
    {props.children}
  </div>
));

export const Row = withTheme((props: ElementProps) => (
  <div
    css={{
      display: 'flex',
      flexWrap: 'wrap',
      padding: props.theme.spacing(1),
      [props.theme.breakpoint('md')]: {
        padding: props.theme.spacing(2),
      },
    }}
    {...props}
  >
    {props.children}
  </div>
));

export const Col = withTheme((props: ElementProps) => (
  <div
    css={{
      width: '100%',
      flexGrow: 0,
      padding: props.theme.spacing(1),
      [props.theme.breakpoint('md')]: {
        padding: props.theme.spacing(2),
      },
    }}
    {...props}
  >
    {props.children}
  </div>
));
