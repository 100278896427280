import { Dispatch } from 'redux';
import { getType, isActionOf } from 'typesafe-actions';

import { Api } from 'common/redux/make-api';
import { INVENTORY } from 'common/redux/types/feature';
import { setLoader } from 'common/redux/ui/loader/loader-action-creators';
import { fetchInventory, setInventory } from 'admin/inventory/redux/inventory-action-creators';
import Inventory from 'admin/inventory/types/inventory';
import { InventoryAction } from 'admin/inventory/redux/inventory-action-creators';

const makeCatalogRouteMiddleware = (inventoryApi: Api<Inventory>) => () => (next: Dispatch) => (
  action: InventoryAction
) => {
  next(action);

  switch (action.type) {
    case getType(fetchInventory):
      next(setLoader(true, INVENTORY));
      next(inventoryApi.actions.fetch());
      break;
    case getType(inventoryApi.actions.fetchSuccess):
      next(setLoader(false, INVENTORY));
      if (isActionOf(inventoryApi.actions.fetchSuccess, action)) {
        next(setInventory(action.payload.data));
      }
      break;
  }
};

export default makeCatalogRouteMiddleware;
