/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { PropsWithChildren } from 'react';



interface TooltipWrapProps {
  text: string
}

export default function TooltipWrap(props: PropsWithChildren<TooltipWrapProps>) {
  return (
    <div className="tooltip-wrap">
      <style>
        {`
          .hover-tooltip {
            position: relative;
          }
          .hover-tooltip .tooltiptext {
            visibility: hidden;
            width: 200px;
            bottom: 110%;
            left: 50%;
            margin-left: -100px;
            background-color: grey;
            opacity: 0.9;
            color: #fff;
            text-align: center;
            border-radius: 6px;
            padding: 5px 0;

            /* Position the tooltip */
            position: absolute;
            z-index: 1;
          }
          .hover-tooltip:hover .tooltiptext {
            visibility: visible;
            padding: 10px;
          }
        `}
      </style>

      <div className="hover-tooltip">
        {props.children}
        <span className="hover-tooltip tooltiptext">{props.text}</span>
      </div>
  </div>
  )
}
