import { createAction, ActionType } from 'typesafe-actions';
import { AdminUserSearchState } from '../types/state';


/**
 * Creates a 'document action' to set loading status
 */
export const setAdminUserSearchState = createAction('SET_USER_INTERACTION', action => (searchState: AdminUserSearchState) => action(searchState));

export type UserInteractionAction = ActionType<typeof setAdminUserSearchState>;
