import { InventoryState } from 'common/redux/types/state';
import { getType, isActionOf } from 'typesafe-actions';
import { InventoryAction, setInventory } from './inventory-action-creators';


const initialState = {
  data: [],
  meta: {
    fetched: false,
  },
};

function inventoryReducer(
  state: InventoryState = initialState,
  action: InventoryAction
): InventoryState {
  switch (action.type) {
    case getType(setInventory):
      if (isActionOf(setInventory, action)) {
        return {
          data: action.payload,
          meta: { fetched: true },
        };
      }
      return state;
    default:
      return state;
  }
}

export default inventoryReducer;
