/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { withTheme, Theme } from '@emotion/react';

type SelectProps = React.ComponentProps<'select'> & {
  theme: Theme;
  borderColor?: string;
};

function Select({ id, value, theme, children, onChange, borderColor }: SelectProps) {
  return (
    <div
      css={{
        borderWidth: 0,
        [theme.breakpoint('md')]: {
          borderWidth: 1,
        },
        borderStyle: 'solid',
        borderColor: borderColor || theme.palette.card.color,
      }}
    >
      <select
        id={id}
        value={value}
        onChange={onChange}
        css={{
          '&:focus': {
            outline: 'none',
          },
          color: theme.palette.card.color,
          cursor: 'pointer',
          fontSize: '.875em',
          width: '100%',
          borderWidth: 1,
          height: theme.spacing(5),
          [theme.breakpoint('md')]: {
            borderWidth: 0,
            height: theme.spacing(4),
          },
          borderStyle: 'solid',
          borderColor: borderColor || theme.palette.card.color,
          background: theme.palette.card.background,
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2),
          textTransform: 'capitalize',
        }}
      >
        {children}
      </select>
    </div>
  );
}

export default withTheme(Select);
