import { getType, isActionOf } from 'typesafe-actions';

import { UserOrderHistoryState } from '../../common/redux/types/state';
import { UserOrderHistoryAction, setUserOrderHistory } from './user-order-history-action-creators';

const initialState = {
  data: [],
  meta: {
    fetched: false,
  },
};

function userOrderHistoryReducer(
  state: UserOrderHistoryState = initialState,
  action: UserOrderHistoryAction
): UserOrderHistoryState {
  switch (action.type) {
    case getType(setUserOrderHistory):
      if (isActionOf(setUserOrderHistory, action)) {
        return {
          ...state,
          data: action.payload,
          meta: { fetched: true },
        };
      }
      return state;
    default:
      return state;
  }
}

export default userOrderHistoryReducer;
