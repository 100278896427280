/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { withTheme, Theme } from '@emotion/react';
import { useSelector } from 'react-redux';

import { getShopSettings } from '../../shop-settings/redux/shop-settings-selectors';

interface ShopTaglineProps {
  theme: Theme;
}

function ShopTagline({ theme }: ShopTaglineProps) {
  const shopSettings = useSelector(getShopSettings);
  return (
    <p
      css={{
        textAlign: 'center',
        marginTop: 0,
        marginBottom: theme.spacing(2),
        color: theme.palette.body.color,
        padding: theme.spacing(1),
      }}
      data-testid="shop-tagline"
    >
      {shopSettings.shopTagline}
    </p>
  );
}

export default withTheme(ShopTagline);
