import { Dispatch } from 'redux';
import { getType, isActionOf } from 'typesafe-actions';

import { Api } from '../../common/redux/make-api';
import ShopSettings from '../types/shop-settings';
import { SHOP_SETTINGS } from '../../common/redux/types/feature';
import { setLoader } from '../../common/redux/ui/loader/loader-action-creators';
import {
  ShopSettingsAction,
  setShopSettings,
  fetchShopSettings,
  updateShopSettings,
} from './shop-settings-action-creators';
import { setNotification } from '../../common/redux/ui/notification/notification-action-creators';

const makeShopSettingsRouteMiddleware = (shopSettingsApi: Api<ShopSettings>) => () => (
  next: Dispatch
) => (action: ShopSettingsAction) => {
  next(action);

  switch (action.type) {
    case getType(fetchShopSettings):
      next(setLoader(true, SHOP_SETTINGS));
      next(shopSettingsApi.actions.fetch());
      break;
    case getType(shopSettingsApi.actions.fetchSuccess):
      next(setLoader(false, SHOP_SETTINGS));
      if (isActionOf(shopSettingsApi.actions.fetchSuccess, action)) {
        next(setShopSettings(action.payload.data));
      }
      break;
    case getType(updateShopSettings):
      next(setLoader(true, SHOP_SETTINGS));
      if (isActionOf(updateShopSettings, action)) {
        next(shopSettingsApi.actions.update(action.payload));
      }
      break;
    case getType(shopSettingsApi.actions.updateSuccess):
      next(setLoader(false, SHOP_SETTINGS));
      if (isActionOf(shopSettingsApi.actions.updateSuccess, action)) {
        next(setShopSettings(action.payload.data));
        next(setNotification('success', 'Shop settings updated successfully', SHOP_SETTINGS));
      }
      break;
  }
};

export default makeShopSettingsRouteMiddleware;
