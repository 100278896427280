/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';

import { Heading, Small } from '../../../common/components/typography';

interface CatalogItemCardPointsProps {
  points: number;
}

export default function CatalogItemCardPoints({ points }: CatalogItemCardPointsProps) {
  return (
    <Heading as="h3" data-testid="catalog-item-points">
      {points} <Small>Points</Small>
    </Heading>
  );
}
