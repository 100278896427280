import makeDevHttpClient from './make-dev-http-client';
import { AxiosInstance } from 'axios';

import makeProdHttpClient from './make-prod-http-client';
import ApiUrl from '../types/api-url';
import { ApiResponse, ApiConfig } from '../redux/types/common';

export interface HttpClient {
  get: <T>(url: ApiUrl, config?: ApiConfig) => Promise<ApiResponse<T>>;
  post: <T>(url: ApiUrl, body: {}, config?: ApiConfig) => Promise<ApiResponse<T>>;
  put: <T>(url: ApiUrl, body: {}, config?: ApiConfig) => Promise<ApiResponse<T>>;
  delete: <T>(url: ApiUrl, body: {}, config?: ApiConfig) => Promise<ApiResponse<T>>;
}

export default function makeHttpClient(axiosInstance: AxiosInstance): HttpClient {
  return process.env.REACT_APP_ENGAGE_STORE_ENV === 'production'
    ? makeProdHttpClient(axiosInstance)
    : makeDevHttpClient();
}
