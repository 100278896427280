/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { withTheme, Theme } from '@emotion/react';
import { useSelector } from 'react-redux';

import { getShopSettings } from '../../shop-settings/redux/shop-settings-selectors';

interface ShopNameProps {
  theme: Theme;
}

function ShopName({ theme }: ShopNameProps) {
  const shopSettings = useSelector(getShopSettings);
  return (
    <h1
      css={{
        lineHeight: 1,
        fontSize: '3em',
        textAlign: 'center',
        fontWeight: 'normal',
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(4),
        color: theme.palette.accent.color,
      }}
      data-testid="shop-name"
    >
      <strong>{shopSettings.shopName}</strong>
    </h1>
  );
}

export default withTheme(ShopName);
