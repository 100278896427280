/**
 * Returns a copy of the given object including the given property => value
 */
export default function assoc(
  property: string | number,
  value: any,
  object: {}
) {
  return { ...object, [property]: value };
}
