import React from 'react';

import makeAuthUtils from './make-auth-utils';
import AuthConfig from './types/auth-config';
import {
  DEFAULT_CALLBACK_PATHNAME,
  DEFAULT_TOKEN_CACHE_KEY,
  DEFAULT_CALLBACK_TOKEN_PARAM,
  DEFAULT_CALLBACK_STATUS_PARAM,
  DEFAULT_CALLBACK_MESSAGE_PARAM,
} from './auth-defaults';
import AuthWrapper, { AuthWrapperProps } from './components/auth-wrapper';

const makeAuthWrapper = (props: AuthConfig) => {
  const authUtils = makeAuthUtils({
    authStatusEndpoint: props.authStatusEndpoint,
    tokenCacheKey: props.tokenCacheKey || DEFAULT_TOKEN_CACHE_KEY,
    callbackPathname: props.callbackPathname || DEFAULT_CALLBACK_PATHNAME,
    callbackTokenParam: props.callbackTokenParam || DEFAULT_CALLBACK_TOKEN_PARAM,
    callbackMessageParam: props.callbackMessageParam || DEFAULT_CALLBACK_MESSAGE_PARAM,
    callbackStatusParam: props.callbackStatusParam || DEFAULT_CALLBACK_STATUS_PARAM,
  });

  const Wrapper = ({ renderPrivateApp }: Pick<AuthWrapperProps, 'renderPrivateApp'>) => (
    <AuthWrapper utils={authUtils} renderPrivateApp={renderPrivateApp} />
  );

  return Wrapper;
};

export default makeAuthWrapper;
