import { Dispatch } from 'redux';
import { getType } from 'typesafe-actions';

import {
  CartAction,
  addItemToCart,
  removeItemFromCart,
  setCartShipping,
  setCartShippingLocation,
  addCartToCache,
  showAdditionalAddress,
} from './cart-action-creators';
import { OrderAction } from '../../order/redux/order-action-creators';

const cartRouteMiddleware = () => (next: Dispatch) => (action: CartAction | OrderAction) => {
  next(action);
  switch (action.type) {
    case getType(showAdditionalAddress):
    case getType(addItemToCart):
    case getType(removeItemFromCart):
    case getType(setCartShippingLocation):
      next(addCartToCache());
      break;
    case getType(setCartShipping):
      next(addCartToCache());
      break;

  }
};

export default cartRouteMiddleware;
