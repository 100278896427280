import { Dispatch } from 'redux';
import { getType, isActionOf } from 'typesafe-actions';

import { Api } from '../../common/redux/make-api';
import CatalogItem from '../types/catalog-item';
import { CATALOG } from '../../common/redux/types/feature';
import { arrayToObject } from '../../common/utils/array-to-object';
import { setLoader } from '../../common/redux/ui/loader/loader-action-creators';
import { CatalogAction, fetchCatalog, setCatalog } from './catalog-action-creators';
import Catalog from '../types/catalog';

const makeCatalogRouteMiddleware = (catalogApi: Api<Catalog>) => () => (next: Dispatch) => (
  action: CatalogAction
) => {
  next(action);

  switch (action.type) {
    case getType(fetchCatalog):
      next(setLoader(true, CATALOG));
      next(catalogApi.actions.fetch());
      break;
    case getType(catalogApi.actions.fetchSuccess):
      next(setLoader(false, CATALOG));
      if (isActionOf(catalogApi.actions.fetchSuccess, action)) {
        const catalogById = arrayToObject<CatalogItem, 'id'>('id', action.payload.data);
        next(setCatalog(catalogById));
      }
      break;
  }
};

export default makeCatalogRouteMiddleware;
