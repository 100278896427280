import { createAction, ActionType } from 'typesafe-actions';

import Order from '../types/order';
import { CREATE } from '../../common/redux/constants';
import { ORDER } from '../../common/redux/types/feature';

export const createOrder = createAction(`${ORDER} ${CREATE}`, action => (order: Order) =>
  action(order)
);

export type OrderAction = ActionType<typeof createOrder>;
