import { getType } from 'typesafe-actions';

import assoc from '../../../utils/assoc';
import dissoc from '../../../utils/dissoc';
import { NotificationsState } from '../../types/state';
import {
  NotificationAction,
  setNotification,
  removeNotification,
  setNotificationWithTimeout
} from './notification-action-creators';


const initialState: NotificationsState = {};

export default function notificationReducer(state: NotificationsState = initialState, action: NotificationAction) {
  switch (action.type) {
    case getType(setNotificationWithTimeout):
    case getType(setNotification): {
      return action.payload.id
        ? assoc(action.payload.id, { ...action.payload, meta: action.meta }, state)
        : state;
    }
    case getType(removeNotification): {
      return dissoc(`${action.payload.id}`, state);
    }
    default:
      return state;
  }
}
