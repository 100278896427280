/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';

import Badge from '../../../common/components/badge';
import { Small } from '../../../common/components/typography';

interface PointsProps {
  points: number;
}

const Points = ({ points }: PointsProps) => (
  <Badge>
    {points} <Small>Points</Small>
  </Badge>
);

export default Points;
