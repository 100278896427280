/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import {Fragment, useCallback} from 'react';
import { useDropzone, FileRejection, FileError, ErrorCode, DropzoneOptions} from 'react-dropzone';
import { useTheme } from '@emotion/react';
import { ReactComponent as DeleteIcon } from '@fortawesome/fontawesome-pro/svgs/solid/trash.svg';
import Thumbnail from '../../common/components/thumbnail';
import { useDispatch } from 'react-redux';
import { setLoader } from '../../common/redux/ui/loader/loader-action-creators';
import { UPLOAD_ITEM_IMAGE } from '../../common/redux/types/feature';
import { setNotification } from '../../common/redux/ui/notification/notification-action-creators';
import { fetchCatalog } from '../../catalog/redux/catalog-action-creators';
import { fetchInventory } from 'admin/inventory/redux/inventory-action-creators';
import { uploadImage } from 'common/utils/make-api-request';

const maxUploadSize = 10; // mb
const fileUploadCount = 1;


export type ImageDropzoneOptions =
{
  populateImageUrl? : string
  id? : string;
  imageFor: string;
  onDelete?: () => void;
  options?: DropzoneOptions;
  disabled: boolean;
}

const withCustomMsg = (rejectedFiles: FileRejection[]) => {
  let errorMsgs: string[] = []
  let fileMsgs: string[] = []
  let tooManyFiles: boolean = false
  rejectedFiles.forEach((rejectedFile: FileRejection) => {
    errorMsgs = []
    rejectedFile.errors.forEach((error: FileError) => {
      switch (error.code) {
        case ErrorCode.TooManyFiles:
          tooManyFiles = true
          break;
        case ErrorCode.FileTooLarge:
          errorMsgs.push(`File is too large (${maxUploadSize} MB)`);
          break;
        case ErrorCode.FileTooSmall:
          errorMsgs.push(`File is not large enough`);
          break;
        case ErrorCode.FileInvalidType:
          errorMsgs.push(`Invalid file type`);
          break;
        default:
          errorMsgs.push('An error occurred');
      }
    })
    if (errorMsgs.length > 0) {
      fileMsgs.push(`${rejectedFile.file.name}: ${errorMsgs.join(', ')}`)
    }
  })
  if (tooManyFiles) {
    fileMsgs.unshift(`Too Many files, only ${fileUploadCount} file(s) allowed.`)
  }
  return fileMsgs.join('\n')
}

export default function ImageDropzone({
  populateImageUrl,
  id,
  imageFor,
  onDelete,
  disabled
}: ImageDropzoneOptions) {

  const dispatch = useDispatch();
  const showDeleteButton = () => {
    let button = document.getElementById(`delete-button-${imageFor}-${id}`)
    if (button) {
      button.style.height = "40px"
    }
  }
  const hideDeleteButton = () => {
    let button = document.getElementById(`delete-button-${imageFor}-${id}`)
    if (button) {
      button.style.height = '0'
    }
  }

  // called when an image is selected or dropped in the dropzone
  const onDrop = useCallback((acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
    // handle rejections
    if(rejectedFiles.length>0){
      if(acceptedFiles.length>0){ // catch error case where a file is accepted even if there are rejections
        // create a new FileRejection[] with acceptedFiles and errors as TooManyFiles
        rejectedFiles.push(
          {
            file: acceptedFiles[0],
            errors: [{code: ErrorCode.TooManyFiles, message: "too many files"}]
          }
        )
      }
      alert(withCustomMsg(rejectedFiles))
      return
    }

    //handle accepted files
    let reader = new FileReader();
    reader.readAsDataURL(acceptedFiles[0]);
    reader.onload = (e: Event) => {
      if (!e.target) { return }
      // construct an empty request body
      const form = new FormData();
      // populate request body with the image and id of item or variant
      if(id){
        form.append('id', id)
      }
      form.append('image', acceptedFiles[0])
      form.append('type', imageFor)

      dispatch(setLoader(true, UPLOAD_ITEM_IMAGE))

      uploadImage(form).then(()=> {
        dispatch(setLoader(false, UPLOAD_ITEM_IMAGE))
        dispatch(fetchCatalog())
        dispatch(fetchInventory())
        dispatch(setNotification('success', "Image updated", "[UI]"))
      }).catch( err => {
        dispatch(setLoader(false, UPLOAD_ITEM_IMAGE))
        dispatch(setNotification('failure', "There was an issue uploading the image: " + err.message, "[UI]"))
      })
    }

  }, [id, dispatch, imageFor]);

  const { getRootProps, getInputProps, isDragActive} = useDropzone({
    onDrop,
    accept: ['image/jpeg','image/png', 'image/gif'],
    maxSize: maxUploadSize * (1024 * 1024), // bytes
    maxFiles: fileUploadCount,
    multiple: false
  });

  const theme = useTheme();

  return (
    <div
      id={`${id}-${imageFor} Dropzone`}
      css={{
        position: 'relative',
        height: '90% ',
        minHeight: '250px',
        border: '2px dashed #ccc',
        borderColor: isDragActive ? theme.palette.accent.color : theme.palette.common.lightGrey,
        borderRadius: '5px',
        padding: '20px',
        textAlign: 'center',
        lineHeight: '1.5',
        fontSize: '13px',
        color: theme.palette.common.darkGrey,
        backgroundColor: isDragActive ? theme.palette.common.lightGrey: theme.palette.common.white,
        cursor: disabled ? 'cursor' : 'pointer',
        transition: 'all 0.2s ease-in-out',
        '&:hover': {
          borderColor: theme.palette.accent.color,
          backgroundColor: theme.palette.common.lightGrey
        }
      }}
      onMouseOver={showDeleteButton}
      onMouseOut={hideDeleteButton}
      {...(!disabled && getRootProps())}
      >
      <input {...getInputProps()} />
      { populateImageUrl?
      <Fragment>
        <div css={{height: '75%'}}>
          <Thumbnail alt={`${imageFor} Thumbnail`} css={{border: 'none', maxHeight: '100%'}} imageUrl={populateImageUrl}/>
        </div>
        {!disabled && (
          <div
            title={`Delete ${imageFor} image`}
            id={`delete-button-${imageFor}-${id}`}
            css={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              width: "100%",
              height: "0px",
              backgroundColor: theme.palette.common.darkGrey,
              borderRadius: '0 0 5px 5px',
              transition: 'all 0.2s ease-in-out',
              overflow: 'hidden',
              '&:hover': {
                backgroundColor: theme.palette.common.red
              }
            }}
            onClick={(e) => {
              e.stopPropagation()
              if (e.ctrlKey || window.confirm(`Are you sure you want to delete this image?\nHold ctrl key while clicking delete to skip this warning.`)) {
                if (onDelete) {
                  onDelete();
                }
              }
            }}
          >
          <div css={{height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'hidden'}} >
            <DeleteIcon
              fill='white'
              css={{
                height: '20px',
              }}
            />
          </div>
        </div>
        )}
      </Fragment>
      :
      <p> Click or drag a jpeg, png, or gif here</p>
      }
    </div>
  )
}

ImageDropzone.defaultProps = {
  disabled: false
}
