import { createSelector } from 'reselect';

import State, { LoaderState } from '../../types/state';
import { SHOP_SETTINGS, CATALOG, UPLOAD_ITEM_IMAGE, UPLOAD_ITEM_SIZECHART_IMAGE, UPLOAD_ITEM_VARIANT_IMAGE, PDF_GENERATOR, CSV_GENERATOR, ORDER } from '../../types/feature';

function getLoaderState(state: State) {
  return state.ui.loader;
}

export const getShopSettingsLoadingState = createSelector(
  getLoaderState,
  (loaderState: LoaderState) => loaderState[SHOP_SETTINGS]
);

export const getCatalogLoadingState = createSelector(
  getLoaderState,
  (loaderState: LoaderState) => loaderState[CATALOG]
);

export const getImageUploadLoadingState = createSelector(
  getLoaderState,
  (loaderState: LoaderState) => loaderState[UPLOAD_ITEM_IMAGE]
);

export const getSizeChartUploadLoadingState = createSelector(
  getLoaderState,
  (loaderState: LoaderState) => loaderState[UPLOAD_ITEM_SIZECHART_IMAGE]
);

export const getVariantUploadLoadingState = createSelector(
  getLoaderState,
  (loaderState: LoaderState) => loaderState[UPLOAD_ITEM_VARIANT_IMAGE]
);

export const getPDFGeneratorLoadingState = createSelector(
  getLoaderState,
  (loaderState: LoaderState) => loaderState[PDF_GENERATOR]
);

export const getCSVGeneratorLoadingState = createSelector(
  getLoaderState,
  (loaderState: LoaderState) => loaderState[CSV_GENERATOR]
);

export const getOrderLoadingState = createSelector(
  getLoaderState,
  (loaderState: LoaderState) => loaderState[ORDER]
)
