import { createAction, ActionType } from 'typesafe-actions';

import Cart, { CartShipping, CartShippingLocation } from '../types/cart';
import { SET } from '../../common/redux/constants';
import { CART } from '../../common/redux/types/feature';
import CatalogItem from '../../catalog/types/catalog-item';

/**
 * Creates a 'document action' to load cart cache from localstorage into redux store
 */
export const getCartFromCache = createAction(`${CART} GET_CART_FROM_CACHE`, action => (catalog: CatalogItem[] ) =>
  action()
);

export const removeCartFromCache = createAction(`${CART} REMOVE_CART_FROM_CACHE`, action => () =>
  action()
);

export const addCartToCache = createAction(`${CART} ADD_CURRENT_CART_TO_CACHE`, action => () =>
  action()
);

/**
 * Set the order placed variable in the cart to indicate successful and failed tx
 */
export const setOrderPlaced = createAction(`${CART} ORDER_PLACED`, action => (orderPlaced: boolean) =>
  action(orderPlaced)
);

/**
 * Creates a 'document action' to add a catalog item to the cart
 */
export const addItemToCart = createAction(`${CART} ADD_ITEM`, action => (catalogItemId: string) =>
  action(catalogItemId)
);

/**
 * Creates a 'command action' to remove a catalog item from the cart
 */
export const removeItemFromCart = createAction(
  `${CART} REMOVE_ITEM`,
  action => (catalogItemId: string) => action(catalogItemId)
);

/**
 * Creates a 'command action' to set shipping address on the cart
 */
export const setCartShipping = createAction(
  `${CART} SET_SHIPPING_ADDRESS`,
  action => (cartShipping?: CartShipping) => action(cartShipping)
);

/**
 * Creates a 'command action' to set shipping location on the cart
 */
export const setCartShippingLocation = createAction(
  `${CART} SET_SHIPPING_LOCATION`,
  action => (cartShippingLocation?: CartShippingLocation) => action(cartShippingLocation)
);

export const showAdditionalAddress = createAction(
  `${CART} SHOW_ADDITIONAL_ADDRESS`,
  action => (show: boolean) => action(show)
);

/**
 * Creates a 'document action' to set new shopping cart on the redux store
 */
export const setCart = createAction(`${CART} ${SET}`, action => (cart: Cart) => action(cart));

export const resetCart = createAction(`${CART} RESET`, action => () => action());

export type CartAction = ActionType<
  | typeof setOrderPlaced
  | typeof addItemToCart
  | typeof removeItemFromCart
  | typeof setCartShipping
  | typeof setCartShippingLocation
  | typeof getCartFromCache
  | typeof removeCartFromCache
  | typeof addCartToCache
  | typeof showAdditionalAddress
  | typeof setCart
  | typeof resetCart
>;
