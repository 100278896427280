/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useTheme } from '@emotion/react';
import { ReactComponent as ChevronUp } from '@fortawesome/fontawesome-pro/svgs/solid/chevron-up.svg';
import { ReactComponent as ChevronDown } from '@fortawesome/fontawesome-pro/svgs/solid/chevron-down.svg';


interface CardHeaderProps {
  open: boolean;
  onToggle: () => void;
}

export default function CardToggle({ open, onToggle }: CardHeaderProps) {
  const theme = useTheme();
  return (
    <button
      data-testid={open ? 'card-toggle-up' : 'card-toggle-down'}
      css={{
        padding: 0,
        lineHeight: 0,
        border: 'none',
        cursor: 'pointer',
        background: 'transparent',
        marginTop: theme.spacing(0.25),
        '&:focus, &:active': { outline: 'none' },
      }}
      onClick={onToggle}
    >
      {open ? (
        <ChevronUp
          width={theme.spacing(2)}
          height={theme.spacing(2)}
          fill={theme.palette.card.color}
        />
      ) : (
        <ChevronDown
          width={theme.spacing(2)}
          height={theme.spacing(2)}
          fill={theme.palette.card.color}
        />
      )}
    </button>
  );
}
