/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx } from '@emotion/core';


import Card from '../../../common/components/card';
import { useTheme } from '@emotion/react';
import { Variant } from '../../../catalog/types/variant';
import EditableInventoryItemVariant from './editable-inventory-item-variant';

interface EditableInventoryItemVariantListProps {
  variants?: Variant[];
  entityName: string;
  isLoading: boolean;
  showDeletedVariants: boolean;
  disableRestore: boolean;
}

export default function EditableInventoryItemVariantList({
  variants,
  entityName,
  isLoading,
  showDeletedVariants,
  disableRestore,

}: EditableInventoryItemVariantListProps) {
  const theme = useTheme();

  const getCardTitle = (variant: Variant) => {
    if (variant.size && variant.color) {
      return `Size: ${variant.size} | Color: ${variant.color}`;
    } else if (variant.size) {
      return `Size: ${variant.size}`;
    } else if (variant.color) {
      return `Color: ${variant.color}`;
    } else {
      return 'No size or color';
    }
  }

  return (
    <div data-testid="editable-inventory-item-variant-list">
    { variants?.map(variant => (!variant.deletedAt || showDeletedVariants) && (
      <Card key={`card_variant_${variant.id}`}
        title={ `${getCardTitle(variant)}`}
        previewImage={variant.imageUrl}
        toggleable
        closed
        css={{
          borderRadius: '10px',
          marginTop: theme.spacing(2),
          border: variant.deletedAt? `${theme.palette.common.red} solid 3px`: "none",
        }}
        >
        <EditableInventoryItemVariant variant={variant} isLoading={isLoading} entityName={entityName} disableRestore={disableRestore}/>
      </Card>
      ))
      }
    </div>

  )
}

EditableInventoryItemVariantList.defaultProps = {
  showDeletedVariants: false,
  disableRestore: false,
}
