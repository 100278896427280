/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useTheme } from '@emotion/react';
import { useState, useCallback, ChangeEvent, FormEvent } from 'react';

import { Input } from '../../common/components/form';
import Button from '../../common/components/button';

interface SingleTextFormProps {
  onSubmit: (text: string) => void;
  buttonLabel: string;
  value?: string;
  placeholder?: string;
  isLoading?: boolean;
  clearInputOnSubmit?: boolean;
  onlyNumbers?: boolean;
}

export default function SingleTextForm({
  onSubmit,
  buttonLabel,
  value,
  placeholder,
  isLoading,
  clearInputOnSubmit = true,
  onlyNumbers = false,
}: SingleTextFormProps) {
  const theme = useTheme();

  const [inputValue, setInputValue] = useState(value || '');

  const isNumber = (x: any) => {
    const parsed = parseInt(x);
    if (isNaN(parsed)) return false;
    return parsed;
  }

  const onChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    let v = e.target.value
    if (onlyNumbers && e.target.value !== '-' && e.target.value !== '0') {
      if (!isNumber(e.target.value)) {
        setInputValue('');
        return;
      }
      v = `${isNumber(e.target.value)}`;
    }
    setInputValue(v);
  }, [onlyNumbers]);

  const handleSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (inputValue && !isLoading) {
        onSubmit(inputValue);
        if (clearInputOnSubmit) {
          setInputValue('');
        }
      }
    },
    [inputValue, onSubmit, isLoading, clearInputOnSubmit]
  );

  return (
    <form onSubmit={handleSubmit} css={{ display: 'flex', marginBottom: theme.spacing(2) }}>
      <Input value={inputValue} onChange={onChange} placeholder={placeholder} />
      <Button
        size="sm"
        type="submit"
        disabled={!inputValue || isLoading}
        css={{ height: 36, marginLeft: theme.spacing(2), marginRight: theme.spacing(2), fontSize: '.85em' }}
        outline
      >
        {buttonLabel}
      </Button>
    </form>
  );
}
