/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { ComponentProps } from 'react';
import { withTheme, Theme } from '@emotion/react';

type TableProps = ComponentProps<'table'> & { theme: Theme };

export const Table = withTheme(({ theme, children, ...restProps }: TableProps) => (
  <table
    cellSpacing={0}
    cellPadding={0}
    css={{
      fontSize: '.875em',
      width: '100%',
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: theme.palette.body.background,
    }}
    {...restProps}
  >
    {children}
  </table>
));

type TableSectionProps = ComponentProps<'thead'> & {
  as: 'thead' | 'tbody' | 'tfoot';
  theme: Theme;
};

export const TableSection = withTheme(
  ({ as, theme, children, ...restProps }: TableSectionProps) => {
    const styles = {
      thead: css({
        th: {
          textAlign: 'left',
          padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
          borderBottomWidth: 2,
          borderBottomStyle: 'solid',
          borderBottomColor: theme.palette.body.background,
        },
      }),
      tbody: css({
        td: {
          padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
          borderBottomWidth: 1,
          borderBottomStyle: 'solid',
          borderBottomColor: theme.palette.body.background,
        },
      }),
      tfoot: {
        fontWeight: 'bold',
        td: {
          padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
        },
      },
    };
    return jsx(as, { ...restProps, css: styles[as] }, children);
  }
);
