import { createSelector } from 'reselect';

import State, { UserInteractionState } from '../types/state';

function getUserInteractionState(state: State) {
  return state.userInteraction;
}

export const getAdminUserSearchInteractionState = createSelector(
  getUserInteractionState,
  (userInteractionState: UserInteractionState) => userInteractionState.adminUserSearch
)
