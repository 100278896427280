/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useTheme } from '@emotion/react';
import { useSelector } from 'react-redux';

import Card from '../../common/components/card';
import Button from '../../common/components/button';
import { getUser } from '../../user/redux/user-selectors';

import { getCartShippingDescription } from '../../cart/redux/cart-selectors';

interface OrderConfirmProps {
  onConfirm: () => void;
  onCancel: () => void;
}

export default function OrderConfirm({ onConfirm, onCancel }: OrderConfirmProps) {
  const user = useSelector(getUser);
  const shippingAddress = useSelector(getCartShippingDescription);
  const theme = useTheme();

  return (
    <Card css={{ position: 'absolute', width: theme.spacing(34), fontSize: '.85em', top: 0 }}>
      <p css={{ marginTop: 0 }}>
        <strong>{`${user.firstName} ${user.lastName}`}</strong>, please confirm your shipping
        address is <strong>{shippingAddress}</strong> before placing your order.
      </p>
      <div css={{ textAlign: 'right' }}>
        <Button outline size="sm" onClick={onCancel} css={{ marginRight: theme.spacing(1.5) }}>
          Cancel
        </Button>
        <Button size="sm" onClick={onConfirm} css={{ fontSize: '.85em' }}>
          Confirm
        </Button>
      </div>
    </Card>
  );
}
