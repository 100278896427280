/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { withTheme, Theme } from '@emotion/react';
import { useState, Fragment, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

import CatalogItemImage from './catalog-item-image';
import CatalogItemPoints from './catalog-item-points';
import CatalogItemTitle from './catalog-item-title';
import CatalogItemDescription from './catagog-item-description';
import ClaimButton from './claim-button';
import PointsAwayMessage from './points-away-message';
import UnavailableMessage from './unavailable-message';
import AccentBg from '../../../common/components/accent-bg';
import BackToStore from '../../../common/components/back-to-store';
import { Container, Row, Col } from '../../../common/components/layout';
import CatalogItem from '../../types/catalog-item';
import { makeCartItemId } from '../../../cart/cart-utils';
import { getVariantImageUrl } from '../../catalog-utils';
import VariantPicker from '../variant-picker';
import { filterVariantsFromCartItems, isVariantAvailable } from '../../../cart/redux/cart-selectors';

interface CatalogItemDetailsProps {
  theme: Theme;
  allowNegativeStock: boolean;
  addToCart: (id: string) => void;
  item: CatalogItem;
  userPoints: number;
}

function CatalogItemDetails({ theme, item, addToCart, userPoints, allowNegativeStock }: CatalogItemDetailsProps) {
  /*
  These two values (attr1Value\attr2Value) represent the selected dropdown state values of the variant pickers.
  They were moved here because the claim button needs to control reverting the state to the default values.
  */
  const [attr1Value, setAttr1Value] = useState('');
  const [attr2Value, setAttr2Value] = useState('');

  const [variantId, setVariantId] = useState('');


  useEffect(() => {
  if (item.variants && item.variants.length === 1) {
    if (item.variants[0].id) {
      setVariantId(item.variants[0].id);
    }
  }
}, [item.variants]);

  const onClaim = useCallback(() => {
    if (item.id === undefined) {return }
    addToCart(makeCartItemId(item.id, variantId));
    if (item.variants && !(item.variants.length === 1)) {
      setVariantId('');
    }
    setAttr1Value('');
    setAttr2Value('');
  }, [item.id, item.variants, addToCart, variantId]);

  const itemAvailable = item.availableQuantity > 0 || allowNegativeStock;
  const hasEnoughPoints = userPoints >= item.points;

  const itemHasOnlyOneVariant = item.variants && item.variants.length === 1;
  const singleVariant = item.variants ? item.variants[0] : undefined
  const isSingleVariantAvailable = useSelector(isVariantAvailable(singleVariant));


  // fixed bug filtering admin inventory list when 0 quantity.
  const variantsFilteredByCart = useSelector(filterVariantsFromCartItems(item.variants))

  return (
    <div
      data-testid="catalog-item-details"
      css={{
        position: 'relative',
        margin: '0 auto',
        maxWidth: theme.spacing(130),
      }}
    >
      <AccentBg
        css={{
          bottom: 110,
          borderRadius: '10px',
          [theme.breakpoint('md')]: {
            bottom: 120,
          },
        }}
      />
      <Container
        css={{
          maxWidth: theme.spacing(60),
          paddingTop: theme.spacing(2),
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2),
          [theme.breakpoint('md')]: {
            paddingTop: theme.spacing(8),
            paddingLeft: theme.spacing(4),
            paddingRight: theme.spacing(4),
          },
        }}
      >
        <BackToStore />
      </Container>
      <Container
        css={{
          maxWidth: theme.spacing(60),
        }}
      >
        <Row
          css={{
            [theme.breakpoint('md')]: {
              paddingTop: 0,
            },
          }}
        >
          <Col
            css={{
              [theme.breakpoint('md')]: {
                width: '50%',
              },
            }}
          >
          { item.id &&
            <CatalogItemImage url={getVariantImageUrl(variantId, item.imageUrl ? item.imageUrl : "", variantsFilteredByCart)} />
          }
          </Col>
          <Col
            css={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              [theme.breakpoint('md')]: {
                width: '50%',
              },
            }}
          >
            <div>
              <CatalogItemPoints points={item.points} />
              <CatalogItemTitle title={item.title} />
              <CatalogItemDescription description={item.description} sizeChart={item.sizeChart} />
              {!!variantsFilteredByCart && item.variants && item.variants.length > 1 && (
                <VariantPicker
                  css={{ marginBottom: theme.spacing(6) }}
                  onPick={setVariantId}
                  variants={variantsFilteredByCart}
                  inline
                  attr1Value={attr1Value}
                  attr2Value={attr2Value}
                  setAttr1Value={setAttr1Value}
                  setAttr2Value={setAttr2Value}
                />
              )}
            </div>
            {itemAvailable && !itemHasOnlyOneVariant ? (
              <Fragment>
                {hasEnoughPoints ? (
                  <ClaimButton disabled={!!variantsFilteredByCart && !variantId} onClick={onClaim} />
                ) : (
                  <PointsAwayMessage pointsAway={item.points - userPoints} />
                )}
              </Fragment>
            ) : itemHasOnlyOneVariant && isSingleVariantAvailable ? (
              <Fragment>
                {hasEnoughPoints ? (
                  <ClaimButton disabled={!isSingleVariantAvailable} onClick={onClaim} />
                ) : (
                  <PointsAwayMessage pointsAway={item.points - userPoints} />
                )}
              </Fragment>
            ) : (
              <UnavailableMessage />
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default withTheme(CatalogItemDetails);
