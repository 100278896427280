import { createSelector } from 'reselect';
import State, { CatalogById } from '../../common/redux/types/state';
import Cart, { CartItemsById, CartShipping, CartShippingLocation } from '../types/cart';
import { getCatalogById } from '../../catalog/redux/catalog-selectors';
import { CatalogItemInCart } from '../components/cart-item';
import { makeCartItemId, parseCartItemId } from '../cart-utils';
import { getShopSettings } from '../../shop-settings/redux/shop-settings-selectors';
import ShopSettings from '../../shop-settings/types/shop-settings';
import CatalogItem from '../../catalog/types/catalog-item';
import { Variant, Variants } from '../../catalog/types/variant';

export function getCart(state: State) {
  return state.cart;
}

export const getCartItemsById = createSelector(getCart, (cart: Cart) => cart.items);

export const getCatalogItemsInCart = createSelector(
  getCatalogById,
  getCartItemsById,
  (catalogById: CatalogById, cartItemsById: CartItemsById) => {
    return Object.keys(cartItemsById).map((cartItemId: string) => {
      const [itemId, cartItemVariantId] = parseCartItemId(cartItemId);
      const catalogItem = catalogById[itemId];
      const cartItemQuantity = cartItemsById[cartItemId];

      return { catalogItem, cartItemVariantId, cartItemQuantity, cartItemId };
    });
  }
);

export const getTotalPointsInCart = createSelector(
  getCatalogItemsInCart,
  (catalogItemsInCart: CatalogItemInCart[]): number =>
    catalogItemsInCart.reduce(
      (acc: number, { catalogItem, cartItemQuantity }: CatalogItemInCart) =>
        catalogItem ? (acc += catalogItem.points * cartItemQuantity) : acc,
      0
    )
);

export const getNumberOfItemsInCart = createSelector(
  getCatalogItemsInCart,
  (catalogItemsInCart: CatalogItemInCart[]): number =>
    catalogItemsInCart.reduce(
      (acc: number, { catalogItem, cartItemQuantity }: CatalogItemInCart) =>
        catalogItem ? (acc += cartItemQuantity) : acc,
      0
    )
);

export const getItemsInCartOutOfStock = createSelector(
  getCatalogItemsInCart,
  (catalogItemsInCart: CatalogItemInCart[]): number =>
    catalogItemsInCart.reduce(
      (acc: number, { catalogItem, cartItemQuantity }: CatalogItemInCart) =>
        catalogItem.availableQuantity - cartItemQuantity < 0 ? (acc += 1) : acc,
      0
    )
);

export const getItemAvailableQuantity = (item: CatalogItem) => createSelector(
  getCartItemsById,
  (cartItemsById: CartItemsById): number => {
    var id = makeCartItemId(item.id ? item.id : "", "")
    return item.availableQuantity - cartItemsById[id]
  }
)

export const isVariantAvailable = (variant: Variant | undefined) => createSelector(
  getCartItemsById,
  getShopSettings,
  (cartItemsById: CartItemsById, settings: ShopSettings): boolean => {
    // if we don't have a variant, it's not available
    if (!variant) return false;
    // if the store allows negative stock, who cares
    if (settings.allowNegativeStock) return true;
    // create the cart identifier to lookup how many we have in the current cart
    var id = makeCartItemId(
      variant.itemId ? variant.itemId : '',
      variant.id ? variant.id : '')
    // if there aren't any in the cart
    // eslint-disable-next-line eqeqeq
    if (cartItemsById[id] == undefined){
      //just check that the variants quantity is above 0
      if (variant.availableQuantity > 0) {
        return true;
      } else {
        return false;
      }
    }
    // otherwise, subtract the cart from available and check that it is greater than 0
    return (variant.availableQuantity - cartItemsById[id]) > 0
  }
)

//produce a list of selectable variants based on what we already have in our cart.
export const filterVariantsFromCartItems = (variants: Variants | undefined) => createSelector(
  getCatalogItemsInCart,
  getShopSettings,
  (catalogItemsInCart: CatalogItemInCart[], settings: ShopSettings): Variants | undefined => {
    if (settings.allowNegativeStock) return variants;
    if (!variants) return variants
    variants = variants.filter((variant) => variant.availableQuantity > 0)
    catalogItemsInCart.map((item) => {
      if (variants) {
        variants = variants.filter((variant) => {
          // eslint-disable-next-line eqeqeq
        if (item.cartItemVariantId == variant.id) {
          return (variant.availableQuantity - item.cartItemQuantity > 0)
        } else {
          return true
        }
      });
      }
      return true
    });
    return variants;
  }
)

export const getIsOrderPlaced = createSelector(
  getCart,
  (cart: Cart): boolean | undefined => cart.orderPlaced
)

export const getIsCartEmpty = createSelector(
  getNumberOfItemsInCart,
  (numberOfItems: number) => numberOfItems === 0
);

export const getCartShipping = createSelector(
  getCart,
  (cart: Cart): CartShipping | undefined => cart.shipping
);

export const getCartShippingLocation = createSelector(
  getCart,
  (cart: Cart): CartShippingLocation | undefined => cart.shippingLocation
);

export const getCartShippingDescription = createSelector(
  getCartShipping,
  getCartShippingLocation,
  getShopSettings,
  (cartShipping: CartShipping | undefined,  cartShippingLocation: CartShippingLocation | undefined, { shippingOption }: ShopSettings) => {
    if (shippingOption === "address and location") {
      if (cartShippingLocation) {
        if (cartShippingLocation.location_id !== "hookAddress") {
          return cartShippingLocation.location_description
        }
      }
      if (cartShipping) {
        if ('street_address_1' in cartShipping) {
          return `${cartShipping.street_address_1}, ${cartShipping.street_address_2}, ${cartShipping.city} ${cartShipping.state} - ${cartShipping.zipcode}`;
        }
      }
    } else {
      if (shippingOption === 'location') {
        if (!cartShippingLocation) return '';
        if (shippingOption === 'location' && 'location_description' in cartShippingLocation) {
          return cartShippingLocation.location_description;
        }
      }
      if (shippingOption === 'address') {
        if (!cartShipping) return '';
        if (shippingOption === 'address' && 'street_address_1' in cartShipping) {
          return `${cartShipping.street_address_1}, ${cartShipping.street_address_2}, ${cartShipping.city} ${cartShipping.state} - ${cartShipping.zipcode}`;
        }
      }
    }
    return '';
  }
);

export const getShowAdditionalAddress = createSelector(
  getCart,
  (cart: Cart): boolean | undefined => cart.showAdditionalAddress
);
