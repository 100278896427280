/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useSelector } from 'react-redux';
import AddressForm from './address-form';
import LocationSelect from './location-select';
import { getShopSettings } from '../../shop-settings/redux/shop-settings-selectors';
import ListItem from '../../common/types/list-item';
import { getShowAdditionalAddress } from '../redux/cart-selectors';


export default function LocationAndAddress() {

    const shopSettings = useSelector(getShopSettings);
    const label100Remote :ListItem[] = [{id: "hookAddress", description: shopSettings.label100Remote}];
    const showAddress = useSelector(getShowAdditionalAddress); 

    return (<div>
        <LocationSelect additionalItem={label100Remote} />
        {showAddress && <AddressForm /> }
        </div>
        );
}