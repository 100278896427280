import { getType, isActionOf } from 'typesafe-actions';

import { CatalogAction, setCatalog } from './catalog-action-creators';
import { CatalogState } from '../../common/redux/types/state';

const initialState = {
  data: {},
  meta: {
    fetched: false,
  },
};

function catalogReducer(
  state: CatalogState = initialState,
  action: CatalogAction
): CatalogState {
  switch (action.type) {
    case getType(setCatalog):
      if (isActionOf(setCatalog, action)) {
        return {
          data: action.payload,
          meta: { fetched: true },
        };
      }
      return state;
    default:
      return state;
  }
}

export default catalogReducer;
