/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useTheme } from '@emotion/react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/pro-solid-svg-icons';

interface paginatorProps {
  setPage(page: number): void
  currentPage: number
  pageCurrentLength: number
  pageMaxLength: number
  pageContainsData: boolean

}


export default function Paginator(props:paginatorProps) {
  const theme = useTheme();
  const nextPage = () => { if (props.pageContainsData && props.pageCurrentLength === props.pageMaxLength) props.setPage(props.currentPage+1) }
  const prevPage = () => { if (props.currentPage !== 0) props.setPage(props.currentPage-1)}

  const first = props.currentPage === 0
  const last = props.pageCurrentLength !== props.pageMaxLength

  const pageNum = () => {
    if (first && last) {
      return
    }
    return props.currentPage + 1
  }

  return (
    <div className="paginator" style={{userSelect: "none"}}>
      <a style={{cursor: "pointer", display: "inline-block", width: "25px", paddingRight: "10px"}} onClick={prevPage}>
      { !first &&
        <FontAwesomeIcon style={{color: theme.palette.accent.color}} icon={faArrowLeft} />
      }
      </a>
    {pageNum()}
      <a style={{cursor: "pointer", display: "inline-block", width: "25px", paddingLeft: "10px"}} onClick={nextPage}>
      { !last &&
        <FontAwesomeIcon style={{color: theme.palette.accent.color}} icon={faArrowRight} />
      }
      </a>
    </div>
  )
}
