/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Link } from 'react-router-dom';
import { withTheme, Theme } from '@emotion/react';

import { ReactComponent as ChevronLeftIcon } from '@fortawesome/fontawesome-pro/svgs/solid/chevron-left.svg';

interface BackToStoreProps {
  theme: Theme;
}

function BackToStore({ theme, ...restProps }: BackToStoreProps) {
  return (
    <Link
      css={{
        textDecoration: 'none',
        textTransform: 'uppercase',
        color: theme.palette.accent.contrastColor,
        '&:hover': {
          textDecoration: 'underline',
        },
        [theme.breakpoint('md')]: {
          fontSize: '1.125em',
        },
      }}
      to="/"
      {...restProps}
    >
      <ChevronLeftIcon
        width={theme.spacing(1)}
        height={theme.spacing(2)}
        fill={theme.palette.accent.contrastColor}
        css={{ verticalAlign: 'middle', marginTop: -2 }}
      />{' '}
      back to store
    </Link>
  );
}

export default withTheme(BackToStore);
