/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useTheme } from '@emotion/react';

import CardToggle from './card-toggle';
import CardAdd from './card-add';
import TooltipWrap from 'common/components/tooltip-wrap';

import { ReactComponent as ExclamationTriangle } from '@fortawesome/fontawesome-pro/svgs/solid/exclamation-triangle.svg';

interface CardHeaderProps {
  title: string;
  open: boolean;
  toggleable?: boolean;
  isAddNew?: boolean;
  previewImage?: string;
  headerWarning?: boolean;
  onToggle: () => void;
}

export default function CardHeader({ title, open, toggleable, isAddNew, previewImage, headerWarning, onToggle }: CardHeaderProps) {
  const theme = useTheme();
  const expansionType = () => {
    if (isAddNew) {
      return (
        <CardAdd open={open} onToggle={onToggle}/>
      )
    } else if (toggleable) {
      return (
        <CardToggle open={open} onToggle={onToggle} />
      )
    }
  }

  return (
    <header
      css={{
        display: 'flex',
        marginLeft: -theme.spacing(2),
        marginRight: -theme.spacing(2),
        justifyContent: 'space-between',
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        marginBottom: open ? theme.spacing(2) : -theme.spacing(2),
        borderBottom: open ? `1px ${theme.palette.common.lighterGrey} solid` : 'none',
      }}
    >

      <h3 css={{ margin: 0, fontWeight: 300, color: theme.palette.accent.color, lineHeight: 1 }}>
        {title}
      </h3>
      <div
      css={{
        display: 'flex',
      }}>
        <div
        css={{
          display: 'flex',
          flexDirection: 'row-reverse',
          justifyContent: 'space-between',
          width: '100px',
          marginRight: '20px',
        }}>
          {previewImage &&
            <img alt={"preview of item"} src={previewImage} height="30px" css={{borderRadius: "5px"}}/>
          }
          {headerWarning &&
            <TooltipWrap text="contains deleted variants">
              <ExclamationTriangle width={"30px"} css={{
                fill: `${theme.palette.common.red}`
              }}/>
            </TooltipWrap>
          }
        </div>
        <div>
          {expansionType()}
        </div>
      </div>
    </header>
  );
}
