/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx } from '@emotion/core';
import { useDispatch } from 'react-redux';

import { useTheme } from '@emotion/react';
import { Variant } from '../../../catalog/types/variant';
import { Form, FormField, FormRow, Input } from '../../../common/components/form';
import { ChangeEvent, FormEvent, Fragment, useCallback, useEffect, useState } from 'react';
import ImageDropzone from '../../../image-uploader/components/image-dropzone';
import { ReactComponent as DeleteIcon } from '@fortawesome/fontawesome-pro/svgs/solid/trash.svg';
import { deleteCatalogItemVariant, updateCatalogItemVariant } from '../../../catalog-item-variant/redux/catalog-item-variant-action-creators'
import Button from '../../../common/components/button';
import { ReactComponent as RestoreIcon } from '@fortawesome/fontawesome-pro/svgs/solid/trash-undo.svg';
import { setNotificationWithTimeout } from 'common/redux/ui/notification/notification-action-creators';
import { INVENTORY } from 'common/redux/types/feature';
interface EditableInventoryItemVariantProps {
  variant: Variant;
  entityName: string;
  isLoading: boolean;
  disableRestore: boolean;
}

export default function EditableInventoryItemVariant({
  variant,
  entityName,
  isLoading,
  disableRestore,
}: EditableInventoryItemVariantProps) {
  const dispatch = useDispatch();
  const theme = useTheme();

  const [newItem, setNewItem] = useState<Variant>(variant);

  const updateNewItem = useCallback((key: string, value: string | number | boolean) => {
    setNewItem(prevNewItem => {
      return key in prevNewItem ? { ...prevNewItem, [key]: value } : prevNewItem;
    });
  }, []);

  const isNumber = (x: any) => {
    const parsed = parseInt(x);
    if (isNaN(parsed)) return false;
    return parsed;
  }

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
      let { id, value } = e.target;
      if (id !== "points" && id !== "availableQuantity") {
        updateNewItem(id, value);
        return;
      }
      if (value !== '-' && value !== '0') {
        if (!isNumber(value)) {
          value = '';
          return;
        }
      }
      updateNewItem(id, parseInt(value));
  }

  const onCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = e.target;
    if (checked) updateNewItem(id, true);
    else  updateNewItem(id, false);
  }

    useEffect(() => {
      setNewItem(variant);
    }, [variant]);

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    dispatch(updateCatalogItemVariant(newItem));
  }

  return (
    <Fragment >
      <div data-testid={`editable-inventory-item-variant-${variant.id}`} css={{ height: "auto", display: 'flex', flexWrap: 'wrap', width: '100%', justifyContent: 'space-evenly'}}>
        {/* inputs div */}
        <div css={{width: '370px'}}>
          <div key={`size_${variant.itemId}_${variant.id}`} css={{ width: '100%', display: "block" }}>
              <Form data-testid="add-inventory-item-variant" onSubmit={onSubmit} css={{ border: theme.palette.card.color, padding: theme.spacing(1)}}>
                <FormRow>
                  <FormField inputId="size" label="Size" >
                    <Input id="size" value={newItem.size} onChange={onInputChange}/>
                  </FormField>
                  <FormField inputId="color" label="Color" >
                    <Input id="color" value={newItem.color} onChange={onInputChange}/>
                  </FormField>
                  <FormField inputId="availableQuantity" label="Quantity">
                    <Input id="availableQuantity" value={newItem.availableQuantity} onChange={onInputChange} />
                  </FormField>
                </FormRow>
                <FormRow>
                  <FormField inputId="buyerItemNumber" label="Buyer Number">
                    <Input id="buyerItemNumber" value={newItem.buyerItemNumber} onChange={onInputChange} />
                  </FormField>
                  <FormField inputId="unitOfMeasure" label="Unit Of Measure">
                    <Input id="unitOfMeasure" value={newItem.unitOfMeasure} onChange={onInputChange} />
                  </FormField>
                  <FormField inputId="visible" label="Visible">
                    <Input type="checkbox" id="visible" checked={newItem.visible} onChange={onCheckboxChange} css={{width:"40px"}} />
                  </FormField>
                </FormRow>
                <FormRow css={{justifyContent: 'space-between', alignItems: 'center'}}>
                  <Button css={{ margin: 8, height: 36 }} type="submit" size="sm">
                    Save
                  </Button>
                  {
                    variant.deletedAt ?
                    <RestoreIcon
                      title="Restore item variant"
                      fill={theme.palette.common.darkGrey}
                      css={{
                        height: '35px',
                        cursor: 'pointer',
                        '&:hover': {
                          fill: theme.palette.common.green
                        }
                      }}
                      onClick={(e)=> {
                        if(!disableRestore){
                          if (e.ctrlKey || window.confirm(`Are you sure you want to restore this variant?\nHold ctrl key while clicking restore to skip this warning.`)) {
                            dispatch(updateCatalogItemVariant({...variant, deletedAt: null}));
                          }
                        }else{
                          dispatch(setNotificationWithTimeout("info", "You must restore the item to restore this variant", INVENTORY, 5000));
                        }
                      }}
                    />
                    :
                    <DeleteIcon
                    title="Delete item variant"
                    fill={theme.palette.common.darkGrey}
                    css={{
                      height: '35px',
                      cursor: 'pointer',
                      '&:hover': {
                        fill: theme.palette.common.red
                      }
                    }}
                    onClick={(e) => {
                      if (e.ctrlKey || window.confirm(`Are you sure you want to delete this variant?\nHold ctrl key while clicking delete to skip this warning.`)) {
                        dispatch(deleteCatalogItemVariant(variant));
                      }
                    }}
                  />
                  }

                </FormRow>
              </Form>
          </div>
        </div>
        {/* Dropzone and checkbox div */}
        <div css={{textAlign: "center", width: '350px', display: 'flex'}}>
        {/* Dropzone div */}
          <div css={{ display: 'block', maxHeight: '300px', width: '100%'}}>
            <strong>Image</strong>
            <ImageDropzone populateImageUrl={variant.imageUrl} id={variant.id} imageFor="itemVariant" onDelete={() => dispatch(updateCatalogItemVariant({...variant, imageUrl: ''}))}/>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

EditableInventoryItemVariant.defaultProps = {
  disableRestore: false,
}
