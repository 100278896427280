/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useTheme } from '@emotion/react';
import React, { useEffect, useState } from 'react';

interface ThumbnailProps extends React.ImgHTMLAttributes<any>{
    imageUrl: string
}


let isGif = (url: string) => {
  let splitUrl = url.split('.')
  if (splitUrl[splitUrl.length - 1] === 'gif') {
      return true
  }
  return false
}

let getOptimizedUrl = (url: string) => {
  let newurlarr = url.split('.')
  let acceptedExtensions = ["jpg", "jpeg", "png", "webp"]
  if(acceptedExtensions.includes(newurlarr[newurlarr.length -1])) {
    newurlarr[newurlarr.length - 1] = "webp";
    return newurlarr.join('.');
  }
  return url + ".webp";
}

export default function Thumbnail({ imageUrl, ...restProps }: ThumbnailProps) {
    const theme = useTheme()

    const [imgSrc, setImgSrc] = useState<string | undefined>(undefined)

    // if imageUrl changes, setImgSrc in state to optimized imageurl.
    useEffect(() => {
        setImgSrc(getOptimizedUrl(imageUrl))
    }, [imageUrl])

        const imageUnavailable = () => {
        if (imgSrc === imageUrl) {
            setImgSrc(undefined)
        } else {
            setImgSrc(imageUrl)
            }
        }

    if (imageUrl === "") {
        return (
        <div
            data-testid="empty-thumbnail"
            css={{
                width: '100%',
                height: '100%',
                backgroundColor: theme.palette.card.background,
            }}
            {...restProps}
        />
        )
    } else if (isGif(imageUrl)) {
        return (
            // disabled as alts should be passed in
            // eslint-disable-next-line jsx-a11y/alt-text
            <img src={imageUrl}
              data-testid="gif-thumbnail"
                css={{
                    objectFit: 'contain',
                    width: '100%',
                    height: 'auto',
                }}
                {...restProps}
            />
        )
    }

    // Default case, image is not empty or gif
    return (
        // disabled as alts should be passed in
        // eslint-disable-next-line jsx-a11y/alt-text
      <img
            data-testid="thumbnail"
            src={imgSrc}
            css={{
                objectFit: 'contain',
                width: '100%',
                height: 'auto',
            }}
            onError={imageUnavailable}
            {...restProps}
        />
    )
}
