/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useTheme } from '@emotion/react';

import { Variants } from '../../types/variant';
import { getAttrNames } from './variant-picker-utils';
import SingleVariantPicker from './single-variant-picker';
import DoubleVariantPicker from './double-variant-picker';

interface VariantPickerProps {
  inline?: boolean;
  variants: Variants;
  attr1Value: string
  attr2Value: string
  setAttr1Value: (attr: string) => void;
  setAttr2Value: (attr: string) => void;
  onPick: (variantId: string) => void;
}

export default function VariantPicker({
  variants,
  onPick,
  inline,
  attr1Value,
  attr2Value,
  setAttr1Value,
  setAttr2Value,
  ...restProps
}: VariantPickerProps) {
  const theme = useTheme();

  if (variants.length === 0) return null;

  const attrNames = getAttrNames(variants[0]);

  return (
    <div
      css={{
        display: inline ? 'flex' : 'block',
        '> div': {
          flex: 1,
          maxWidth: inline ? '35%' : '100%',
          marginLeft: inline ? theme.spacing(2) : 0,
          marginBottom: inline ? 0 : theme.spacing(1),
        },
      }}
      {...restProps}
    >
      {attrNames.length === 1 ? (
        <SingleVariantPicker variants={variants} onPick={onPick} attrName={attrNames[0]}  attr1Value={attr1Value} setAttr1Value={setAttr1Value} />
      ) : (
        <DoubleVariantPicker variants={variants} onPick={onPick} attrNames={attrNames} attr1Value={attr1Value} attr2Value={attr2Value} setAttr1Value={setAttr1Value} setAttr2Value={setAttr2Value} />
      )}
    </div>
  );
}
