import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import "react-datepicker/dist/react-datepicker.css";
import 'bootstrap/dist/css/bootstrap.min.css';

import React from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/browser';

import App from './app';
import appConfig from './app-config';

Sentry.init({
  dsn: appConfig.sentryDsn,
  integrations: [
    new Sentry.Integrations.Breadcrumbs({
      console: false,
    }),
  ],
  blacklistUrls: [
    'http://localhost'
  ]
});

const container = document.getElementById('root');
if (container != undefined) {
  const root = createRoot(container);
  root.render(<App />);
} else {
  throw new Error('Failed to find the root element')
}
