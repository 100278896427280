/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useSelector, useDispatch } from 'react-redux';
import { useCallback, ChangeEvent, useState, FormEvent } from 'react';

import Select from '../../../common/components/select';
import { Form, FormRow, FormField, Input, FormFieldRow, Textarea } from '../../../common/components/form';
import { getShopSettings } from '../../../shop-settings/redux/shop-settings-selectors';
import { updateShopSettings } from '../../../shop-settings/redux/shop-settings-action-creators';
import Button from '../../../common/components/button';

export default function GeneralSettings() {
  const dispatch = useDispatch();
  const [settings, setSettings] = useState(useSelector(getShopSettings));

  const updateSetting = useCallback((key: string, value: string | boolean) => {
    setSettings(prevSettings => {
      return key in prevSettings ? { ...prevSettings, [key]: value } : prevSettings;
    });
  }, []);

  const onInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { id, value } = e.target;
      updateSetting(id, value);
    },
    [updateSetting]
  );

  const onTextAreaChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      const { id, value } = e.target;
      updateSetting(id, value);
    },
    [updateSetting]
  );

  const onSelectChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      const { id, value } = e.target;
      updateSetting(id, value);
    },
    [updateSetting]
  );

  const onSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      dispatch(updateShopSettings(settings));
    },
    [dispatch, settings]
  );

  const onCheckboxChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { id, checked } = e.target;
      if (checked) updateSetting(id, true);
      else  updateSetting(id, false);
    },
    [updateSetting]
  );

  return (
    <Form onSubmit={onSubmit}>
      <FormRow>
        <FormField inputId="shopName" label="Shop Name">
          <Input id="shopName" value={settings.shopName} onChange={onInputChange} />
        </FormField>
        <FormField inputId="shopTagline" label="Tagline">
          <Input id="shopTagline" value={settings.shopTagline} onChange={onInputChange} />
        </FormField>
        <FormField inputId="shopLogoUrl" label="Logo URL">
          <Input id="shopLogoUrl" value={settings.shopLogoUrl} onChange={onInputChange} />
        </FormField>
        <FormField inputId="homeUrl" label="Home URL">
          <Input id="homeUrl" value={settings.homeUrl} onChange={onInputChange} />
        </FormField>
        <FormField inputId="shopPointsBadgeUrl" label="Points Badge URL">
          <Input
            id="shopPointsBadgeUrl"
            value={settings.shopPointsBadgeUrl}
            onChange={onInputChange}
          />
        </FormField>
        <FormField inputId="contactEmail" label="Contact Email">
          <Input id="contactEmail" value={settings.contactEmail} onChange={onInputChange} />
        </FormField>
        <FormField inputId="shippingOption" label="Shipping Option">
          <Select id="shippingOption" value={settings.shippingOption} onChange={onSelectChange}>
            <option key="unset option">Select option</option>
            {['address', 'location', 'address and location'].map((shippingOption: string) => (
              <option key={shippingOption} value={shippingOption}>
                {shippingOption}
              </option>
            ))}
          </Select>
        </FormField>
        <FormField inputId="allowNegativeStock" label="Allow Negative Stock">
          <Input type="checkbox" id="allowNegativeStock" checked={settings.allowNegativeStock} onChange={onCheckboxChange} />
        </FormField>
        <FormField inputId="label100Remote" label="Label for 100% Remote">
          <Input type="input" id="label100Remote" value={settings.label100Remote} onChange={onInputChange} />
        </FormField>
      </FormRow>

      <FormRow>
        <FormFieldRow inputId="orderMessage" label="Order message (shown after employee confirms order)">
          <Textarea id="orderMessage" onChange={onTextAreaChange} defaultValue={settings.orderMessage} />
        </FormFieldRow>
      </FormRow>
      <FormRow>
        <FormFieldRow inputId="outOfStockMessage" label="Out of stock message (shown in the order when the item is out of stock)">
          <Textarea id="outOfStockMessage" onChange={onTextAreaChange} defaultValue={settings.outOfStockMessage} />
        </FormFieldRow>
      </FormRow>
      <FormRow>
        <FormFieldRow inputId="deadlineToReceiveMessage" label="Deadline to receive products message (shown in the order when the item is NOT out of stock)">
          <Textarea id="deadlineToReceiveMessage" onChange={onTextAreaChange} defaultValue={settings.deadlineToReceiveMessage} />
        </FormFieldRow>
      </FormRow>
      <FormRow>
      <FormField inputId="orderEmailLabel" label="Order email label">
          <Input type="input" id="orderEmailLabel" value={settings.orderEmailLabel} onChange={onInputChange} />
        </FormField>
      </FormRow>
<Button css={{ margin: 8, height: 36 }} type="submit" size="sm">
        Save Change
      </Button>
    </Form>
  );
}
