import { getType, isActionOf } from 'typesafe-actions';

import shopSettingsDefaults from './shop-settings-defaults';
import { ShopSettingsState } from '../../common/redux/types/state';
import { ShopSettingsAction, setShopSettings } from './shop-settings-action-creators';

const initialState = {
  data: shopSettingsDefaults,
  meta: {
    fetched: false,
  },
};

function shopSettingsReducer(
  state: ShopSettingsState = initialState,
  action: ShopSettingsAction
): ShopSettingsState {
  switch (action.type) {
    case getType(setShopSettings):
      if (isActionOf(setShopSettings, action)) {
        return {
          data: action.payload,
          meta: {
            fetched: true,
          },
        };
      }
      return state;
    default:
      return state;
  }
}

export default shopSettingsReducer;
