/** @jsxRuntime classic */
/** @jsx jsx */
import { useDispatch, useSelector } from 'react-redux';
import { jsx } from '@emotion/core';
import { useTheme } from '@emotion/react';
import { DateTime } from 'luxon';
import DatePicker from "react-datepicker";
import { ChangeEvent, useEffect, useState } from 'react';
import { Form, FormFieldRow, Input } from '../../../common/components/form';
import Button from '../../../common/components/button';
import Card from '../../../common/components/card';
import { setNotification } from '../../../common/redux/ui/notification/notification-action-creators';
import { setLoader } from '../../../common/redux/ui/loader/loader-action-creators';
import { CSV_GENERATOR, PDF_GENERATOR, UI } from '../../../common/redux/types/feature';
import { getShopSettings } from '../../../shop-settings/redux/shop-settings-selectors';
import Select, { ActionMeta, MultiValue } from 'react-select';
import makeAnimated from 'react-select/animated';
import { getCatalogVariantOptions } from '../../../catalog/redux/catalog-selectors';
import { VariantOption } from '../../../catalog/types/variant';
import TooltipWrap from 'common/components/tooltip-wrap';
import { Operator, Params, ReportType, adminGetOrderReport, withParams } from 'common/utils/make-api-request';

export default function OrdersCsvGenerator({...restProps}) {
  const shopTheme = useTheme();
  const settings = useSelector(getShopSettings);
  const dispatch = useDispatch();
  const [dateRange, setDateRange] = useState<Array<Date|null>>([new Date(), new Date()]);
  const [from, to] = dateRange;
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [itemVariantIds, setItemVariantIds] = useState<Array<number>>([]);

  // multiselector
  const animatedComponents = makeAnimated();
  const shopThemeOptions = {neutral20: 'black', neutral30: 'black', primary25: shopTheme.palette.accent.color+'50', primary: 'black'}
  const itemOptions = useSelector(getCatalogVariantOptions)
  const onMultiSelect = (newValue: MultiValue<VariantOption>, actionMeta: ActionMeta<VariantOption>) => {
    let newList: Array<number> = []
    newValue.forEach((variant) => {
      newList.push(variant.value)
    });
    setItemVariantIds(newList)
  }

  useEffect(() => {
    // set from to one month ago
    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
    setDateRange([oneMonthAgo, new Date()]);
  }, []);

  const getParams = () : Params => {
      let params = withParams()
      if (firstName !== '') {
        params.Search('users.first_name', Operator.like, firstName)
      }
      if (lastName !== '') {
        params.Search('users.last_name', Operator.like, lastName)
      }
      if (email !== '') {
        params.Search('users.email', Operator.like, email)
      }
      if (from !== null) {
        params.Search('orders.created_at', Operator.greaterThan, sqlFromDate(from))
      }
      if (to !== null) {
        params.Search('orders.created_at', Operator.lessThan, sqlToDate(to))
      }
      return params
    }

  const sqlFromDate = (date : Date) : string => {
    return `${DateTime.fromObject({year: date.getFullYear(), month: date.getMonth()+1, day: date.getDate()}, {zone: settings.reportingTimezone}).startOf("day").toUTC().toString()}`
  }

  const sqlToDate = (date : Date) : string => {
    return `${DateTime.fromObject({year: date.getFullYear(), month: date.getMonth()+1, day: date.getDate()}, {zone: settings.reportingTimezone}).endOf("day").toUTC().toString()}`
  }

  const onCreateCsv = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    dispatch(setLoader(true, CSV_GENERATOR))
    adminGetOrderReport(ReportType.csv, null, getParams())
      .then( externalFile => {
        let newWindow = window.open();
        if (newWindow != null){
          newWindow.location = externalFile.Location;
        }
        dispatch(setLoader(false, CSV_GENERATOR))
    }).catch(err => {
      dispatch(setLoader(false, CSV_GENERATOR))
      dispatch(setNotification('failure', "We had some trouble: " + err.message, UI))
    })
  };

  const onCreatePdf = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    dispatch(setLoader(true, PDF_GENERATOR))
    let payload = null
    if (itemVariantIds.length > 0) {
      payload = {
        itemVariantIds: itemVariantIds
      }
    }
    adminGetOrderReport(ReportType.pdf, payload, getParams())
    .then( externalFile => {
      let newWindow = window.open();
      if (newWindow != null){
        newWindow.location = externalFile.Location;
      }
      dispatch(setLoader(false, PDF_GENERATOR))
    }).catch(err => {
      dispatch(setLoader(false, PDF_GENERATOR))
      dispatch(setNotification('failure', "We had some trouble: " + err.message, UI))
    })
  };

  return (
    <div {...restProps}>
      <Card toggleable={false} css={{
        boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.5)",
        borderRadius: 10,
      }}>
        <TooltipWrap text="Generate CSV and PDF Order reports">
          <h3 css={{color: shopTheme.palette.accent.color}}>Order Report Generator</h3>
        </TooltipWrap>
          <Form>
            <FormFieldRow inputId="from" label="Time Range">
              <DatePicker
                css={{width: "100%", border: "1px #444444 solid", padding: "8px" }}
                selectsRange
                startDate={from}
                endDate={to}
                maxDate={new Date()}
                isClearable
                showYearDropdown
                showMonthDropdown
                dropdownMode='select'
                monthsShown={2}
                placeholderText="Click to select a date range"
                required
                onChange={(update) => {
                  setDateRange(update);
                }}
              />
            </FormFieldRow>
            <FormFieldRow css={{width: "100%"}} inputId="firstName" label="First Name">
              <Input type="text" id="firstName" value={firstName} onChange={(e: ChangeEvent<HTMLInputElement>) => setFirstName(e.target.value)} />
            </FormFieldRow>
            <FormFieldRow inputId="lastName" label="Last Name">
              <Input type="text" id="lastName" value={lastName} onChange={(e: ChangeEvent<HTMLInputElement>) => setLastName(e.target.value)} />
            </FormFieldRow>
            <FormFieldRow inputId="email" label="Email">
              <Input type="email" id="email" value={email} onChange={(e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)} />
            </FormFieldRow>
            <FormFieldRow inputId='item-selector' label="Catalog Items">
                <Select theme={(theme) => ({...theme, borderRadius: 0, colors: {...theme.colors, ...shopThemeOptions}})} components={animatedComponents} isMulti options={itemOptions} onChange={onMultiSelect} />
            </FormFieldRow>
          <div css={{display: "flex", justifyContent: "space-around"}}>
            {
              itemVariantIds.length > 0 ?
                <TooltipWrap text='Selecting items will not filter the CSV report'>
                  <Button size={'sm'} onClick={onCreateCsv}>
                    Generate CSV
                  </Button>
                </TooltipWrap>
              :
                <Button size={'sm'} onClick={onCreateCsv}>
                  Generate CSV
                </Button>
            }
            <Button size={'sm'} onClick={onCreatePdf}>
              Generate PDF
            </Button>
          </div>
          </Form>
      </Card>
    </div>
  );
}
