/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useTheme } from '@emotion/react';

export const Form = ({ children, ...restProps }: React.HTMLProps<HTMLFormElement>) => {
  const theme = useTheme();
  return (
    <form css={{ marginLeft: -theme.spacing(1), marginRight: -theme.spacing(1) }} {...restProps}>
      {children}
    </form>
  );
};

export const FormRow = ({
  children,
  ...restProps
}: {
  children: React.ReactNode
}) => (
  <div css={{ display: 'flex', flexWrap: 'wrap' }}{...restProps}>{children}</div>
);

export const FormField = ({
  label,
  inputId,
  children,
  ...restProps
}: {
  label: string;
  inputId: string;
  children: React.ReactNode;
}) => {
  const theme = useTheme();
  return (
    <div
      css={{
        padding: theme.spacing(1),
        marginBottom: 0,
        width: '100%',
        [theme.breakpoint('md')]: { width: '33.33%' },
        [theme.breakpoint('lg')]: { width: '25%' },
      }}
      {...restProps}
    >
      <label css={{ display: 'block', fontSize: '.875em', lineHeight: '1.25em' }} htmlFor={inputId}>
        <strong>{label}</strong>
      </label>
      {children}
    </div>
  );
};

export const FormFieldRow = ({
  label,
  inputId,
  children,
  ...restProps
}: {
  label: string;
  inputId: string;
  children: React.ReactNode;
}) => {
  const theme = useTheme();
  return (
    <div
      css={{
        padding: theme.spacing(1),
        marginBottom: 0,
        width: '100%',
      }}
      {...restProps}
    >
      <label css={{ display: 'block', fontSize: '.875em', lineHeight: '1.25em' }} htmlFor={inputId}>
        <strong>{label}</strong>
      </label>
      {children}
    </div>
  );
};

export const Input = ({ ...restProps }: React.HTMLProps<HTMLInputElement>) => {
  const theme = useTheme();
  return (
    <input
      css={{
        border: `1px ${theme.palette.common.darkGrey} solid`,
        display: 'block',
        fontSize: '.85em',
        '&:focus,&:active': {
          outline: 'none',
        },
        width: '100%',
        padding: theme.spacing(1),
        color: theme.palette.common.darkGrey,
      }}
      {...restProps}
    />
  );
};

export const InputWithChildren = ({ children, ...restProps }: React.HTMLProps<HTMLInputElement>) => {
  const theme = useTheme();
  return (
    <div style={{
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      border: `1px ${theme.palette.common.darkGrey} solid`,
      padding: theme.spacing(.8),
      width: '100%',
      }}
      >
    <input
      css={{
        border: 'none',
        display: 'block',
        fontSize: '.85em',
        '&:focus,&:active': {
          outline: 'none',
        },
        width: '100%',
        color: theme.palette.common.darkGrey,
      }}
      {...restProps}
    />
    {children}
    </div>
  );
};

export const Textarea = ({ ...restProps }: React.HTMLProps<HTMLTextAreaElement>) => {
  const theme = useTheme();
  return (
    <textarea
      css={{
        resize: 'vertical',
        border: `1px ${theme.palette.common.darkGrey} solid`,
        display: 'block',
        fontSize: '.85em',
        '&:focus,&:active': {
          outline: 'none',
        },
        fontFamily: theme.typography.fontFamily,
        width: '100%',
        padding: theme.spacing(1),
        color: theme.palette.common.darkGrey,
      }}
      {...restProps}
    ></textarea>
  );
};
