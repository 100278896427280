/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { withTheme, Theme } from '@emotion/react';
import { Link } from 'react-router-dom';

import { P } from '../../../common/components/typography';

interface UnavailableMessageProps {
  theme: Theme;
}

const UnavailableMessage = withTheme(({ theme }: UnavailableMessageProps) => (
  <P css={{ textTransform: 'uppercase', color: theme.palette.accent.color }}>
    Sorry, this item is unavailable at the moment. Please, select another item from{' '}
    <Link css={{ color: theme.palette.accent.color }} to="/">
      the catalog
    </Link>
    .
  </P>
));

export default UnavailableMessage;
