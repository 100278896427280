/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useSelector } from 'react-redux';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';

import AdminUserPage from '../users/pages/admin-user-page';
import AdminUsersPage from '../users/pages/admin-users-page';
import AdminSettingsPage from '../settings/pages/admin-settings-page';
import AdminInventoryPage from '../inventory/pages/admin-inventory-page';
import { getUser } from '../../user/redux/user-selectors';
import { USER_ROLE_ADMIN } from '../../user/types/user-role';
import AdminToolsPage from '../tools/pages/admin-tools-page';


export default function AdminPage() {
  const { path } = useRouteMatch();
  const user = useSelector(getUser);

  return (
    <Switch>
      {user.role === USER_ROLE_ADMIN && (
        <Switch>
          <Route path={`${path}/users/:userId`} component={AdminUserPage} />
          <Route path={`${path}/users`} component={AdminUsersPage} />
          <Route path={`${path}/settings`} component={AdminSettingsPage} />
          <Route path={`${path}/inventory`} component={AdminInventoryPage} />
          <Route path={`${path}/tools`} component={AdminToolsPage} />
        </Switch>
      )}
      <Redirect to="/" />
    </Switch>
  );
}
