import { getType, isActionOf } from 'typesafe-actions';

import { UserAction, setUser } from './user-action-creators';
import { UserState } from '../../common/redux/types/state';
import { USER_ROLE_USER } from '../types/user-role';

const initialState: UserState = {
  data: {
    id: '-1',
    firstName: '',
    lastName: '',
    userName: '',
    email: '',
    imageUrl: '',
    role: USER_ROLE_USER,
    totalPointsEarned: 0,
    totalPointsRedeemed: 0,
  },
  meta: {
    fetched: false,
  },
};

function userReducer(state: UserState = initialState, action: UserAction): UserState {
  switch (action.type) {
    case getType(setUser):
      if (isActionOf(setUser, action)) {
        return {
          ...state,
          data: action.payload,
          meta: { fetched: true },
        };
      }
      return state;
    default:
      return state;
  }
}

export default userReducer;
