/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { withTheme, Theme } from '@emotion/react';

import Button from '../../../common/components/button';

interface ClaimButtonProps {
  theme: Theme;
  onClick: () => void;
  disabled: boolean;
}

const ClaimButton = withTheme(({ theme, onClick, disabled = false }: ClaimButtonProps) => (
  <Button
    size="lg"
    css={{
      [theme.breakpoint('md')]: {
        alignSelf: 'flex-start',
      },
    }}
    onClick={onClick}
    disabled={disabled}
  >
    Claim it
  </Button>
));

export default ClaimButton;
