/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { withTheme, Theme } from '@emotion/react';


type BadgeProps = React.ComponentProps<'span'> & {
  theme: Theme;
};

function Badge(props: BadgeProps) {
  return (
    <span
      css={{
        paddingTop: props.theme.spacing(0.25),
        paddingBottom: props.theme.spacing(0.25),
        paddingLeft: props.theme.spacing(1.5),
        paddingRight: props.theme.spacing(1.5),
        display: 'inline-block',
        color: props.theme.palette.body.color,
        border: `3px solid ${props.theme.palette.body.background}`,
        borderRadius: props.theme.spacing(1),
        [props.theme.breakpoint('md')]: {
          fontSize: '1.5em',
          paddingTop: props.theme.spacing(0.5),
          paddingBottom: props.theme.spacing(0.5),
          paddingLeft: props.theme.spacing(3),
          paddingRight: props.theme.spacing(3),
        },
      }}
      {...props}
    >
      {props.children}
    </span>
  );
}

export default withTheme(Badge);
