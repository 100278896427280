import { createAction, ActionType } from 'typesafe-actions';

import { CATALOG } from '../../common/redux/types/feature';
import { FETCH, SET } from '../../common/redux/constants';
import { CatalogById } from '../../common/redux/types/state';

export const fetchCatalog = createAction(`${CATALOG} ${FETCH}`, action => () => action());

export const setCatalog = createAction(`${CATALOG} ${SET}`, action => (catalogById: CatalogById) =>
  action(catalogById)
);

export type CatalogAction = ActionType<typeof fetchCatalog | typeof setCatalog>;
