/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useTheme } from '@emotion/react';
import { useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import OrderConfirm from './order-confirm';
import Button from '../../common/components/button';
import { getOrderFromCart } from '../redux/order-selectors';
import { createOrder } from '../redux/order-action-creators';

interface OrderButtonProps {
  onAfterOrder: () => void;
}

export default function OrderButton({ onAfterOrder }: OrderButtonProps) {
  const dispatch = useDispatch();
  const order = useSelector(getOrderFromCart);
  const theme = useTheme();
  const [showConfirm, setShowConfirm] = useState(false);

  const onConfirm = useCallback(() => {
    if (order) {
      dispatch(createOrder(order));
      onAfterOrder();
    }
  }, [onAfterOrder, dispatch, order]);

  const onCancel = useCallback(() => {
    setShowConfirm(false);
  }, []);

  const onClick = useCallback(() => {
    setShowConfirm(true);
  }, []);

  const disabled = !order;

  return (
    <div css={{ position: 'relative' }}>
      <Button
        size="md"
        onClick={onClick}
        disabled={disabled}
        css={{
          marginRight: theme.spacing(2),
          marginBottom: theme.spacing(1),
        }}
      >
        Order
      </Button>
      {showConfirm && <OrderConfirm onConfirm={onConfirm} onCancel={onCancel} />}
    </div>
  );
}
