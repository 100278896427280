import { createSelector } from 'reselect';

import Order, { OrderItems, OrderShipping, OrderShippingLocation } from '../types/order';
import { CartItemsById } from '../../cart/types/cart';
import { getCartItemsById, getCartShipping, getCartShippingLocation } from '../../cart/redux/cart-selectors';
import { parseCartItemId } from '../../cart/cart-utils';

export const getOrderFromCart = createSelector(
  getCartItemsById,
  getCartShipping,
  getCartShippingLocation,
  (cartItemsById: CartItemsById, shipping?: OrderShipping, shippingLocation?: OrderShippingLocation): Order | undefined => {
    const items: OrderItems = Object.keys(cartItemsById).map((cartItemId: string) => {
      const [id, variantId] = parseCartItemId(cartItemId);
      return {
        id,
        variantId,
        quantity: cartItemsById[cartItemId],
      };
    });
    /** Ensure only a valid order is created */
    if (shippingLocation &&!!items.length) {
      if (shippingLocation.location_id !== "hookAddress") {
        return { items, shipping_location: shippingLocation };
      }       
    }   
    if (shipping &&!!items.length) {
      return { items, shipping_location: shipping };       
    }   

    return undefined;           
  }
);
