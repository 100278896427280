/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Theme, useTheme } from '@emotion/react';


type ButtonSize = 'sm' | 'md' | 'lg';
type ButtonVariant = 'primary' | 'secondary';

const makeButtonDimensions = ({
  size,
  rounded,
  theme,
}: {
  size: ButtonSize;
  rounded: boolean;
  theme: Theme;
}) => {
  const dimensions = {
    sm: {
      fontSize: '.8em',
      height: theme.spacing(3.5),
      padding: rounded ? 0 : `0 ${theme.spacing(1.25)}px 0`,
    },
    md: {
      fontSize: '1em',
      height: theme.spacing(6),
      padding: rounded ? 0 : `0 ${theme.spacing(2.5)}px 0`,
    },
    lg: {
      fontSize: '1.25em',
      height: theme.spacing(8),
      padding: rounded ? 0 : `0 ${theme.spacing(7.5)}px 0`,
    },
  };

  return dimensions[size];
};

const makeButtonColors = ({
  outline,
  variant,
  theme,
}: {
  outline: boolean;
  variant: ButtonVariant;
  theme: Theme;
}) => {
  switch (variant) {
    case 'secondary':
      return {
        borderColor: theme.palette.card.color,
        color: outline ? theme.palette.card.color : theme.palette.card.background,
        backgroundColor: outline ? theme.palette.card.background : theme.palette.card.color,
      };
    default:
      return {
        borderColor: theme.palette.accent.color,
        color: outline ? theme.palette.accent.color : theme.palette.accent.contrastColor,
        backgroundColor: outline ? theme.palette.accent.contrastColor : theme.palette.accent.color,
      };
  }
};

type ButtonProps = React.ComponentProps<'button'> & {
  size: ButtonSize;
  outline?: boolean;
  rounded?: boolean;
  variant?: ButtonVariant;
  margin?: string;
};

export default function Button({
  size,
  children,
  margin,
  rounded = false,
  outline = false,
  disabled = false,
  variant = 'primary',
  ...restProps
}: ButtonProps) {
  const theme = useTheme();
  const { height, padding, fontSize } = makeButtonDimensions({ size, rounded, theme });
  const { color, borderColor, backgroundColor } = makeButtonColors({ outline, variant, theme });
  return (
    <button
      css={{
        cursor: disabled ? 'not-allowed' : 'pointer',
        textTransform: 'uppercase',
        '&:focus': {
          outline: 'none',
        },
        '&:hover': {
          boxShadow: '0 0 0 1px rgba(0, 0, 0, 0.5)',
        },
        height,
        padding,
        fontSize,
        color,
        borderColor,
        backgroundColor,
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: rounded ? height : height / 6,
        opacity: disabled ? 0.5 : 1,
        width: rounded ? height : 'auto',
        margin: margin || 'none',
      }}
      disabled={disabled}
      {...restProps}
    >
      {children}
    </button>
  );
}
