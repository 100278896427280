import { getType, isActionOf } from 'typesafe-actions';

import { CatalogItemAction, createCatalogItem, updateCatalogItem, deleteCatalogItem } from './catalog-item-action-creators';
import { CatalogItemState } from '../../common/redux/types/state';
import catalogItemDefaults from './catalog-item-defaults';

const initialState = {
  data: catalogItemDefaults,
  meta: {
    fetched: false,
  },
};

function catalogItemReducer(
  state: CatalogItemState = initialState,
  action: CatalogItemAction
): CatalogItemState {
  switch (action.type) {
    case getType(updateCatalogItem):
      if (isActionOf(updateCatalogItem, action)) {
        return {
          data: action.payload,
          meta: { fetched: true },
        };
      }
      return state;
    case getType(createCatalogItem):
      if (isActionOf(createCatalogItem, action)) {
        return {
          data: action.payload,
          meta: { fetched: true },
        };
      }
      return state;
    case getType(deleteCatalogItem):
      if (isActionOf(deleteCatalogItem, action)) {
        return {
          data: action.payload,
          meta: { fetched: true },
        };
      }
      return state;
    default:
      return state;
  }
}

export default catalogItemReducer;
