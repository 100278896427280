/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withTheme, Theme } from '@emotion/react';
import { RouteComponentProps } from 'react-router';

import Button from '../../common/components/button';
import LargeModal from '../../common/components/large-modal';
import { Heading, P } from '../../common/components/typography';
import { getContactEmail } from '../../shop-settings/redux/shop-settings-selectors';
import { ReactComponent as ChevronLeftIcon } from '@fortawesome/fontawesome-pro/svgs/solid/chevron-left.svg';
import { getShopSettings } from '../../shop-settings/redux/shop-settings-selectors';
import { setOrderPlaced } from '../redux/cart-action-creators';

type OrderPlacedMessageProps = RouteComponentProps & {
  theme: Theme;
};

function OrderPlacedMessage({ theme, history, location }: OrderPlacedMessageProps) {
  const contactEmail = useSelector(getContactEmail);
  const goToStore = useCallback(() => {
    history.push('/');
  }, [history]);

  const dispatch = useDispatch();
  //Since we showed this message, clear this value
  dispatch(setOrderPlaced(false))


  const settings = useSelector(getShopSettings);

  return location && location.search.includes('cart=order-placed') ? (
    <LargeModal isOpen onClose={goToStore} ariaLabel="Order Placed">
      <div
        css={{
          [theme.breakpoint('md')]: {
            minHeight: theme.spacing(54),
          },
          display: 'flex',
          textAlign: 'center',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          padding: theme.spacing(4),
        }}
      >
        <Heading as="h3">Your order has been placed</Heading>
        <P>
          {settings.orderMessage}{' '}
          <a href={`mailto:${contactEmail}`} css={{ color: theme.palette.accent.color }}>
          {settings.orderEmailLabel}
          </a>
        </P>
        <Button size="md" css={{ marginTop: theme.spacing(4) }} outline onClick={goToStore}>
          <ChevronLeftIcon
            width={theme.spacing(1)}
            height={theme.spacing(2)}
            fill={theme.palette.accent.color}
            css={{ verticalAlign: 'middle', marginTop: -2 }}
          />{' '}
          back to store
        </Button>
      </div>
    </LargeModal>
  ) : null;
}

export default withTheme(OrderPlacedMessage);
