import { Dispatch } from 'redux';
import { getType, isActionOf } from 'typesafe-actions';

import {
  fetchUserParticipationHistory,
  setUserParticipationHistory,
  UserParticipationHistoryAction,
} from './user-participation-history-action-creators';
import { UserParticipationHistory } from '../types/user-participation-history';
import { USER_PARTICIPATION_HISTORY } from '../../common/redux/types/feature';
import { Api } from '../../common/redux/make-api';
import { setLoader } from '../../common/redux/ui/loader/loader-action-creators';
import { setNotification } from '../../common/redux/ui/notification/notification-action-creators';

const makeUserParticipationHistoryRouteMiddleware = (
  userParticipationHistoryApi: Api<UserParticipationHistory>
) => () => (next: Dispatch) => (action: UserParticipationHistoryAction) => {
  next(action);

  switch (action.type) {
    case getType(fetchUserParticipationHistory):
      next(setLoader(true, USER_PARTICIPATION_HISTORY));
      next(userParticipationHistoryApi.actions.fetch());
      break;
    case getType(userParticipationHistoryApi.actions.fetchSuccess):
      next(setLoader(false, USER_PARTICIPATION_HISTORY));
      if (isActionOf(userParticipationHistoryApi.actions.fetchSuccess, action)) {
        if (action.payload.data) {
          next(setUserParticipationHistory(action.payload.data));
        }
      }
      break;
    case getType(userParticipationHistoryApi.actions.createFailure):
      next(setLoader(false, USER_PARTICIPATION_HISTORY));
      next(
        setNotification(
          'failure',
          'Could not fetch user participation history',
          USER_PARTICIPATION_HISTORY
        )
      );
      break;
  }
};

export default makeUserParticipationHistoryRouteMiddleware;
