/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useTheme } from '@emotion/react';
import { useSelector } from 'react-redux';

import AddressForm from './address-form';
import LocationSelect from './location-select';
import LocationAndAddress from './location-and-address';
import { Heading, P } from '../../common/components/typography';
import { getShippingOption, getShopSettings } from '../../shop-settings/redux/shop-settings-selectors';
import { getItemsInCartOutOfStock } from '../redux/cart-selectors';

export default function CartShipping() {
  const theme = useTheme();
  const shippingOption = useSelector(getShippingOption);
  const itemsInCartOutOfStock = useSelector(getItemsInCartOutOfStock);
  const settings = useSelector(getShopSettings);

  return (
    <div>
      <Heading as="h3" css={{ lineHeight: 1.25 }}>
        Enter your delivery address:
      </Heading>
      <P css={{ lineHeight: 1.25, marginTop: theme.spacing(1) }}>
        { itemsInCartOutOfStock <= 0
          ? settings.deadlineToReceiveMessage //`Please allow 7-10 business days to receive your order.`
          : settings.outOfStockMessage /*`Please allow 2 weeks to receive your order as this item is currently out of stock.` */ }
      </P>
      <div css={{ marginTop: theme.spacing(1) }}>
        {shippingOption === 'location' && <LocationSelect />}
        {shippingOption === 'address' && <AddressForm />}
        {shippingOption === 'address and location' && <LocationAndAddress />}
      </div>
    </div>
  );
}
