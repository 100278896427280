/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useDispatch } from 'react-redux';
import { ChangeEvent, useState, FormEvent, useEffect } from 'react';
import { useTheme } from '@emotion/react';

import { Form, FormRow, FormField, Input, FormFieldRow, Textarea } from '../../../common/components/form';
import Button from '../../../common/components/button';
import { createCatalogItem } from '../../../catalog-item/redux/catalog-item-action-creators';
import { NewCatalogItem } from '../../../catalog/types/catalog-item';
import { NewVariant, NewVariants } from '../../../catalog/types/variant';

export default function AddInventoryItem() {
  const dispatch = useDispatch();
  const theme = useTheme();


  const newItemVariantDefaults: NewVariant = {
    size: "",
    color: "",
    imageUrl: "",
    availableQuantity: 0,
    visible: true,
    buyerItemNumber: "",
    unitOfMeasure: ""
  };

  const [newItemVariant, setNewItemVariant] = useState<NewVariant>(newItemVariantDefaults)

  const newItemDefaults: NewCatalogItem = {
    title: "",
    points: 0,
    description: "",
    buyerItemNumber: "",
    unitOfMeasure: "",
    sizeChart: "",
    availableQuantity: 0,
    visible: true,
    variants: []
  };

  const [newItem, setNewItem] = useState<NewCatalogItem>(newItemDefaults)

  const updateNewItem = (key: string, value: string | number | boolean | NewVariants) => {
    setNewItem(prevNewItem => {
      return key in prevNewItem ? { ...prevNewItem, [key]: value } : prevNewItem;
    });
  }

  const isNumber = (x: any) => {
    const parsed = parseInt(x);
    if (isNaN(parsed)) return false;
    return parsed;
  }

  useEffect(() => {
    setNewItem(prevNewItem => {
      return { ...prevNewItem, variants: [newItemVariant] };
    });
  }, [newItemVariant])

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    let { id, value } = e.target;
    if (id !== "points") {
      updateNewItem(id, value);
      return;
    }
    if (value !== '-' && value !== '0') {
      if (!isNumber(value)) {
        value = '';
        return;
      }
    }
    updateNewItem(id, parseInt(value));
  }

  const onVariantInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    let { id, value } = e.target;
    if (id === "availableQuantity") {
      if (!isNumber(value)) {
        value = '';
        return;
      }
      setNewItemVariant({ ...newItemVariant, [id]: parseInt(value) });
      return
    }
    setNewItemVariant({ ...newItemVariant, [id]: value });
  }


  const onTextAreaChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
      const { id, value } = e.target;
      updateNewItem(id, value);
  }

  const onSubmit = (e: FormEvent) => {
      e.preventDefault();
      // add newItemVariant to newItem
      dispatch(createCatalogItem(newItem));
      setNewItem(newItemDefaults);
      setNewItemVariant(newItemVariantDefaults);

  }

  const onCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
      const { id, checked } = e.target;
      if (checked) updateNewItem(id, true);
      else updateNewItem(id, false);
  }

  return (
    <div data-testid="add-inventory-item" css={{padding: theme.spacing(2)}}>
      <Form onSubmit={onSubmit} css={{ border: theme.palette.card.color, padding: theme.spacing(1)}}>
        <h3>New Item</h3>
        <FormRow>
          <FormField inputId="title" label="Title" >
            <Input id="title" required value={newItem.title} onChange={onInputChange} css={{width:"100%"}} />
          </FormField>
        </FormRow>
        <FormRow>
          <FormFieldRow inputId="description" label="Description">
            <Textarea id="description" value={newItem.description} onChange={onTextAreaChange}/>
          </FormFieldRow>
        </FormRow>
        <FormRow>
          <FormField inputId="buyerItemNumber" label="Buyer Number">
            <Input id="buyerItemNumber" value={newItem.buyerItemNumber} onChange={onInputChange} />
          </FormField>
          <FormField inputId="unitOfMeasure" label="Unit Of Measure">
            <Input id="unitOfMeasure" value={newItem.unitOfMeasure} onChange={onInputChange} />
          </FormField>
          <FormField inputId="size" label="Size">
            <Input id="size" value={newItemVariant.size} onChange={onVariantInputChange} />
          </FormField>
          <FormField inputId="color" label="Color">
            <Input id="color" value={newItemVariant.color} onChange={onVariantInputChange} />
          </FormField>
        </FormRow>
        <FormRow>
          <FormField inputId="points" label="Points">
            <Input id="points" required value={newItem.points} onChange={onInputChange} css={{width:"100px"}} />
          </FormField>
          <FormField inputId="availableQuantity" label="Quantity">
            <Input id="availableQuantity" value={newItemVariant.availableQuantity} onChange={onVariantInputChange} css={{width:"100px"}} />
          </FormField>
          <FormField inputId="visible" label="Visible">
            <Input type="checkbox" id="visible" checked={newItem.visible} onChange={onCheckboxChange} css={{width:"40px"}} />
          </FormField>
        </FormRow>

        <Button css={{ margin: 8, height: 36 }} type="submit" size="sm">
          Save
        </Button>
      </Form>
    </div>
    );
}
