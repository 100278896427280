/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useTheme } from '@emotion/react';

import Card from '../../../common/components/card';
import AdminPageContainer from '../../components/admin-page-container';
import EditableShippingLocations from '../components/editable-shipping-locations';
import EditableEvents from '../components/editable-events';
import GeneralSettings from '../components/general-settings';
import DesignSettings from '../components/design-settings';

export default function AdminSettingsPage() {
  const theme = useTheme();
  return (
    <div>
    <AdminPageContainer title="Shop Settings">
      <Card css={{ borderRadius: '10px'}} title="General" toggleable>
        <GeneralSettings />
      </Card>
      <Card css={{  borderRadius: '10px', marginTop: theme.spacing(2) }} title="Design" toggleable closed>
        <DesignSettings />
      </Card>
      <Card css={{  borderRadius: '10px', marginTop: theme.spacing(2) }} title="Shipping Locations" toggleable closed>
        <EditableShippingLocations />
      </Card>
      <Card css={{  borderRadius: '10px', marginTop: theme.spacing(2) }} title="Events" toggleable closed>
        <EditableEvents />
      </Card>
      </AdminPageContainer>
    </div>
  );
}
