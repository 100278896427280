/** @jsxRuntime classic */
/** @jsx jsx */
import { useDispatch } from 'react-redux';
import { jsx } from '@emotion/core';
import { useTheme } from '@emotion/react';
import { Form } from '../../../common/components/form';
import Button from '../../../common/components/button';
import Card from '../../../common/components/card';
import { setNotification } from '../../../common/redux/ui/notification/notification-action-creators';
import { setLoader } from '../../../common/redux/ui/loader/loader-action-creators';
import { CSV_GENERATOR, PDF_GENERATOR, UI } from '../../../common/redux/types/feature';
import TooltipWrap from 'common/components/tooltip-wrap';
import { ReportType, adminGetInventoryReport } from 'common/utils/make-api-request';

export default function InventoryReportGenerator({...restProps}) {
  const shopTheme = useTheme();
  const dispatch = useDispatch();

  const onCreateCsv = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    dispatch(setLoader(true, CSV_GENERATOR))
    // construct query string
    adminGetInventoryReport(ReportType.csv)
    .then( redirect => {
      let newWindow = window.open();
      if (newWindow != null){
        newWindow.location = redirect.Location;
      }
      dispatch(setLoader(false, CSV_GENERATOR))
    }).catch(err => {
      //notification
      dispatch(setLoader(false, CSV_GENERATOR))
      dispatch(setNotification('failure', "We had some trouble: " + err.message, UI))
    })
  };


  const onCreatePdf = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    dispatch(setLoader(true, PDF_GENERATOR))
    // construct query string
    adminGetInventoryReport(ReportType.pdf)
    .then( redirect => {
      let newWindow = window.open();
      if (newWindow != null){
        newWindow.location = redirect.Location;
      }
      dispatch(setLoader(false, PDF_GENERATOR))
    }).catch(err => {
      //notification
      dispatch(setLoader(false, PDF_GENERATOR))
      dispatch(setNotification('failure', "We had some trouble: " + err.message, UI))
    })
  };

  return (
    <div {...restProps}>
      <Card toggleable={false} css={{
        boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.5)",
        borderRadius: 10,
      }}>
        <TooltipWrap text="Generate reports of inventory">
          <h3 css={{color: shopTheme.palette.accent.color}}>Inventory Report Generator</h3>
        </TooltipWrap>
          <Form>
          <div css={{display: "flex", justifyContent: "space-around", padding: '8px'}}>
            <Button size={'sm'} onClick={onCreateCsv}>
              Generate CSV
            </Button>
            <Button size={'sm'} onClick={onCreatePdf}>
              Generate PDF
            </Button>
          </div>
          </Form>
      </Card>
    </div>
  );
}
