import { getType } from 'typesafe-actions';
import { UserInteractionState } from '../types/state';
import { UserInteractionAction, setAdminUserSearchState } from './interaction-action-creator';


const initialState = {
  adminUserSearch: {
    term: "",
    page: 0,
  }
};

export default function interactionReducer(
  state: UserInteractionState = initialState,
  action: UserInteractionAction
): UserInteractionState {
  switch (action.type) {
    case getType(setAdminUserSearchState):
      return {
        ...state,
        adminUserSearch: action.payload
      }
    default:
      return state;
  }
}
