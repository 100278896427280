import { createAction, ActionType } from 'typesafe-actions';

import { CATALOG_ITEM } from '../../common/redux/types/feature';
import { CREATE, UPDATE, DELETE } from '../../common/redux/constants';
import CatalogItem, { NewCatalogItem } from '../../catalog/types/catalog-item';

export const createCatalogItem = createAction(`${CATALOG_ITEM} ${CREATE}`, action => (item: NewCatalogItem) => action(item) );

export const updateCatalogItem = createAction(`${CATALOG_ITEM} ${UPDATE}`, action => (item: CatalogItem) => action(item));

export const deleteCatalogItem = createAction(`${CATALOG_ITEM} ${DELETE}`, action => (item: CatalogItem) => action(item));

export type CatalogItemAction = ActionType<typeof updateCatalogItem | typeof createCatalogItem | typeof deleteCatalogItem >;
