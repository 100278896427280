/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useTheme } from '@emotion/react';

import Button from '../../common/components/button';
import { Table } from '../../common/components/table';
import ListItem from '../../common/types/list-item';
import SingleTextForm from './single-text-form';

interface EditableListProps {
  entityName: string;
  isLoading: boolean;
  onCreate: (title: string) => void;
  onDelete: (itemId: string) => void;
  onUpdate: (itemId: string, title: string) => void;
  items: ListItem[];
}

export default function EditableList({
  onCreate,
  onDelete,
  onUpdate,
  items,
  entityName,
  isLoading,
}: EditableListProps) {
  const theme = useTheme();
  return (
    <div>
      <SingleTextForm
        onSubmit={onCreate}
        buttonLabel="Create"
        placeholder={`Create ${entityName}`}
        isLoading={isLoading}
      />
      <Table css={{ border: 'none' }}>
          {items.map(item => (
            <tr key={item.id}>
              <td>
                <SingleTextForm
                  onSubmit={(v) => {
                    if (window.confirm('Are you sure you want to update this ' + entityName + '?')) {
                      item.description = v;
                      onUpdate(item.id, item.description);
                    }
                  }}
                  value={item.description}
                  buttonLabel="Update"
                  isLoading={isLoading}
                  clearInputOnSubmit={false}
                />
              </td>
              <td css={{ width: 60, textAlign: 'right', }}>

                <Button
                  disabled={isLoading}
                  onClick={() => {
                    if (window.confirm(`Are you sure you want to delete this ${entityName}?`)) {
                      onDelete(item.id);
                    }
                  }}
                  variant="secondary"
                  size="sm"
                  css={{ fontSize: '.85em', height: 36, marginBottom: theme.spacing(2) }}
                  outline
                >
                  Delete
                </Button>
              </td>
            </tr>
          ))}
      </Table>
    </div>
  );
}
