/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import EditableList from '../../components/editable-list';
import { getShopSettings } from '../../../shop-settings/redux/shop-settings-selectors';
import { updateShopSettings } from '../../../shop-settings/redux/shop-settings-action-creators';
import { getShopSettingsLoadingState } from '../../../common/redux/ui/loader/loader-selectors';

export default function EditableShippingLocations() {
  const dispatch = useDispatch();
  const isLoading = useSelector(getShopSettingsLoadingState);

  const shopSettings = useSelector(getShopSettings);

  const onCreateShippingLocation = useCallback(
    (description: string) => {
      const updates = Object.assign({}, shopSettings, {
        locations: [{ id: `tmp-location-id--${Date.now()}`, description }].concat(
          shopSettings.locations || []
        ),
      });
      dispatch(updateShopSettings(updates));
    },
    [dispatch, shopSettings]
  );

  const onDeleteShippingLocation = useCallback(
    (id: string) => {
      if (shopSettings.locations) {
        const updates = Object.assign({}, shopSettings, {
          locations: shopSettings.locations.filter(location => location.id !== id),
        });
        dispatch(updateShopSettings(updates));
      }
    },
    [dispatch, shopSettings]
  );

  const onUpdateShippingLocation = useCallback(
    (id: string, description: string) => {
      if (shopSettings.locations) {
        const updates = Object.assign({}, shopSettings, {
          locations: shopSettings.locations.map(location => {
            if (location.id === id) {
              return { id, description };
            }
            return location;
          }),
        });
        dispatch(updateShopSettings(updates));
      }
    },
    [dispatch, shopSettings]
  );

  return (
    <EditableList
      entityName="Shipping Location"
      isLoading={isLoading}
      onCreate={onCreateShippingLocation}
      onDelete={onDeleteShippingLocation}
      onUpdate={onUpdateShippingLocation}
      items={shopSettings.locations || []}
    />
  );
}
