import { AxiosInstance } from 'axios';

import ApiUrl from '../types/api-url';
import { ApiResponse, ApiConfig } from '../redux/types/common';

export default function makeProdHttpClient(axiosInstance: AxiosInstance) {
  const get = <T>(url: ApiUrl, config?: ApiConfig): Promise<ApiResponse<T>> =>
    axiosInstance.get(url, config);

  const post = <T>(url: ApiUrl, body: {}, config?: ApiConfig): Promise<ApiResponse<T>> =>
    axiosInstance.post(url, body, config);

  const put = <T>(url: ApiUrl, body: {}, config?: ApiConfig): Promise<ApiResponse<T>> =>
    axiosInstance.put(url, body, config);

  const deleteFn = <T>(url: ApiUrl, body: {}, config?: ApiConfig): Promise<ApiResponse<T>> =>
    axiosInstance.delete(url, { ...config, data: body});

  return { get, post, put, delete: deleteFn };
}
