/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useTheme } from '@emotion/react';
import { useSelector } from 'react-redux';

import { getNotifications } from '../../common/redux/ui/notification/notification-selectors';
import { Notification } from '../../common/redux/ui/notification/notification-type';

export default function ShopNotifications() {
  const theme = useTheme();
  const notifications = useSelector(getNotifications);
  return (
    <div
      css={{
        position: 'fixed',
        top: theme.spacing(9),
        right: theme.spacing(1),
        zIndex: theme.zIndex.notification,
      }}
    >
      {notifications.map(({ id, variant, message }: Notification) => (
        <ShopNotification key={id} id="id" variant={variant} message={message} />
      ))}
    </div>
  );
}

function ShopNotification({ variant, message }: Notification) {
  const theme = useTheme();
  if ((typeof message) != "string") {
    message = "you passed a non string message to this notification"
  }
  return (
    <div
      css={{
        marginBottom: theme.spacing(1),
        padding: theme.spacing(0.75),
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5),
        fontSize: '.875em',
        background: variant === 'success' ? theme.palette.common.green : theme.palette.common.red,
        color: 'white',
      }}
    >
      {message}
    </div>
  );
}
