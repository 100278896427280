/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useTheme } from '@emotion/react';

import { P } from '../../../common/components/typography';
import { Link } from 'react-router-dom';

interface CatalogItemCardTitleProps {
  id: string;
  title: string;
}

export default function CatalogItemCardTitle({ id, title }: CatalogItemCardTitleProps) {
  const theme = useTheme();
  return (
    <P
      data-testid="catalog-item-name"
      css={{
        lineHeight: 1,
        textAlign: 'right',
        fontSize: '1.25em',
      }}
    >
      <Link
        title={title}
        to={`/catalog/${id}`}
        css={{ color: theme.palette.accent.color, textDecoration: 'none' }}
      >
        {title}
      </Link>
    </P>
  );
}
