import Axios from 'axios';

import AuthConfig from './types/auth-config';
import AuthUtils from './types/auth-utils';

const makeAuthUtils = (props: Required<AuthConfig>): AuthUtils => {
  const getUrlParam = (urlParam: string) => () => {
    const params = new URLSearchParams(window.location.search);
    return params.get(urlParam) || '';
  };

  const getAuthHeaders = (authToken: string) => ({
    Authorization: `Bearer ${authToken}`,
  });

  const redirectTo = (to: string = '/') => window.history.pushState({}, window.document.title, to);

  const isOnCallbackPathname = (callbackPathname: string) => () =>
    window.location.pathname === callbackPathname;

  const getAuthTokenFromLocalStorage = (authTokenKey: string) => () =>
    window.localStorage.getItem(authTokenKey) || '';

  const addAuthTokenToLocalStorage = (authTokenKey: string) => (authTokenValue: string) =>
    window.localStorage.setItem(authTokenKey, authTokenValue);

  const removeAuthTokenFromLocalStorage = (authTokenKey: string) => () =>
    window.localStorage.removeItem(authTokenKey);

  return {
    redirectTo,
    fetchAuthStatus: () =>
      Axios.get(props.authStatusEndpoint, {
        headers: getAuthHeaders(getAuthTokenFromLocalStorage(props.tokenCacheKey)()),
      }),
    isOnCallbackPathname: isOnCallbackPathname(props.callbackPathname),
    getAuthTokenFromLocalStorage: getAuthTokenFromLocalStorage(props.tokenCacheKey),
    addAuthTokenToLocalStorage: addAuthTokenToLocalStorage(props.tokenCacheKey),
    removeAuthTokenFromLocalStorage: removeAuthTokenFromLocalStorage(props.tokenCacheKey),
    getAuthStatusFromUrl: getUrlParam(props.callbackStatusParam),
    getAuthTokenFromUrl: getUrlParam(props.callbackTokenParam),
    getAuthMessageFromUrl: getUrlParam(props.callbackMessageParam),
  };
};

export default makeAuthUtils;
