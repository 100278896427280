import { createAction, ActionType } from 'typesafe-actions';

import Feature from '../../types/feature';

/**
 * Creates a 'document action' to set loading status
 */
export const setLoader = createAction('SET_LOADER', action =>
  (status: boolean, feature: Feature) =>
    action(status, { feature })
);

export type LoaderAction = ActionType<typeof setLoader>;
