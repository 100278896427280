/** @jsxRuntime classic */
/** @jsx jsx */
// import Modal from 'react-bootstrap/Modal';
import Modal from 'react-modal';
import { jsx } from '@emotion/core';
import { withTheme, Theme } from '@emotion/react';
import {faXmark} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface LargeModalProps {
  isOpen: boolean;
  theme: Theme;
  ariaLabel: string;
  onClose: () => void;
  children: React.ReactNode;
}

function LargeModal({ theme, ariaLabel, onClose, isOpen, children }: LargeModalProps) {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      aria={{ labelledby: ariaLabel }}
      appElement={document.getElementById('root') || undefined }
      className="shop-modal-content"
      css={{
        width: "80%",
        height: "700px",
        marginTop: "2%!important",
        marginLeft: "10%!important",
        '&.shop-modal-content': {
          outline: 'none',
          margin: '0 auto',
          position: 'absolute',
          [theme.breakpointMax('lg')]: {
            overflowY: "scroll",
            bottom: 'auto',
            width: '100%',
            height: '100%',
            margin: "0!important",
          },
          borderWidth: 0,
          borderRadius: "10px",
          padding: theme.spacing(2),
          color: theme.palette.card.color,
          boxShadow: '0 0 20px 0 rgba(0, 0, 0, .55)',
          background: theme.palette.card.background,
          /**
           * Optimize scrolling on mobile
           */
          WebkitOverflowScrolling: 'touch',
        },
      }}
      style={{
        overlay: {
          zIndex: theme.zIndex.modalOverlay,
        },
      }}
    >
      <button
        onClick={onClose}
        css={{
          background: "transparent",
          border: "none",
          borderRadius: "40px",
          position: 'absolute',
          top: theme.spacing(0.5),
          right: theme.spacing(2),
          [theme.breakpoint('md')]: {
            right: theme.spacing(1),
          },
        }}
      >
        <FontAwesomeIcon icon={faXmark}  color={theme.palette.accent.color} css={{'&:hover': {color: theme.palette.common.red}}} size="2x"/>
      </button>
      {children}
    </Modal>
  );
}

export default withTheme(LargeModal);
