import { createAction, ActionType } from 'typesafe-actions';

import { FETCH, SET } from '../../common/redux/constants';
import { USER_PARTICIPATION_HISTORY } from '../../common/redux/types/feature';
import { UserParticipationHistory } from '../types/user-participation-history';

export const fetchUserParticipationHistory = createAction(`${USER_PARTICIPATION_HISTORY} ${FETCH}`);

export const setUserParticipationHistory = createAction(
  `${USER_PARTICIPATION_HISTORY} ${SET}`,
  action => (userParticipationHistory: UserParticipationHistory) => action(userParticipationHistory)
);

export type UserParticipationHistoryAction = ActionType<
  typeof setUserParticipationHistory | typeof fetchUserParticipationHistory
>;
