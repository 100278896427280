import { Dispatch } from 'redux';
import { getType, isActionOf } from 'typesafe-actions';

import { Api } from '../../common/redux/make-api';
import { Variant } from '../../catalog/types/variant';
import { CATALOG_ITEM_VARIANT } from '../../common/redux/types/feature';
import { setLoader } from '../../common/redux/ui/loader/loader-action-creators';
import { CatalogItemVariantAction, updateCatalogItemVariant, createCatalogItemVariant, deleteCatalogItemVariant } from './catalog-item-variant-action-creators';
import { setNotification } from '../../common/redux/ui/notification/notification-action-creators';
import { fetchCatalog } from '../../catalog/redux/catalog-action-creators';
import { fetchInventory } from 'admin/inventory/redux/inventory-action-creators'

const makeCatalogItemVariantRouteMiddleware = (catalogItemVariantApi: Api<Variant>) => () => (next: Dispatch) => (
  action: CatalogItemVariantAction
) => {
  next(action)
  switch (action.type) {
    case getType(createCatalogItemVariant):
      next(setLoader(true, CATALOG_ITEM_VARIANT));
      if (isActionOf(createCatalogItemVariant, action)) {
        next(catalogItemVariantApi.actions.create(action.payload));
      }
      break;
    case getType(catalogItemVariantApi.actions.createSuccess):
      next(setLoader(false, CATALOG_ITEM_VARIANT));
      if (isActionOf(catalogItemVariantApi.actions.createSuccess, action)) {
        next(setNotification('success', 'Variant created successfully', CATALOG_ITEM_VARIANT));
        next(fetchCatalog());
        next(fetchInventory());
      }
      break;
    case getType(catalogItemVariantApi.actions.createFailure):
      next(setLoader(false, CATALOG_ITEM_VARIANT));
      if(isActionOf(catalogItemVariantApi.actions.createFailure, action)) {
        next(setNotification('failure', action.payload, CATALOG_ITEM_VARIANT));
      }
      break;
    case getType(updateCatalogItemVariant):
      next(setLoader(true, CATALOG_ITEM_VARIANT));
      if (isActionOf(updateCatalogItemVariant, action)) {
        next(catalogItemVariantApi.actions.update(action.payload));
      }
      break;
    case getType(catalogItemVariantApi.actions.updateSuccess):
      next(setLoader(false, CATALOG_ITEM_VARIANT));
      if (isActionOf(catalogItemVariantApi.actions.updateSuccess, action)) {
        next(setNotification('success', 'Variant updated successfully', CATALOG_ITEM_VARIANT));
        next(fetchCatalog());
        next(fetchInventory());
      }
      break;
    case getType(catalogItemVariantApi.actions.updateFailure):
      next(setLoader(false, CATALOG_ITEM_VARIANT));
      if(isActionOf(catalogItemVariantApi.actions.updateFailure, action)) {
        next(setNotification('failure', action.payload, CATALOG_ITEM_VARIANT));
      }
      break;
    case getType(deleteCatalogItemVariant):
      next(setLoader(true, CATALOG_ITEM_VARIANT));
      if (isActionOf(deleteCatalogItemVariant, action)) {
        next(catalogItemVariantApi.actions.deleteAction(action.payload));
      }
      break;
    case getType(catalogItemVariantApi.actions.deleteSuccess):
      next(setLoader(false, CATALOG_ITEM_VARIANT));
      if (isActionOf(catalogItemVariantApi.actions.deleteSuccess, action)) {
        next(setNotification('success', 'Item deleted successfully', CATALOG_ITEM_VARIANT));
        next(fetchCatalog());
        next(fetchInventory());
      }
      break;
    case getType(catalogItemVariantApi.actions.deleteFailure):
      next(setLoader(false, CATALOG_ITEM_VARIANT));
      if(isActionOf(catalogItemVariantApi.actions.deleteFailure, action)) {
        next(setNotification('failure', action.payload, CATALOG_ITEM_VARIANT));
      }
      break;
  }
};

export default makeCatalogItemVariantRouteMiddleware;
