/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useEffect, useState } from 'react';
import { useTheme } from '@emotion/react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import Card from '../../../common/components/card';
import AdminPageContainer from '../../components/admin-page-container';
import SingleTextForm from '../../components/single-text-form';
import { Table, TableSection } from '../../../common/components/table';
import User from '../../../user/types/user';

import formatPoints from '../../../common/utils/format-points';
import { setNotification } from '../../../common/redux/ui/notification/notification-action-creators';
import { USERS } from '../../../common/redux/types/feature';
import { Operator, adminUserSearch, withParams } from 'common/utils/make-api-request';
import Paginator from 'admin/tools/components/paginator';
import { getAdminUserSearchInteractionState } from 'common/redux/interaction/interaction-selectors';
import { setAdminUserSearchState } from 'common/redux/interaction/interaction-action-creator';

export default function AdminUsersPage() {
  const dispatch = useDispatch();
  const searchState = useSelector(getAdminUserSearchInteractionState);
  const theme = useTheme();
  const [searchTerm, setSearchTerm] = useState<string>(searchState.term)
  const [users, setUsers] = useState<Array<User>>([]);
  const [page, setPage] = useState<number>(searchState.page);
  const perPage = 100;

  useEffect(() => {
    onSearch()
  }, [page, searchTerm])

  const onSearch = () => {
    if (searchTerm === "") {
      return
    }
    if (searchTerm !== searchState.term) {
      setPage(0)
    }

    let terms = searchTerm.split(" ")
    let op = terms.length > 1 ? Operator.in : Operator.orIn
    let params = withParams()
      .Search('users.first_name', op, terms.join(","))
      .Search('users.last_name', op, terms.join(","))
      .Search('users.email', Operator.orIn, terms.join(","))
      .Search('users.first_name', Operator.orLike, searchTerm)
      .Search('users.last_name', Operator.orLike, searchTerm)
      .Search('users.email', Operator.orLike, searchTerm)
      .Limit(perPage)
      .Offset(page * perPage)

    adminUserSearch(params)
      .then(res => {
        if (res.length === 0) {
          dispatch(setNotification('failure', "No users were found", USERS));
          return
        }
        setUsers(res);
        dispatch(setAdminUserSearchState({term: searchTerm, page: page}))
      })
      .catch(err => {
        dispatch(setNotification('failure', err.message, USERS));
      })
  };
  //the table section for the users looks like a nightmare becuase when you
  //render the body without elements it complains the table has empty whitespace.
  //so we chose not to render the body at all when there are no users
  return (
    <AdminPageContainer title="Manage Users">
      <Card css={{ borderRadius: '10px' }}>
        <SingleTextForm
          onSubmit={(value) => setSearchTerm(value)}
          buttonLabel="Search"
          value={searchState.term}
          clearInputOnSubmit={false}
          placeholder="Find users by first name, last name, or email address"
        />
        <div style={{display: "flex", justifyContent: "flex-end"}}>
          <Paginator
            setPage={setPage}
            currentPage={page}
            pageMaxLength={perPage}
            pageCurrentLength={users.length}
            pageContainsData={users.length > 0}
          />
        </div>
        <Table css={{ borderBottomWidth: 0 }}>
          <TableSection as="thead">
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Points</th>
            </tr>
          </TableSection>
          { users &&
          <TableSection as="tbody">
            {users && users.map((user: User) => (
              <tr key={user.id}>
                <td>
                  <Link css={{ color: theme.palette.accent.color }} to={`/admin/users/${user.id}`}>{user.firstName + ' ' + user.lastName}</Link>
                </td>
                <td>{user.email}</td>
                <td>{formatPoints(user.totalPointsEarned - user.totalPointsRedeemed)}</td>
              </tr>
            ))}
          </TableSection>
          }
          </Table>
      </Card>
    </AdminPageContainer>
  );
}
