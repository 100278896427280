/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import EditableList from '../../components/editable-list';
import { getShopSettings } from '../../../shop-settings/redux/shop-settings-selectors';
import { updateShopSettings } from '../../../shop-settings/redux/shop-settings-action-creators';
import { getShopSettingsLoadingState } from '../../../common/redux/ui/loader/loader-selectors';

export default function EditableEvents() {
  const dispatch = useDispatch();
  const isLoading = useSelector(getShopSettingsLoadingState);
  const shopSettings = useSelector(getShopSettings);

  const onCreateEvent = useCallback(
    (description: string) => {
      const updates = Object.assign({}, shopSettings, {
        pointTitles: [{ id: `tmp-event-id--${Date.now()}`, description }].concat(
          shopSettings.pointTitles || []
        ),
      });
      dispatch(updateShopSettings(updates));
    },
    [dispatch, shopSettings]
  );

  const onDeleteEvent = useCallback(
    (id: string) => {
      if (shopSettings.pointTitles) {
        const updates = Object.assign({}, shopSettings, {
          pointTitles: shopSettings.pointTitles.filter(pointTitle => pointTitle.id !== id),
        });
        dispatch(updateShopSettings(updates));
      }
    },
    [dispatch, shopSettings]
  );

  const onUpdateEvent = useCallback(
    (id: string, description: string) => {
      if (shopSettings.pointTitles) {
        const updates = Object.assign({}, shopSettings, {
          pointTitles: shopSettings.pointTitles.map(pointTitle => {
            if (pointTitle.id === id) {
              return { id, description };
            }
            return pointTitle;
          }),
        });
        dispatch(updateShopSettings(updates));
      }
    },
    [dispatch, shopSettings]
  );

  return (
    <EditableList
      entityName="Event"
      isLoading={isLoading}
      onCreate={onCreateEvent}
      onDelete={onDeleteEvent}
      onUpdate={onUpdateEvent}
      items={shopSettings.pointTitles || []}
    />
  );
}
