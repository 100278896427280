import { UserOrder, UserOrderItem } from "user-order-history/types/user-order-history"


const ordersAsItems = (orders : UserOrder[]) : UserOrderItem[] => {
  var items : UserOrderItem[] = []
  orders.forEach((order: UserOrder) => {
    order.items.forEach((item: UserOrderItem) => {
      item.date = order.created_at
      items.push(item)
    })
  })
  return items
}

export {
  ordersAsItems,
}
