import { createSelector } from 'reselect';

import { SHOP_SETTINGS } from '../../common/redux/types/feature';
import { getLoaderState } from '../../common/redux/ui/ui-selectors';
import State, { ShopSettingsState, LoaderState } from '../../common/redux/types/state';
import ShopSettings from '../types/shop-settings';

const getShopSettingsState = (state: State) => state.shopSettings;

export const getShopSettings = createSelector(
  getShopSettingsState,
  (shopSettingsState: ShopSettingsState) => shopSettingsState.data
);

export const getShopLocations = createSelector(
  getShopSettings,
  (shopSettings: ShopSettings) => shopSettings.locations || []
);

export const getShippingOption = createSelector(
  getShopSettings,
  (shopSettings: ShopSettings) => shopSettings.shippingOption
);

export const getContactEmail = createSelector(
  getShopSettings,
  (shopSettings: ShopSettings) => shopSettings.contactEmail
);

export const getHasFetchedShopSettings = createSelector(
  getShopSettingsState,
  (shopSettingsState: ShopSettingsState) => shopSettingsState.meta.fetched
);

export const getFetchingShopSettings = createSelector(
  getLoaderState,
  (loaderState: LoaderState) => loaderState[SHOP_SETTINGS]
);
