/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useSelector } from 'react-redux';
import { ThemeProvider } from '@emotion/react';
import deepmerge from 'deepmerge';

import { getShopSettings } from '../../shop-settings/redux/shop-settings-selectors';
import shopThemeDefaults from './../shop-theme-defaults';

interface ShopThemeProviderProps {
  children: React.ReactNode;
}

export default function ShopThemeProvider({ children }: ShopThemeProviderProps) {
  const shopSettings = useSelector(getShopSettings);
  const shopTheme = deepmerge(shopThemeDefaults, {
    typography: {
      fontFamily: shopSettings.theme.bodyFontFamily,
    },
    palette: {
      accent: {
        color: shopSettings.theme.accentColor,
      },
      card: {
        color: shopSettings.theme.cardColor,
        background: shopSettings.theme.cardBg,
      },
      body: {
        color: shopSettings.theme.bodyColor,
        background: shopSettings.theme.bodyBg,
      },
    },
  });

  return <ThemeProvider theme={shopTheme}>{children}</ThemeProvider>;
}
