/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useTheme } from '@emotion/react';
import { useDispatch } from 'react-redux';
import { Link, useParams, useHistory } from 'react-router-dom';

import Card from '../../../common/components/card';
import EditablePoints from '../components/editable-points';
import formatPoints from '../../../common/utils/format-points';
import AdminPageContainer from '../../components/admin-page-container';
import { useEffect, useState } from 'react';
import User from '../../../user/types/user';
import { setNotification, setNotificationWithTimeout } from '../../../common/redux/ui/notification/notification-action-creators';
import { USER } from '../../../common/redux/types/feature';
import UserParticipationHistory from 'user-participation-history/components/user-participation-history';
import UserOrderHistory from 'user-order-history/components/user-order-history';
import { UserParticipation } from 'user-participation-history/types/user-participation-history';
import { adminGetUser, adminGetUserOrderHistory, adminGetUserRewards } from 'common/utils/make-api-request';
import { UserOrder, UserOrderItem } from 'user-order-history/types/user-order-history';
import { Heading, Small } from 'common/components/typography';
import { ordersAsItems } from 'common/utils/api-request-utils';

export default function AdminUserPage() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams<{ userId: string }>();
  const [user, setUser] = useState<User | null>(null)
  const [hasUpdatedPoints, setHasUpdatedPoints] = useState(false);
  const [userRewards, setUserRewards] = useState<Array<UserParticipation>>([])
  const [userOrderItems, setUserOrderItems] = useState<Array<UserOrderItem>>([])

  useEffect(()=> {
    adminGetUser(params.userId)
    .then((user: User) => {
      setUser(user)
      setHasUpdatedPoints(false)
    }).catch((err: Error) => {
      dispatch(setNotificationWithTimeout('failure', err.message, USER, 10 * 1000))
      history.push('/admin/users');
    })
    // deps: 'dispatch', 'history', and 'params.userId'
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[hasUpdatedPoints]);

  useEffect(() => {
    if (user !== null) {
      adminGetUserRewards(user.id)
        .then((rewards: UserParticipation[]) => {
          setUserRewards(rewards)
        }).catch((err: Error) => {
          dispatch(setNotification('failure', err.message, USER))
        });
      adminGetUserOrderHistory(user.id)
        .then((orders: UserOrder[]) => {
          setUserOrderItems(ordersAsItems(orders));
        }).catch((err: Error) => {
          dispatch(setNotification('failure', err.message, USER))
        });
    }
    // deps: 'dispatch'
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [user])
return ( user &&
      <AdminPageContainer title="Manage User">
      <Card
        title={`${user.firstName} ${user.lastName}`}
        css={{ borderRadius: '10px', [theme.breakpoint('md')]: { padding: theme.spacing(4) } }}
      >
        <div css={{display: "flex", justifyContent: "space-between", paddingBottom: theme.spacing(2)}}>
          <Heading as="h3" css={{textTransform: 'lowercase'}}>{user.email}</Heading>
          <Heading
            as="h3"
            css={{
              textAlign: 'center',
              [theme.breakpoint('md')]: { textAlign: 'right' },
              fontSize: '1.5em',
              textTransform: 'capitalize',
            }}
          >
            Points Balance: {formatPoints(user.totalPointsEarned - user.totalPointsRedeemed)} <Small>Points</Small>
          </Heading>
        </div>
          <EditablePoints updatedPoints={setHasUpdatedPoints}/>
          <Card toggleable closed title={`Participation History (${userRewards.length})`}>
            <UserParticipationHistory userRewards={userRewards}/>
          </Card>
          <Card toggleable closed title={`Item Order History (${userOrderItems.length})`}>
            <UserOrderHistory orderItems={userOrderItems}/>
          </Card>
        </Card>
          <div css={{ marginTop: theme.spacing(1) }}>
            <Link css={{ color: theme.palette.accent.color, fontWeight: 300 }} to="/admin/users">
              &larr; back to users
            </Link>
          </div>
        </AdminPageContainer>
  );
}
