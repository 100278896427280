import ShopSettings from '../types/shop-settings';

const shopSettingsDefaults: ShopSettings = {
  shopName: 'Default shop name',
  shopTagline: 'Default shop tagline',
  shopLogoUrl: 'https://via.placeholder.com/350x150',
  shopPointsBadgeUrl: 'https://via.placeholder.com/64x64',
  contactEmail: 'default-contact@viddler.com',
  homeUrl: 'https://viddler/com',
  allowNegativeStock: true,
  label100Remote: '100% remote',
  orderMessage: "The message",
  deadlineToReceiveMessage: "The Out Of Stock message",
  outOfStockMessage: "The Out Of Stock message",
  orderEmailLabel: "Contact us",
  reportingTimezone: "America/New_York",
  theme: {
    bodyBg: '#F6F6F6',
    bodyColor: '#444444',
    bodyFontFamily: 'Helvetica, Arial, sans-serif',
    cardBg: '#FFFFFF',
    cardColor: '#444444',
    accentColor: '#FF0266',
  },
  shippingOption: 'location',
};

export default shopSettingsDefaults;
