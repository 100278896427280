/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';

import ShopName from '../../shop/components/shop-name';
import Catalog from '../components/catalog';
import Page from '../../common/components/page';
import { useSelector } from 'react-redux';
import { getShopSettings } from '../../shop-settings/redux/shop-settings-selectors';

export default function CalalogPage() {
  const shopSettings = useSelector(getShopSettings);
  return (
    <Page title={shopSettings.shopName}>
      <ShopName />
      <Catalog />
    </Page>
  );
}
