import { Dispatch } from 'redux';
import { getType, isActionOf } from 'typesafe-actions';

import {
  fetchUserOrderHistory,
  setUserOrderHistory,
  UserOrderHistoryAction,
} from './user-order-history-action-creators';
import { UserOrderHistory } from '../types/user-order-history';
import { USER_ORDER_HISTORY } from '../../common/redux/types/feature';
import { Api } from '../../common/redux/make-api';
import { setLoader } from '../../common/redux/ui/loader/loader-action-creators';
import { setNotification } from '../../common/redux/ui/notification/notification-action-creators';

const makeUserOrderHistoryRouteMiddleware = (userOrderHistoryApi: Api<UserOrderHistory>) => () => (
  next: Dispatch
) => (action: UserOrderHistoryAction) => {
  next(action);

  switch (action.type) {
    case getType(fetchUserOrderHistory):
      next(setLoader(true, USER_ORDER_HISTORY));
      next(userOrderHistoryApi.actions.fetch());
      break;
    case getType(userOrderHistoryApi.actions.fetchSuccess):
      next(setLoader(false, USER_ORDER_HISTORY));
      if (isActionOf(userOrderHistoryApi.actions.fetchSuccess, action)) {
        if (action.payload.data) {
          next(setUserOrderHistory(action.payload.data));
        }
      }
      break;
    case getType(userOrderHistoryApi.actions.createFailure):
      next(setLoader(false, USER_ORDER_HISTORY));
      next(setNotification('failure', 'Could not fetch user order history', USER_ORDER_HISTORY));
      break;
  }
};

export default makeUserOrderHistoryRouteMiddleware;
