/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import AdminPageContainer from '../../components/admin-page-container';
import EditableInventory from '../components/editable-inventory';

export default function AdminInventoryPage() {
  return (
    <div>
    <AdminPageContainer title="Shop Inventory">
          <EditableInventory />
      </AdminPageContainer>
    </div>
  );
}
