import { createAction, ActionType } from 'typesafe-actions';

import Feature from '../../types/feature';
import { NotificationVariant } from './notification-type';

/**
 * Creates a 'document action' to set a notification
 */
export const setNotification = createAction(
  'SET_NOTIFICATION',
  action => (variant: NotificationVariant, message: string, feature: Feature, id?: number) =>
    action({ variant, message, id }, { feature })
);

export const setNotificationWithTimeout = createAction(
  'SET_NOTIFICATION_WITH_TIMEOUT',
  action => (variant: NotificationVariant, message: string, feature: Feature, timeoutMillis: number, id?: number) =>
    action({variant, message, timeoutMillis, id}, {feature})
)

/**
 * Creates a 'document action' to remove a notification
 */
export const removeNotification = createAction(
  'REMOVE_NOTIFICATION',
  action => (id: number, feature: Feature) => action({ id }, { feature })
);

export type NotificationAction = ActionType<typeof setNotification | typeof setNotificationWithTimeout | typeof removeNotification>;
