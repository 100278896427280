/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment, ReactNode } from 'react';

function isUrl(str: string) {
  /**
   * Taken from https://stackoverflow.com/questions/3809401/what-is-a-good-regular-expression-to-match-a-url answer 166
   */
  const urlRegex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/gi;
  return urlRegex.test(str);
}

interface LinkifyProps {
  children: ReactNode;
  label?: string;
}

export default function Linkify(props: LinkifyProps) {
  /**
   * Ignore anything that's NOT a string
   */
  const {children, label} = props;

  if (typeof children !== 'string') {
    return <Fragment>{children}</Fragment>;
  }

  return (
    <Fragment>
      {children.split(' ').map((word: string, index: number) => (
        <Fragment key={index}>
          {isUrl(word) ? (
            <a href={word} target="_blank" rel="noopener noreferrer">
              {label || word}
            </a>
          ) : (
            `${word} `
          )}
        </Fragment>
      ))}
    </Fragment>
  );
}
