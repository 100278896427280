import CatalogItem from '../../catalog/types/catalog-item';
import { Variant } from '../../catalog/types/variant';

const variantDefaults: Variant = {
  id: "",
  size: "",
  color: "",
  imageUrl: "",
  availableQuantity: 0,
  visible: true,
  itemId: ""
};
const variantsDefault: Variant[] = [variantDefaults,variantDefaults]

const catalogItemDefaults: CatalogItem = {
  id: "",
  title: "",
  imageUrl: "",
  points: 0,
  description: "",
  availableQuantity: 0,
  visible: true,
  variants: variantsDefault
};

export default catalogItemDefaults;
