/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Link } from 'react-router-dom';

import Thumbnail from '../../../common/components/thumbnail';
import { useTheme } from '@emotion/react';

interface CatalogItemCardImageProps {
  title: string;
  id: string;
  imageUrl: string;
  showVariantPicker: boolean;
}

export default function CatalogItemCardImage({
  title,
  id,
  imageUrl,
  showVariantPicker,
}: CatalogItemCardImageProps) {
  const theme = useTheme();
  return (
    <div css={{
      maxWidth: '100%',
      maxHeight: '100%',
      }}>
    <Link
      title={title}
      to={`/catalog/${id}`}
      css={{ display: 'block', marginBottom: theme.spacing(1), marginTop: theme.spacing(1), height: '200px'}}
    >
        <Thumbnail
          alt={title + " thumbnail"}
          imageUrl={imageUrl}
          css={{
            width: "100%",
            height: "100%",
            borderWidth: 0,
            paddingRight: showVariantPicker ? '50%' : '0%',
          }}
        />
      </Link>
  </div>
  );
}
