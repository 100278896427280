import { createAction, ActionType } from 'typesafe-actions';

import ShopSettings from '../types/shop-settings';
import { FETCH, SET, UPDATE } from '../../common/redux/constants';
import { SHOP_SETTINGS } from '../../common/redux/types/feature';

export const fetchShopSettings = createAction(`${SHOP_SETTINGS} ${FETCH}`, action => () =>
  action()
);

export const updateShopSettings = createAction(
  `${SHOP_SETTINGS} ${UPDATE}`,
  action => (shopSettings: ShopSettings) => action(shopSettings)
);

export const setShopSettings = createAction(
  `${SHOP_SETTINGS} ${SET}`,
  action => (shopSettings: ShopSettings) => action(shopSettings)
);

export type ShopSettingsAction = ActionType<typeof setShopSettings | typeof fetchShopSettings | typeof updateShopSettings>;
