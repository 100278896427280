import { MiddlewareAPI, Dispatch, Middleware } from 'redux';
import { createAction, ActionType, getType, isActionOf } from 'typesafe-actions';

import Feature from './types/feature';
import { PayloadMetaAction } from 'typesafe-actions/dist/create-action';

import {
  FETCH_REQUEST,
  FETCH_SUCCESS,
  FETCH_FAILURE,
  CREATE_REQUEST,
  CREATE_SUCCESS,
  CREATE_FAILURE,
  UPDATE_REQUEST,
  UPDATE_SUCCESS,
  UPDATE_FAILURE,
  DELETE_REQUEST,
  DELETE_SUCCESS,
  DELETE_FAILURE,
} from './constants';
import { ApiResponse } from '../redux/types/common';
import { HttpClient } from '../http-client/make-http-client';

import ApiUrl from '../types/api-url';
import QueryParams from './types/query-params';
import StringifyQueryParams from '../utils/stringify-query-params';
import { setLoader } from './ui/loader/loader-action-creators';

type SuccessAction<T> = (t: ApiResponse<T>) => PayloadMetaAction<string, ApiResponse<T>, undefined>;
type FailureAction = (message: string) => PayloadMetaAction<string, string, undefined>;

export interface Api<T> {
  actions: {
    fetch: (queryParams?: QueryParams) => PayloadMetaAction<string, undefined, undefined>;
    fetchSuccess: SuccessAction<T>;
    fetchFailure: FailureAction;
    create: (body: {}) => PayloadMetaAction<string, {}, undefined>;
    createSuccess: SuccessAction<T>;
    createFailure: FailureAction;
    update: (body: {}) => PayloadMetaAction<string, {}, undefined>;
    updateSuccess: SuccessAction<T>;
    updateFailure: FailureAction;
    deleteAction: (body: {}) => PayloadMetaAction<string, {}, undefined>;
    deleteSuccess: SuccessAction<T>;
    deleteFailure: FailureAction;
  };
  middleware: Middleware;
}

const makeApi = <T>(feature: Feature, apiPath: ApiUrl, httpClient: HttpClient): Api<T> => {
  const fetch = createAction(`${feature} ${FETCH_REQUEST}`, action => (queryParams?: QueryParams) =>
    action(queryParams)
  );

  const fetchSuccess = createAction(`${feature} ${FETCH_SUCCESS}`, action => (t: ApiResponse<T>) =>
    action(t)
  );

  const fetchFailure = createAction(`${feature} ${FETCH_FAILURE}`, action => (message: string) =>
    action(message)
  );

  const create = createAction(`${feature} ${CREATE_REQUEST}`, action => (body: {}) => action(body));

  const createSuccess = createAction(
    `${feature} ${CREATE_SUCCESS}`,
    action => (t: ApiResponse<T>) => action(t)
  );

  const createFailure = createAction(`${feature} ${CREATE_FAILURE}`, action => (message: string) =>
    action(message)
  );

  const update = createAction(`${feature} ${UPDATE_REQUEST}`, action => (body: {}) => action(body));

  const updateSuccess = createAction(
    `${feature} ${UPDATE_SUCCESS}`,
    action => (t: ApiResponse<T>) => action(t)
  );

  const updateFailure = createAction(`${feature} ${UPDATE_FAILURE}`, action => (message: string) =>
    action(message)
  );

  const deleteAction = createAction(`${feature} ${DELETE_REQUEST}`, action => (body: {}) => action(body));

  const deleteSuccess = createAction(
    `${feature} ${DELETE_SUCCESS}`,
    action => (t: ApiResponse<T>) => action(t)
  );

  const deleteFailure = createAction(`${feature} ${DELETE_FAILURE}`, action => (message: string) =>
    action(message)
  );

  type ApiAction = ActionType<
    | typeof fetch
    | typeof fetchSuccess
    | typeof fetchFailure
    | typeof create
    | typeof createSuccess
    | typeof createFailure
    | typeof update
    | typeof updateSuccess
    | typeof updateFailure
    | typeof deleteAction
    | typeof deleteSuccess
    | typeof deleteFailure
  >;

  const middleware = ({ dispatch, getState }: MiddlewareAPI) => (next: Dispatch) => (
    action: ApiAction
  ) => {
    next(action);

    const makeErrorHandler = (failureAction: FailureAction) => (e: {
      response?: ApiResponse<{ message: string }>;
    }) => {
      if (e.response) {
        const {
          data: { message },
        } = e.response;
        next(setLoader(false, feature));
        dispatch(failureAction(message || ''));
      }
    };

    switch (action.type) {
      case getType(fetch):
        if (isActionOf(fetch, action)) {
          const url = action.payload
            ? `${apiPath}?${StringifyQueryParams(action.payload)}`
            : apiPath;
          httpClient
            .get<T>(url)
            .then((res: ApiResponse<T>) => {
              dispatch(fetchSuccess(res));
            })
            .catch(makeErrorHandler(fetchFailure));
        }
        break;
      case getType(create):
        if (isActionOf(create, action)) {
          httpClient
            .post<T>(apiPath, action.payload)
            .then((res: ApiResponse<T>) => {
              dispatch(createSuccess(res));
            })
            .catch(makeErrorHandler(createFailure));
        }
        break;
      case getType(update):
        if (isActionOf(update, action)) {
          httpClient
            .put<T>(apiPath, action.payload)
            .then((res: ApiResponse<T>) => {
              dispatch(updateSuccess(res));
            })
            .catch(makeErrorHandler(updateFailure));
        }
        break;
      case getType(deleteAction):
        if (isActionOf(deleteAction, action)) {
          httpClient
            .delete<T>(apiPath, action.payload)
            .then((res: ApiResponse<T>) => {
              dispatch(deleteSuccess(res));
            })
            .catch((makeErrorHandler(deleteFailure)));
        }
        break;
    }
  };

  return {
    actions: {
      fetch,
      fetchSuccess,
      fetchFailure,
      create,
      createSuccess,
      createFailure,
      update,
      updateSuccess,
      updateFailure,
      deleteAction,
      deleteSuccess,
      deleteFailure,
    },
    middleware,
  };
};

export default makeApi;
