/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment, useCallback } from 'react';

import VariantPickerSelect from './variant-picker-select';
import { Variants, VariantAttrName } from '../../types/variant';
import { doubleAttrVariantsUtils } from './variant-picker-utils';

interface DoubleVariantPickerProps {
  variants: Variants;
  attrNames: VariantAttrName[];
  attr1Value: string
  attr2Value: string
  setAttr1Value: (attr: string) => void;
  setAttr2Value: (attr: string) => void;
  onPick: (variantId: string) => void;
}

export default function DoubleVariantPicker({
  variants,
  attrNames,
  onPick,
  attr1Value,
  attr2Value,
  setAttr1Value,
  setAttr2Value
}: DoubleVariantPickerProps) {

  const { getAttr1Options, getAttr2Options, getVariantId } = doubleAttrVariantsUtils(
    variants,
    attrNames
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onAttr1Select = useCallback(
    (value: string) => {
      setAttr1Value(value);
      setAttr2Value(''); // reset attr2
      onPick(''); // reset previously picked variant
    },
    [onPick]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onAttr2Select = useCallback(
    (value: string) => {
      setAttr2Value(value);
      onPick(getVariantId(attr1Value, value));
    },
    [onPick, getVariantId, attr1Value]
  );

  const attr2Options = getAttr2Options(attr1Value);

  return (
    <Fragment>
      <VariantPickerSelect
        name={attrNames[0]}
        value={attr1Value}
        onSelect={onAttr1Select}
        options={getAttr1Options()}
      />
      {!!attr2Options.length && (
        <VariantPickerSelect
          name={attrNames[1]}
          value={attr2Value}
          onSelect={onAttr2Select}
          options={attr2Options}
        />
      )}
    </Fragment>
  );
}
