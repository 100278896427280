/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useTheme } from '@emotion/react';
import { useState, useCallback, ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import usStates from '../../common/utils/us-states';
import Select from '../../common/components/select';
import { getCartShipping } from '../redux/cart-selectors';
import { validateAddress } from '../../common/utils/validators';
import { setCartShipping } from '../redux/cart-action-creators';
import { NewShippingAddress } from '../../common/types/shipping';
import { Form, FormRow, FormField, Input } from '../../common/components/form';

export default function AddressForm() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const cartShipping = useSelector(getCartShipping);
  const [address, setAddress] = useState<NewShippingAddress>(() =>
    cartShipping && 'street_address_1' in cartShipping
      ? cartShipping
      : { street_address_1: '', street_address_2: '', city: '', state: '', zipcode: '' }
  );

  const updateAddress = useCallback(
    (fieldId: string, fieldValue: string) => {
      setAddress((prevAddress: NewShippingAddress) => {
        const nextAddress =
          fieldId in prevAddress ? { ...prevAddress, [fieldId]: fieldValue } : prevAddress;

        if (validateAddress(nextAddress)) {
          dispatch(setCartShipping(nextAddress));
        } else {
          dispatch(setCartShipping(undefined));
        }

        return nextAddress;
      });
    },
    [dispatch]
  );

  const handleInputChange = useCallback(
    ({ target: { id, value } }: ChangeEvent<HTMLInputElement>) => {
      updateAddress(id, value);
    },
    [updateAddress]
  );

  const handleSelectChange = useCallback(
    ({ target: { id, value } }: ChangeEvent<HTMLSelectElement>) => {
      updateAddress(id, value);
    },
    [updateAddress]
  );

  return (
    <Form>
      <FormRow>
        <FormField
          css={{
            [theme.breakpoint('md')]: { width: '50%' },
            [theme.breakpoint('lg')]: { width: '50%' },
          }}
          label="Street Address 1"
          inputId="street_address_1"
        >
          <Input
            id="street_address_1"
            value={address.street_address_1}
            onChange={handleInputChange}
          />
        </FormField>
        <FormField
          css={{
            [theme.breakpoint('md')]: { width: '50%' },
            [theme.breakpoint('lg')]: { width: '50%' },
          }}
          label="Street Address 2"
          inputId="street_address_2"
        >
          <Input
            id="street_address_2"
            value={address.street_address_2}
            onChange={handleInputChange}
          />
        </FormField>
      </FormRow>
      <FormRow>
        <FormField
          css={{ [theme.breakpoint('lg')]: { width: '33.33%' } }}
          label="City"
          inputId="city"
        >
          <Input id="city" value={address.city} onChange={handleInputChange} />
        </FormField>
        <FormField
          css={{ [theme.breakpoint('lg')]: { width: '33.33%' } }}
          label="State"
          inputId="state"
        >
          <Select id="state" value={address.state} onChange={handleSelectChange}>
            <option key="unset state" value="">
              --
            </option>
            {usStates.map((state: { name: string; abbr: string }) => (
              <option key={state.abbr} value={state.abbr}>
                {state.name}
              </option>
            ))}
          </Select>
        </FormField>
        <FormField
          css={{ [theme.breakpoint('lg')]: { width: '33.33%' } }}
          label="Zipcode"
          inputId="zipcode"
        >
          <Input id="zipcode" value={address.zipcode} type="number" onChange={handleInputChange} />
        </FormField>
      </FormRow>
    </Form>
  );
}
