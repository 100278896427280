/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Select from '../../common/components/select';
import { getCartShippingLocation } from '../redux/cart-selectors';
import { setCartShippingLocation, showAdditionalAddress } from '../redux/cart-action-creators';
import { ShippingLocation } from '../../common/types/shipping';
import { getShopLocations } from '../../shop-settings/redux/shop-settings-selectors';
import ListItem from '../../common/types/list-item';

interface ILocationSelectType {
  additionalItem?: ListItem[];
}

export default function LocationSelect(props:ILocationSelectType) {
  const dispatch = useDispatch();
  const cartShippingLocation = useSelector(getCartShippingLocation);
  const shopLocations = useSelector(getShopLocations);
  const adItem = props.additionalItem;
  let hookAddress: ListItem = { id: '', description: '' }
  if ((adItem !== undefined) && (adItem.length > 0) && (adItem[0].description.length > 1)){
    hookAddress = adItem[0];
  }
  
  const value = JSON.stringify(cartShippingLocation);

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const location = e.target.value ? JSON.parse(e.target.value) : undefined;      
      if ((location !== undefined) && (location.location_id === "hookAddress")) {
        dispatch(showAdditionalAddress(true));   
        dispatch(setCartShippingLocation(location));
      } else {
        dispatch(showAdditionalAddress(false));   
        dispatch(setCartShippingLocation(location));
      }
    },
    [dispatch]
  );

  return (
    <Select id="location-select" value={value} onChange={onChange}>
      <option key="unset location" value="">
        Select your campus
      </option>
      {shopLocations.map((location: ShippingLocation) => (
        <option
          key={location.id}
          value={JSON.stringify({
            location_id: location.id,
            location_description: location.description,
          })}
        >
          {location.description}
        </option>
      ))}
      {hookAddress.id !== '' && 
      <option key={hookAddress.id} value={JSON.stringify({
        location_id: hookAddress.id,
        location_description: hookAddress.description,
      })} >{hookAddress.description}</option>
      }
    </Select>
  );
}
