/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { withTheme, Theme } from '@emotion/react';
import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import Page from '../../common/components/page';
import CartItems from './cart-items';
import CartShipping from './cart-shipping';
import EmptyCartMessage from './empty-cart-message';
import Button from '../../common/components/button';
import LargeModal from '../../common/components/large-modal';
import { Row, Col } from '../../common/components/layout';
import { Heading } from '../../common/components/typography';
import OrderButton from '../../order/components/order-button';
import { getIsCartEmpty, getIsOrderPlaced, getTotalPointsInCart } from '../redux/cart-selectors';
import { getShopSettings } from '../../shop-settings/redux/shop-settings-selectors';
import { getRemainingUserPoints, getUserPointsBalance } from '../../user/redux/user-selectors';

type CartProps = RouteComponentProps & {
  theme: Theme;
};

function Cart({ theme, history, location }: CartProps) {
  const shopSettings = useSelector(getShopSettings);
  const isCartEmpty = useSelector(getIsCartEmpty);
  const orderPlaced = useSelector(getIsOrderPlaced);
  const RemainingUserPoints = useSelector(getRemainingUserPoints);
  const TotalCartPoints = useSelector(getTotalPointsInCart);
  const CurrentPoints = useSelector(getUserPointsBalance);
  const goToStore = useCallback(() => {
    history.push('/');
  }, [history]);

// Alternate to prop drill, works better for redux flow if a transaction fails
// eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (orderPlaced) {
      history.push('/?cart=order-placed');
    }
  }, [orderPlaced])


  const closeModal = useCallback(() => {
    history.push(location.pathname);
  }, [history, location]);

  const pageTitle = `${shopSettings.shopName}: Shopping Cart`;

  return location && location.search.includes('cart=open') ? (
    <Page title={pageTitle}>
      <LargeModal isOpen onClose={closeModal} ariaLabel={pageTitle}>
        <div style={{}}>
        <Row
          data-testid="cart"
          css={{
            marginTop: theme.spacing(2),
            minHeight: theme.spacing(40),
            [theme.breakpoint('md')]: {
              marginTop: 0,
            },
          }}
        >
          <Col
            css={{
              alignItems: 'center',
              justifyContent: 'center',
              overflowY: 'scroll',
              '::-webkit-scrollbar': {
                display: "none"
              },
              msOverflowStyle: "none",
              scrollbarWidth: "none",
              padding: 0,
              height: "600px",
              borderRight: "none",
              display: isCartEmpty ? 'flex' : 'block',
              [theme.breakpoint('lg')]: {
                padding: 0,
                width: '58.33%',
                borderRight: `1px solid ${theme.palette.accent.color}`,
              }
            }}
          >
            {isCartEmpty ? <EmptyCartMessage /> : <CartItems />}
          </Col>
          <Col
            css={{
              padding: 0,
              marginTop: theme.spacing(4),
              width: '100%',
              [theme.breakpoint('lg')]: {
                width: '41.66%',
              },
            }}
          >
            <div style={{ paddingBottom: "100px"}}>
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                <div>Current Points</div>
                <Heading as="h3">{CurrentPoints}</Heading>
              </div>
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                <div>Cart Points</div>
                <Heading as="h3">- {TotalCartPoints}</Heading>
              </div>
              <hr style={{color: theme.palette.accent.color, opacity: "50%"}}/>
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                <div>Remaining Points</div>
                <Heading as="h3">{RemainingUserPoints}</Heading>
              </div>
            </div>
            <CartShipping />
              <div
                css={{
                  display: 'flex',
                  marginTop: theme.spacing(4),
                }}
              >
                {/* void func, see alternative to prop drill above for this callback */}
                <OrderButton onAfterOrder={()=>{return}} />
                <Button
                  outline
                  size="md"
                  onClick={goToStore}
                  css={{ marginBottom: theme.spacing(1) }}
                >
                  Keep Shopping
                </Button>
              </div>
          </Col>
        </Row>
        </div>
      </LargeModal>
    </Page>
  ) : null;
}

export default withTheme(Cart);
