import { Dispatch } from 'redux';
import { getType } from 'typesafe-actions';

import {
  setNotification,
  setNotificationWithTimeout,
  removeNotification,
  NotificationAction
} from './notification-action-creators';

export default function makeNotificationMiddleware(autoRemoveTimeout: number = 3000) {
  const notificationMiddleware = () => (next: Dispatch) => (action: NotificationAction) => {
    if (action.type === getType(setNotification)) {
      const id = new Date().getMilliseconds();

      /**
       * Dispatch an enriched action with a id
       */
      const enrichedAction = { ...action, payload: { ...action.payload, id } };
      next(enrichedAction);

      /**
       * Dispatch a remove action after a given time
       */
      setTimeout(() => {
        next(removeNotification(id, action.meta.feature));
      }, autoRemoveTimeout);

    } else if (action.type === getType(setNotificationWithTimeout)) {
      const id = new Date().getMilliseconds();

      /**
       * Dispatch an enriched action with a id
       */
      const enrichedAction = { ...action, payload: { ...action.payload, id } };
      next(enrichedAction);

      /**
       * Dispatch a remove action after a given time
       */
      setTimeout(() => {
        next(removeNotification(id, action.meta.feature));
      }, action.payload.timeoutMillis);

    } else {
      next(action);
    }
  }

  return notificationMiddleware;
}
