export const UI = '[UI]';
export const USER = '[User]';
export const USERS = '[Users]';
export const USER_PARTICIPATION_HISTORY = '[User Participation History]';
export const USER_ORDER_HISTORY = '[User Order History]';
export const CART = '[Cart]';
export const ORDER = '[Order]';
export const CATALOG = '[Catalog]';
export const CATALOG_ITEM = '[Catalog Item]';
export const CATALOG_ITEM_VARIANT = '[Catalog Item Variant]';
export const INVENTORY = '[Inventory]';
export const SHOP_SETTINGS = '[ShopSettings]';
export const UPLOAD_ITEM_IMAGE = '[Upload Item Image]';
export const UPLOAD_ITEM_SIZECHART_IMAGE = '[Upload Item SizeChart Image]';
export const UPLOAD_ITEM_VARIANT_IMAGE = '[Upload Item Variant Image]';
export const PDF_GENERATOR = "[Generate and Download PDF]"
export const CSV_GENERATOR = "[Generate and Download CSV]"

type Feature =
  | typeof UI
  | typeof USER
  | typeof USERS
  | typeof USER_PARTICIPATION_HISTORY
  | typeof USER_ORDER_HISTORY
  | typeof CART
  | typeof ORDER
  | typeof CATALOG
  | typeof CATALOG_ITEM
  | typeof CATALOG_ITEM_VARIANT
  | typeof INVENTORY
  | typeof SHOP_SETTINGS
  | typeof UPLOAD_ITEM_IMAGE
  | typeof UPLOAD_ITEM_SIZECHART_IMAGE
  | typeof UPLOAD_ITEM_VARIANT_IMAGE
  | typeof PDF_GENERATOR
  | typeof CSV_GENERATOR


export default Feature;
