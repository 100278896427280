/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { ReactNode } from 'react';
import { Helmet } from 'react-helmet';
import { useTheme } from '@emotion/react';

import { Container } from '../../common/components/layout';
import AdminPageTitle from './admin-page-title';

interface PageProps {
  title: string;
  children: ReactNode;
}

export default function AdminPageContainer({ title, children }: PageProps) {
  const theme = useTheme();

  return (
    <div css={{ padding: theme.spacing(1), paddingBottom: theme.spacing(8) }}>
      <Helmet>
        <title>Admin: {title}</title>
      </Helmet>
      <Container css={{ marginTop: theme.spacing(4) }}>
        <AdminPageTitle title={title} />
        {children}
      </Container>
    </div>
  );
}
