/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';

import formatDate from '../../common/utils/format-date';
import { UserOrderItem } from '../types/user-order-history';
import { Table, TableSection } from '../../common/components/table';

interface OrderHistoryProps {
  orderItems: UserOrderItem[];
}

export default function UserOrderHistory(props: OrderHistoryProps) {
  var totalItems = 0
  var totalPoints = 0

  props.orderItems.forEach((orderItem: UserOrderItem) => {
      totalItems += orderItem.quantity
      totalPoints += orderItem.quantity * orderItem.points
  })

  return (
    <Table>
      <TableSection as="thead">
        <tr css={{ th: { width: '20%' } }}>
          <th>Date</th>
          <th>Item Name</th>
          <th>Item Points</th>
          <th>Quantity</th>
          <th>Total Points</th>
        </tr>
      </TableSection>
      <TableSection as="tbody">
        {props.orderItems.map((item: UserOrderItem, index: number) => (
              <tr key={index}>
                <td>{formatDate(item.date)}</td>
                <td>{item.title}</td>
                <td>{item.points}</td>
                <td>{item.quantity}</td>
                <td>{item.points * item.quantity}</td>
              </tr>
          ))}
      </TableSection>
      <TableSection as="tfoot">
        <tr>
          <td>Totals:</td>
          <td></td>
          <td></td>
          <td>
            {totalItems}
            {totalItems === 1 ? ' Item' : ' Items'}
          </td>
          <td>{totalPoints} Points</td>
        </tr>
      </TableSection>
    </Table>
  );
}
