import { composeWithDevTools } from '@redux-devtools/extension';
import { applyMiddleware, createStore, combineReducers, Middleware } from 'redux';

import makeApi from './make-api';
import {
  USER,
  CATALOG,
  CATALOG_ITEM,
  SHOP_SETTINGS,
  ORDER,
  USER_PARTICIPATION_HISTORY,
  USER_ORDER_HISTORY,
  USERS,
  CATALOG_ITEM_VARIANT,
  INVENTORY,
} from 'common/redux/types/feature';

import {
  ORDER_API_URL,
  CATALOG_API_URL,
  CATALOG_ITEM_API_URL,
  CATALOG_ITEM_VARIANT_API_URL,
  USER_PROFILE_API_URL,
  USER_SEARCH_API_URL,
  SHOP_SETTINGS_API_URL,
  USER_PARTICIPATION_HISTORY_API_URL,
  USER_ORDER_HISTORY_API_URL,
  INVENTORY_API_URL,
} from 'common/types/api-url';

import cartReducer from '../../cart/redux/cart-reducer';
import cartRouteMiddleware from '../../cart/redux/cart-route-middleware';
import makeCartCacheMiddleware from '../../cart/redux/make-cart-cache-middleware';

import User from '../../user/types/user';
import userReducer from '../../user/redux/user-reducer';
import makeUserRouteMiddleware from '../../user/redux/make-user-route-middleware';

import usersReducer from '../../admin/users/redux/users-reducer';

import Catalog from '../../catalog/types/catalog';
import catalogReducer from '../../catalog/redux/catalog-reducer';
import makeCatalogRouteMiddleware from '../../catalog/redux/make-catalog-route-middleware';

import CatalogItem from '../../catalog/types/catalog-item';
import catalogItemReducer from '../../catalog-item/redux/catalog-item-reducer';
import makeCatalogItemRouteMiddleware from '../../catalog-item/redux/make-catalog-item-route-middleware';

import { Variant } from '../../catalog/types/variant';
import catalogItemVariantReducer from '../../catalog-item-variant/redux/catalog-item-variant-reducer';
import makeCatalogItemVariantRouteMiddleware from '../../catalog-item-variant/redux/make-catalog-item-variant-route-middleware';

import ShopSettings from '../../shop-settings/types/shop-settings';
import shopSettingsReducer from '../../shop-settings/redux/shop-settings-reducer';
import makeShopSettingsRouteMiddleware from '../../shop-settings/redux/make-shop-settings-route-middleware';

import Order from '../../order/types/order';
import makeOrderRouteMiddleware from '../../order/redux/order-route-middleware';

import loaderReducer from './ui/loader/loader-reducer';

import interactionReducer from './interaction/interaction-reducer';

import { HttpClient } from '../http-client/make-http-client';
import notificationReducer from './ui/notification/notification-reducer';
import makeNotificationMiddleware from './ui/notification/make-notification-middleware';

import { UserParticipationHistory } from '../../user-participation-history/types/user-participation-history';
import userParticipationHistoryReducer from '../../user-participation-history/redux/user-participation-history-reducer';
import makeUserParticipationHistoryRouteMiddleware from '../../user-participation-history/redux/make-user-participation-history-route-middleware';
import userOrderHistoryReducer from '../../user-order-history/redux/user-order-history-reducer';
import { UserOrderHistory } from '../../user-order-history/types/user-order-history';
import makeUserOrderHistoryRouteMiddleware from '../../user-order-history/redux/make-user-order-history-route-middleware';
import makeUsersRouteMiddleware from '../../admin/users/redux/make-users-route-middleware';
import Inventory from 'admin/inventory/types/inventory';
import makeInventoryRouteMiddleware from 'admin/inventory/redux/make-inventory-route-middleware';
import inventoryReducer from 'admin/inventory/redux/inventory-reducer';

export default function makeStore(httpClient: HttpClient, storage: Storage) {
  const rootReducer = combineReducers({
    user: userReducer,
    users: usersReducer,
    userParticipationHistory: userParticipationHistoryReducer,
    userOrderHistory: userOrderHistoryReducer,
    cart: cartReducer,
    inventory: inventoryReducer,
    catalog: catalogReducer,
    catalogItem: catalogItemReducer,
    catalogItemVariant: catalogItemVariantReducer,
    shopSettings: shopSettingsReducer,
    ui: combineReducers({
      loader: loaderReducer,
      notifications: notificationReducer,
    }),
    userInteraction: interactionReducer,
  });

  const usersApi = makeApi<User[]>(USERS, USER_SEARCH_API_URL, httpClient);
  const userApi = makeApi<User>(USER, USER_PROFILE_API_URL, httpClient);
  const userParticipationHistoryApi = makeApi<UserParticipationHistory>(
    USER_PARTICIPATION_HISTORY,
    USER_PARTICIPATION_HISTORY_API_URL,
    httpClient
  );
  const userOrderHistoryApi = makeApi<UserOrderHistory>(
    USER_ORDER_HISTORY,
    USER_ORDER_HISTORY_API_URL,
    httpClient
  );
  const catalogApi = makeApi<Catalog>(CATALOG, CATALOG_API_URL, httpClient);
  const catalogItemApi = makeApi<CatalogItem>(CATALOG_ITEM, CATALOG_ITEM_API_URL, httpClient);
  const catalogItemVariantApi = makeApi<Variant>(CATALOG_ITEM_VARIANT, CATALOG_ITEM_VARIANT_API_URL, httpClient);
  const shopSettingsApi = makeApi<ShopSettings>(SHOP_SETTINGS, SHOP_SETTINGS_API_URL, httpClient);
  const orderApi = makeApi<Order>(ORDER, ORDER_API_URL, httpClient);
  const inventoryApi = makeApi<Inventory>(INVENTORY, INVENTORY_API_URL, httpClient);

  const featureMiddlewares: Middleware[] = [

    makeCatalogItemRouteMiddleware(catalogItemApi),
    catalogItemApi.middleware,

    makeCatalogItemVariantRouteMiddleware(catalogItemVariantApi),
    catalogItemVariantApi.middleware,

    makeOrderRouteMiddleware(orderApi),
    orderApi.middleware,

    cartRouteMiddleware,
    makeCartCacheMiddleware(storage),

    makeUserRouteMiddleware(userApi),
    userApi.middleware,

    makeUsersRouteMiddleware(usersApi, httpClient),
    usersApi.middleware,

    makeUserParticipationHistoryRouteMiddleware(userParticipationHistoryApi),
    userParticipationHistoryApi.middleware,

    makeUserOrderHistoryRouteMiddleware(userOrderHistoryApi),
    userOrderHistoryApi.middleware,

    makeInventoryRouteMiddleware(inventoryApi),
    inventoryApi.middleware,

    makeCatalogRouteMiddleware(catalogApi),
    catalogApi.middleware,



    makeShopSettingsRouteMiddleware(shopSettingsApi),
    shopSettingsApi.middleware,
  ];

  const coreMiddlewares: Middleware[] = [makeNotificationMiddleware()];

  const middlewareEnhancer = applyMiddleware(...featureMiddlewares, ...coreMiddlewares);

  const enhancers = [middlewareEnhancer];

  const composedEnhancers = composeWithDevTools(...enhancers);

  return createStore(rootReducer, composedEnhancers);
}
