/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import React, { ComponentProps } from 'react';
import { withTheme, Theme } from '@emotion/react';


type HeadingProps = React.ComponentProps<'h1'> & { as: 'h1' | 'h3'; theme: Theme };

export const Heading = withTheme(({ as, theme, children, ...restProps }: HeadingProps) => {
  const baseStyles = css({
    margin: 0,
    lineHeight: 1,
    fontWeight: 'normal',
  });
  const styles = {
    h1: css([baseStyles, { fontSize: '2.25em' }]),
    h3: css([
      baseStyles,
      {
        fontSize: '1.25em',
        textTransform: 'uppercase',
        color: theme.palette.accent.color,
      },
    ]),
  };
  return jsx(as, { ...restProps, css: styles[as] }, children);
});

export const P = (props: ComponentProps<'p'>) => (
  <p css={[{ margin: 0 }]} {...props}>
    {props.children}
  </p>
);

export const Small = (props: ComponentProps<'small'>) => (
  <small css={{ fontSize: '.75em' }} {...props}>
    {props.children}
  </small>
);
