import { VariantAttrName, Variant, Variants } from '../../types/variant';

/**
 * Parse out the variant attributes
 */
export function getAttrNames({ size, color }: Variant) {
  const attrNames: VariantAttrName[] = [];

  if (size) attrNames.push('size');
  if (color) attrNames.push('color');

  return attrNames;
}

/**
 * Returns utility functions to get properties from given variants with 1 varying attribute
 */
export function singleAttrVariantsUtils(variants: Variants, attrName: VariantAttrName) {
  /**
   * This data structure makes it easier to get variant id by attribute,
   * and to list the unique attribute values
   * eg. { 'red': 1, 'blue': 2 }
   */
  const variantIdByAttr: { [key: string]: string } = variants.reduce(
    (acc: { [key: string]: string }, cur: Variant) => {
      if  (cur.visible) {
        return { ...acc, [`${cur[attrName]}`]: cur.id }
      }
      return { ...acc }
    },
    {}
  );

  const getOptions = () => Object.keys(variantIdByAttr);
  const getVariantId = (option: keyof { [key: string]: string }) => variantIdByAttr[option];

  return { getOptions, getVariantId };
}

/**
 * Returns utility functions to get properties from given variants with a 2 varying attributes
 */
export function doubleAttrVariantsUtils(variants: Variants, attrNames: VariantAttrName[]) {
  const attr1 = attrNames[0];
  const attr2 = attrNames[1];

  /**
   * This data structure makes it easier relate both attributes
   * and to list the unique attribute values of both attributes
   * eg. { sm: ['red', 'blue', 'pink'], 'lg': ['red', 'blue'] }
   */
  const attr2OptionsByAttr1: { [key: string]: string[] } = variants.reduce(
    (acc: { [key: string]: string[] }, cur: Variant) => {
      const attr1Value = cur[attr1];
      const attr2Value = cur[attr2];
      if (!attr1Value || !attr2Value) {
        return acc;
      }
      if  (!cur.visible) return acc;
      return acc[attr1Value]
        ? { ...acc, [attr1Value]: [...acc[attr1Value], attr2Value] }
        : { ...acc, [attr1Value]: [attr2Value] };
    },
    {}
  );

  /**
   * This data structure makes it easier to find variant id by attributes
   * eg. { sm_red: 1, lg_blue: 2 }
   */
  const variantIdByAttrs: { [key: string]: string } = variants.reduce(
    (acc: { [key: string]: string }, cur: Variant) => ({
      ...acc,
      [`${cur[attr1]}_${cur[attr2]}`]: cur.id,
    }),
    {}
  );

  const getAttr1Options = () => Object.keys(attr2OptionsByAttr1);
  const getAttr2Options = (attr1: string) => attr2OptionsByAttr1[attr1] || [];
  const getVariantId = (attr1: string, attr2: string) => variantIdByAttrs[`${attr1}_${attr2}`];

  return {
    getAttr1Options,
    getAttr2Options,
    getVariantId,
  };
}
