import { createSelector } from 'reselect';

import User from '../types/user';
import { USER } from '../../common/redux/types/feature';
import { getLoaderState } from '../../common/redux/ui/ui-selectors';
import { getTotalPointsInCart } from '../../cart/redux/cart-selectors';
import State, { UserState, LoaderState } from '../../common/redux/types/state';
import formatPoints from '../../common/utils/format-points';

const getUserState = (state: State) => state.user;

export const getUser = createSelector(getUserState, (userState: UserState) => userState.data);

export const getUserLocations = createSelector(getUser, (user: User) => user.addresses || []);

/**
 * Ignores points spent in current cart
 */
export const getUserPointsBalance = createSelector(
  getUser,
  (user: User): number => user.totalPointsEarned - user.totalPointsRedeemed
);

/**
 * Accounts for points spent in current cart
 */
export const getRemainingUserPoints = createSelector(
  getUser,
  getTotalPointsInCart,
  (userData: User, totalPointsInCart: number): number =>
    userData.totalPointsEarned - userData.totalPointsRedeemed - totalPointsInCart
);

/**
 * Accounts for points spent in current cart
 */
export const getFormattedRemainingUserPoints = createSelector(getRemainingUserPoints, formatPoints);

export const getHasFetchedUser = createSelector(
  getUserState,
  (userState: UserState) => userState.meta.fetched
);

export const getFetchingUser = createSelector(
  getLoaderState,
  (loaderState: LoaderState) => loaderState[USER]
);
