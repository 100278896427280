import { getType, isActionOf } from 'typesafe-actions';

import { CatalogItemVariantAction, updateCatalogItemVariant, createCatalogItemVariant, deleteCatalogItemVariant } from './catalog-item-variant-action-creators';
import { CatalogItemVariantState } from '../../common/redux/types/state';
import variantDefaults from './catalog-item-variant-defaults';

const initialState = {
  data: variantDefaults,
  meta: {
    fetched: false,
  },
};

function catalogItemVariantReducer(
  state: CatalogItemVariantState = initialState,
  action: CatalogItemVariantAction
): CatalogItemVariantState {
  switch (action.type) {
    case getType(updateCatalogItemVariant):
      if (isActionOf(updateCatalogItemVariant, action)) {
        return {
          data: action.payload,
          meta: { fetched: true },
        };
      }
      return state;

    case getType(createCatalogItemVariant):
      if (isActionOf(createCatalogItemVariant, action)) {
        return {
          data: action.payload,
          meta: { fetched: true },
        };
      }
      return state;
    case getType(deleteCatalogItemVariant):
      if (isActionOf(deleteCatalogItemVariant, action)) {
        return {
          data: action.payload,
          meta: { fetched: true },
        };
      }
      return state;
    default:
      return state;
  }
}

export default catalogItemVariantReducer;
