/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';

import CatalogItem from '../../../catalog/types/catalog-item';
import Card from '../../../common/components/card';
import { useTheme } from '@emotion/react';
import EditableInventoryItem from './editable-inventory-item';
import { useState } from 'react';
import AddInventoryItem from './add-inventory-item';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashSlash } from '@fortawesome/pro-solid-svg-icons';
import TooltipWrap from 'common/components/tooltip-wrap';

interface EditableInventoryListProps {
  items: CatalogItem[];
  entityName: string;
  isLoading: boolean;
}

export default function EditableInventoryList({
  items,
  entityName,
  isLoading,
}: EditableInventoryListProps) {
  const theme = useTheme();

  const [search, setSearch] = useState('');
  const [showDeleted, setShowDeleted] = useState(false);

  const shouldItemRender = (item: CatalogItem) => {
    if (showDeleted) {
      return item.title.toLowerCase().includes(search.toLowerCase());
    }
    return item.title.toLowerCase().includes(search.toLowerCase()) && !item.deletedAt;
  };

  const hasDeletedItemVariants = (item: CatalogItem) => {
    return item.variants?.some(variant => variant.deletedAt) && showDeleted;
  };

  const renderSearchBar = () => {
    return (
      <div
        css={{
          display: 'inline-flex',
          padding: theme.spacing(1),
          backgroundColor: "white",
          boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.5)",
          borderRadius: "10px",
          width: "33.33%",
        }}>
        <form
          css={{
            display: 'inline-flex',
            marginLeft: theme.spacing(2)
          }}>
          <input
            css={{ width: "100%", border: "none", outline: "0" }}
            size={35}
            type="text"
            value={search}
            onChange={handleChange}
            placeholder="Search..."
            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
          />
        </form>
      </div>
    )
  }
  // sets the state of search term after change on search bar input
  const handleChange = (e: { target: { value: string; }; }) => {
    setSearch(e.target.value);
  };

  return (
    <div data-testid="editable-inventory-list">
      <div
        css={{
          marginTop: theme.spacing(4),
          display: 'flex',
          justifyContent: 'space-between',
          alignContent: 'center',
        }}>
        {renderSearchBar()}
          <TooltipWrap text={showDeleted? "hide deleted items" : "show deleted items"}>
            {
              showDeleted?
              <FontAwesomeIcon icon={faTrashSlash} css={{color: theme.palette.common.darkGrey, cursor: 'pointer', height: '2em'}} onClick={() => setShowDeleted(!showDeleted)}/>
              :
              <svg css={{cursor: 'pointer', fill: theme.palette.common.darkGrey}} onClick={() => setShowDeleted(!showDeleted)} xmlns="http://www.w3.org/2000/svg" height="2em" viewBox="0 0 640 512"><path d="M0 64C0 46.3 14.3 32 32 32c32 0 64 0 96 0c2.4-4.8 4.8-9.5 7.2-14.3C140.6 6.8 151.7 0 163.8 0c40.1 0 80.3 0 120.4 0c12.1 0 23.2 6.8 28.6 17.7c2.4 4.8 4.8 9.5 7.2 14.3c32 0 64 0 96 0c17.7 0 32 14.3 32 32s-14.3 32-32 32c-128 0-256 0-384 0C14.3 96 0 81.7 0 64zm32 64l384 0c-4.5 72.4-9.1 144.9-13.6 217.3c-16.4 16.4-32.7 32.7-49.1 49.1c-8.2 8.2-14 18.5-16.8 29.7c-5 20-10.1 40.1-15.1 60.1c-2.3 9.3-1.8 19 1.4 27.8c-73.9 0-147.8 0-221.7 0c-25.3 0-46.3-19.7-47.9-45C46.1 354 39.1 241 32 128zM353.5 492.1c5-20 10-40.1 15-60.1c1.4-5.7 4.3-10.8 8.4-14.9C420 374 463.1 331 506.2 287.9c-.1 0 0 0-.1 0c23.7 23.7 47.3 47.3 71 71C534 402 491 445 447.9 488.1c-4.1 4.1-9.3 7-14.9 8.4c-20 5-40.1 10-60.1 15c-5.5 1.4-11.2-.2-15.2-4.2s-5.6-9.7-4.2-15.2zM528.8 265.3c9.8-9.8 19.6-19.6 29.4-29.4c15.7-15.6 41-15.6 56.6 0c4.8 4.8 9.5 9.5 14.3 14.3c15.6 15.7 15.6 41 0 56.6c-9.8 9.8-19.6 19.6-29.4 29.4l-70.9-70.9z"/></svg>
            }
          </TooltipWrap>
      </div>
      <Card css={{marginTop: theme.spacing(2), borderRadius: '10px'}} title="Add New Item" toggleable isAddNew closed>
        <AddInventoryItem />
      </Card>
      {items.map(item => (
        shouldItemRender(item) && (
          <Card key={`card_${item.id}`}
            title={item.title}
            previewImage={item.imageUrl}
            headerWarning={hasDeletedItemVariants(item)}
            toggleable
            closed
            css={{
              marginTop: theme.spacing(2),
              borderRadius: '10px',
              border: item.deletedAt? `${theme.palette.common.red} solid 3px`: "none",
            }}>
            <EditableInventoryItem item={item} isLoading={isLoading} entityName={entityName} showDeletedVariants={showDeleted}/>
          </Card>
        )
      ))}
    </div>
  );
}
