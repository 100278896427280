import { Variant } from '../../catalog/types/variant';

const variantDefaults: Variant = {
  id: "",
  size: "",
  color: "",
  imageUrl: "",
  availableQuantity: 0,
  visible: true,
  itemId: ""
};

export default variantDefaults;
