/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { LinkProps, Link } from 'react-router-dom';
import { useTheme } from '@emotion/react';

export default function AdminNavItem({ children, ...restProps }: LinkProps) {
  const theme = useTheme();

  return (
    <li
      css={{
        borderBottom: `1px ${theme.palette.common.lighterGrey} solid`,
        '&:last-of-type': {
          borderBottom: 'none',
        },
      }}
    >
      <Link
        css={{
          color: theme.palette.body.color,
          textDecoration: 'none',
          display: 'block',
          padding: theme.spacing(1.25),
          paddingTop: theme.spacing(0.5),
          paddingBottom: theme.spacing(0.5),
          '&:hover': {
            border: '1px solid',
            borderColor: 'black'
          },
        }}
        {...restProps}
      >
        {children}
      </Link>
    </li>
  );
}
