/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useTheme } from '@emotion/react';

import Thumbnail from '../../../common/components/thumbnail';

interface ImageProps {
  url: string;
}

export default function Image({ url }: ImageProps) {
  const theme = useTheme()
  return (
    <div css={{
      maxWidth: "100%",
      maxHeight: "100%",
    }}>
      <Thumbnail
        alt={"Catalog Item Thumbnail"}
        imageUrl={url}
        css={{
          width: '100%',
          height: '100%',
          borderRadius: '10px',
          boxShadow: `0px 0px 30px ${theme.palette.common.darkGrey}`,
        }}
      />
  </div>
    )
};

