/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Cart from '../../cart/components/cart';
import UserPage from '../../user/pages/user-page';
import ShopHeader from './shop-header';
import AdminPage from '../../admin/pages/admin-page';
import CalalogPage from '../../catalog/pages/catalog-page';
import CatalogItemPage from '../../catalog/pages/catalog-item-page';
import ShopGlobalStyles from './shop-global-styles';
import OrderPlacedMessage from '../../cart/components/order-placed-message';
import ShopSettingsModal from '../../shop-settings/components/shop-settings-modal';
import { fetchUser } from '../../user/redux/user-action-creators';
import { getCartFromCache } from '../../cart/redux/cart-action-creators';
import { fetchCatalog } from '../../catalog/redux/catalog-action-creators';
import { fetchShopSettings } from '../../shop-settings/redux/shop-settings-action-creators';
import {
  getHasFetchedShopSettings,
  getShopSettings,
} from '../../shop-settings/redux/shop-settings-selectors';
import { getCatalogArray } from '../../catalog/redux/catalog-selectors';
import ShopNotifications from './shop-notifications';
import LoadingIndicator from './shop-loading';

export default function Shop() {
  const dispatch = useDispatch();
  const shopSettings = useSelector(getShopSettings);
  const hasFetchedShopSettings = useSelector(getHasFetchedShopSettings);
  const catalog = useSelector(getCatalogArray);

  /**
   * Normally, as a best practice, data should be fetched from where it's needed.
   *
   * Exceptionally in this app, 'user', 'catalog', 'shopSettings' and 'cart'
   * are needed in every page, or at least most of them. Therefore, for the sake of simplicity,
   * I fetch 'em all from here, which is the outer most component.
   */
  useEffect(() => {
    dispatch(fetchUser());
    dispatch(fetchShopSettings());
    dispatch(fetchCatalog());
  }, [dispatch]);

  useEffect(() => {
    if (catalog.length > 0) {
      dispatch(getCartFromCache(catalog));
    }
  }, [dispatch, catalog])

  return (
    <React.Fragment>
      {hasFetchedShopSettings ? (
        <Router>
          <Helmet>
            <title>{shopSettings.shopName}</title>
          </Helmet>
          <ShopGlobalStyles />
          <ShopHeader />
          <ShopNotifications />
          <LoadingIndicator />
          <Switch>
            <Route path="/catalog/:calalogItemId" component={CatalogItemPage} />
            <Route path="/user" component={UserPage} />
            <Route path="/admin" component={AdminPage} />
            <Route path="/" component={CalalogPage} />
          </Switch>
          <Route component={Cart} />
          <Route component={OrderPlacedMessage} />
          <Route component={ShopSettingsModal} />
        </Router>
      ) : (
          <LoadingIndicator color="black"/>
      )}
    </React.Fragment>
  );
}
