import { getType, isActionOf } from 'typesafe-actions';

import { UsersAction, setUsers, setUserPoints } from './users-action-creators';
import { UsersState } from '../../../common/redux/types/state';

const initialState = {
  data: {},
  meta: {
    fetched: false,
  },
};

function usersReducer(state: UsersState = initialState, action: UsersAction): UsersState {
  switch (action.type) {
    case getType(setUsers):
      if (isActionOf(setUsers, action)) {
        return {
          data: action.payload,
          meta: { fetched: true },
        };
      }
      return state;
    case getType(setUserPoints):
      if (isActionOf(setUserPoints, action)) {
        const { userId, points } = action.payload;
        const user = state.data[userId];
        const updatedUser = { ...user, totalPointsEarned: user.totalPointsEarned + points };
        return { ...state, data: { ...state.data, [userId]: updatedUser } };
      }
      return state;
    default:
      return state;
  }
}

export default usersReducer;
