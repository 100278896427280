import { createAction, ActionType } from 'typesafe-actions';

import { FETCH, SET } from 'common/redux/constants';
import { INVENTORY } from 'common/redux/types/feature';
import Inventory from 'admin/inventory/types/inventory';

export const fetchInventory = createAction(`${INVENTORY} ${FETCH}`, action => () => action());

export const setInventory = createAction(`${INVENTORY} ${SET}`, action => (inventory: Inventory) =>
  action(inventory)
);

export type InventoryAction = ActionType<typeof fetchInventory | typeof setInventory>;
