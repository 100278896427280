/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { useTheme } from '@emotion/react';
import ShopTagline from '../../shop/components/shop-tagline';
import ShopGreeting from '../../shop/components/shop-greeting';

import CatalogItem from '../types/catalog-item';
import CatalogItemCard from './catalog-item-card';
import { Container, Row } from '../../common/components/layout';
import { getCatalogArray } from '../redux/catalog-selectors';
import { getRemainingUserPoints } from '../../user/redux/user-selectors';
import { getShopSettings } from '../../shop-settings/redux/shop-settings-selectors';
import { Variants } from '../types/variant';
import Button from '../../common/components/button';

export default function Catalog() {
  const catalog = useSelector(getCatalogArray);
  const userPoints = useSelector(getRemainingUserPoints);
  const shopSettings = useSelector(getShopSettings);

  const [sortDirection, setSortDirection] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const theme = useTheme();

  const sortCatalogItemsByPoints = (catalogItems: CatalogItem[], direction: Boolean) => {
    if (direction) {
      catalogItems.sort((a: CatalogItem, b: CatalogItem) => {
        if (a.points > b.points) return 1;
        if (a.points < b.points) return -1;
        return 0;
      });
    } else {
        catalogItems.sort((a: CatalogItem, b: CatalogItem) => {
        if (a.points < b.points) return 1;
        if (a.points > b.points) return -1;
        return 0;
      });
    }
    return catalogItems;
  }

  const hasVisibleVariants = (v:Variants| undefined) => {
    if (v === undefined) return true;

    return v.reduce((acc, cur) => {
      if (cur.visible) return (acc + 1)
      return acc
    } ,0)
  }
    // Renders a catalog item if it is visible, has visible variants, and matches the search term in title or desc.
    const shouldCatalogItemRender = (catalogItem: CatalogItem) => {
    return (
      catalogItem.visible &&
      hasVisibleVariants(catalogItem.variants) &&
      (catalogItem.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      catalogItem.description.toLowerCase().includes(searchTerm.toLowerCase()))
    ) ? true : false;
  }

  const renderSortButton = () => {
    return (
      <div
        css={{
          display: 'inline-flex',
          float: "right",
          marginRight: theme.spacing(4),
          marginTop: theme.spacing(5),
        }}>
      <Button
        onClick={() => setSortDirection(!sortDirection)}
        size="sm"
        color={theme.palette.accent.color}
        margin="0em 00em 0em 1rem"
        title="Sort by points"
      >
        Sort
      </Button>
    </div>
    )
  }

  const renderSearchBar = () => {
    return (
      <div
        css={{
          display: 'inline-flex',
          padding: theme.spacing(1),
          marginLeft: theme.spacing(4),
          marginRight: theme.spacing(4),
          marginTop: theme.spacing(5),
          backgroundColor: "white",
          boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.5)",
          borderRadius: "10px",
          width: "33.33%",
        }}>
        <form
          css={{
            display: 'inline-flex',
            marginLeft: theme.spacing(2)
          }}>
          <input
            css={{  width: "100%", border: "none", outline: "0" }}
            size={35}
            type="text"
            value={searchTerm}
            onChange={handleChange}
            placeholder="Search..."
            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
          />
      </form>
    </div>
    )
  }

  const renderGreetingCard = () => {
    return (
      <div
        css={{
          backgroundColor: shopSettings.theme.accentColor,
          borderRadius: '10px',
          width: '50%',
          minWidth: '300px',
          paddingTop: '10px',
          paddingBottom: '10px',
          boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.5)",
          marginLeft: theme.spacing(4),
          margin: "auto",
        }}>
        <ShopGreeting />
        <ShopTagline />
      </div>
    )
  }

  // sets the state of search term after change on search bar input
  const handleChange = (e: { target: { value: string; }; }) => {
    setSearchTerm(e.target.value);
  };

  return (
    <Container>
      {renderGreetingCard()}
      {renderSearchBar()}
      {renderSortButton()}
      <Row data-testid="catalog">
        { sortCatalogItemsByPoints(catalog, sortDirection).map((catalogItem: CatalogItem) => {
          if (shouldCatalogItemRender(catalogItem)) {
            return <CatalogItemCard
              {...catalogItem}
              key={catalogItem.id}
              userPoints={userPoints}
              allowNegativeStock={shopSettings.allowNegativeStock}
            />
          }
          return false
        })}
      </Row>
    </Container>
  );
}
