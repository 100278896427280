export interface ConfigOptionsValues {
  [key: string]: any;
}

export interface ConfigOptions<T> {
  development: T;
  production: T;
  staging: T;
  test: T;
}

const NODE_ENV: keyof ConfigOptions<any> =
  process.env.NODE_ENV !== 'development' &&
  process.env.NODE_ENV !== 'production' &&
  process.env.NODE_ENV !== 'test'
    ? 'development'
    : process.env.NODE_ENV;

/**
 * To avoid overwritting NODE_ENV we'll use a custom env variable
 * so we could deploy a production build (using NODE_ENV=production)
 * while having settings for different environments
 */
const ENV: keyof ConfigOptions<any> =
  process.env.REACT_APP_ENGAGE_STORE_ENV !== 'development' &&
  process.env.REACT_APP_ENGAGE_STORE_ENV !== 'production' &&
  process.env.REACT_APP_ENGAGE_STORE_ENV !== 'staging' &&
  process.env.REACT_APP_ENGAGE_STORE_ENV !== 'test'
    ? NODE_ENV
    : process.env.REACT_APP_ENGAGE_STORE_ENV;

export function configFactory<R>(configOptions: ConfigOptions<R>) {
  const env = ENV;

  if (env in configOptions) {
    return configOptions[env];
  }

  throw new Error(`Couldn't find a valid config for env: ${env} for options: ${configOptions}`);
}
