import { Variant, Variants } from './types/variant';
import CatalogItem from './types/catalog-item';

export function getVariantImageUrl(
  variantId: string | undefined,
  defaultImageUrl: string,
  variants?: Variants
) {
  if (!variants || !variantId) {
    return defaultImageUrl;
  }

  return variants.reduce((variantImageUrl: string, variant: Variant) => {
    // eslint-disable-next-line eqeqeq
    if ((variant.id == variantId) && variant.imageUrl) {
      return variant.imageUrl;
    }
    return variantImageUrl;
  }, defaultImageUrl);
}

export const getAttrVariants = (attrName: keyof Variant) => (variants: Variant[] = []) =>
  variants
    .filter((variant: Variant) => !!variant.availableQuantity)
    .map((variant: Variant) => (variant[attrName] ? `${variant[attrName]}` : undefined))
    .filter((attr: string | undefined) => !!attr)
    .reduce((acc: string[], cur: string | undefined) => {
      if (cur && !acc.includes(cur)) {
        return [...acc, cur];
      }
      return acc;
    }, []);

export const getSizeVariants = getAttrVariants('size');
export const getColorVariants = getAttrVariants('color');

export const sortCatalogItemsByPoints = (catalogItems: CatalogItem[]) =>
  catalogItems.sort((a: CatalogItem, b: CatalogItem) => {
    if (a.points > b.points) return 1;
    if (a.points < b.points) return -1;
    return 0;
  });
