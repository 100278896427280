/**
 * Returns a copy of the given object without the given property
 */
export default function dissoc(
  property: string | number,
  object: { [property: string]: any }
) {
  const objectCopy = Object.assign({}, object);
  delete objectCopy[property];
  return objectCopy;
}
