import { getType } from 'typesafe-actions';

import assoc from '../../../utils/assoc';
import { LoaderState } from '../../types/state';
import { setLoader, LoaderAction } from './loader-action-creators';

const initialState = {};

export default function loaderReducer(
  state: LoaderState = initialState,
  action: LoaderAction
): LoaderState {
  switch (action.type) {
    case getType(setLoader):
      return assoc(action.meta.feature, action.payload, state);
    default:
      return state;
  }
}
