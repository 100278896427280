/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useTheme } from '@emotion/react';
import { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import VariantPicker from '../variant-picker';
import Card from '../../../common/components/card';
import Button from '../../../common/components/button';
import CatalogItem from '../../types/catalog-item';
import CatalogItemCardTitle from './catalog-item-card-title';
import { makeCartItemId } from '../../../cart/cart-utils';
import { addItemToCart } from '../../../cart/redux/cart-action-creators';
import { getVariantImageUrl } from '../../catalog-utils';
import CatalogItemCardPoints from './catalog-item-card-points';
import CatalogItemCardImage from './catalog-item-card-image';
import { filterVariantsFromCartItems } from '../../../cart/redux/cart-selectors';

type CatalogItemCardProps = CatalogItem & {
  userPoints: number;
  allowNegativeStock: boolean;
};

export default function CatalogItemCard({
  id,
  title,
  points,
  imageUrl,
  userPoints,
  variants,
  availableQuantity,
  allowNegativeStock,
}: CatalogItemCardProps) {
  const history = useHistory();
  const dispatch = useDispatch();
  const theme = useTheme();
  const [variantId, setVariantId] = useState('');
  const [showVariantPicker, setShowVariantPicker] = useState(false);

  /*
  These two values (attr1Value\attr2Value) represent the selected dropdown state values of the variant pickers.
  They were moved here because the claim button needs to control reverting the state to the default values.
  */
  const [attr1Value, setAttr1Value] = useState('');
  const [attr2Value, setAttr2Value] = useState('');

  const variantsFilteredByCart = useSelector(filterVariantsFromCartItems(variants))
  const shouldChooseVariant = variants ? (variants.length > 1) : false;

  const addToCart = useCallback(() => {
    if (id === undefined) return
    dispatch(addItemToCart(makeCartItemId(id, variantId)));
    history.push(`${history.location.pathname}?cart=open`);
  }, [id, dispatch, variantId, history]);

  const onToggle = useCallback(async () => {
    if (!variants) {
      addToCart();
    } else if (variants.length === 1) {
      if (!id || !variants[0].id) return
      dispatch(addItemToCart(makeCartItemId(id, variants[0].id)));
      history.push(`${history.location.pathname}?cart=open`);
      setVariantId('');
    }
    else {
      setShowVariantPicker(prev => !prev);
      setVariantId('');
    }
  }, [variants, addToCart, id, dispatch, history]);


  const onClaimVariant = useCallback(() => {
    setShowVariantPicker(false);
    addToCart();
    setVariantId('');
    setAttr1Value('');
    setAttr2Value('');
  }, [addToCart, setVariantId]);

  const shouldGrayOut = () => {
    if (userPoints < points) {
      return true;
    }
    if (allowNegativeStock) {
      return false;
    }

    return availableQuantity <= 0;
  }

  return (
    <div
      css={{
        flexGrow: 0,
        width: '100%',
        textDecoration: 'none',
        /**
         * Note: Fade out if user can't afford it or quantity = 0
         */
        opacity: shouldGrayOut() ? 0.5 : 1,
        padding: theme.spacing(1),
        [theme.breakpoint('xs')]: {
          width: '50%',
        },
        [theme.breakpoint('md')]: {
          width: '33.33%',
          padding: theme.spacing(2),
        },
      }}
    >
      <Card
        data-testid="catalog-item-card"
        css=
        {{
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)",
          borderRadius: 10,
          position: 'relative',
          transition: "all 0.1s cubic-bezier(0.165, 0.84, 0.44, 1)",
          '&:hover': {
            transform: "scale(1.05, 1.05)",

          },
        }}
      >
        <header css={{ display: 'flex', justifyContent: 'space-between' }}>
          <CatalogItemCardPoints points={points} />
          <Button disabled={shouldGrayOut()} size="sm" onClick={onToggle} outline>
            {showVariantPicker ? 'Cancel' : shouldChooseVariant ? 'Choose' : 'Claim It'}
          </Button>
        </header>
        { (id) &&
          <CatalogItemCardImage
            id={id}
            title={title}
            showVariantPicker={showVariantPicker}
            imageUrl={getVariantImageUrl(variantId, imageUrl ? imageUrl : "", variantsFilteredByCart)}
          />
        }
        { variantsFilteredByCart && showVariantPicker && (
          <Card
            css={{
              width: '45%',
              position: 'absolute',
              top: theme.spacing(8),
              right: theme.spacing(2),
            }}
          >
          { variants && variants.length > 1 && (
            <VariantPicker
              variants={variantsFilteredByCart}
              onPick={(setVariantId)}
              attr1Value={attr1Value}
              attr2Value={attr2Value}
              setAttr1Value={setAttr1Value}
              setAttr2Value={setAttr2Value}
            />
          )}
          <Button
            size="sm"
            css={{
              width: '100%',
              marginTop: theme.spacing(1),
            }}
            onClick={onClaimVariant}
            disabled={variantsFilteredByCart && !variantId}
            outline
          >
            Claim It
          </Button>
          </Card>
        )}
        { id && <CatalogItemCardTitle id={id} title={title} />}
      </Card>
    </div>
  );
}
