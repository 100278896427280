import { createAction, ActionType } from 'typesafe-actions';

import { FETCH, SET } from '../../common/redux/constants';
import { USER_ORDER_HISTORY } from '../../common/redux/types/feature';
import { UserOrderHistory } from '../types/user-order-history';

export const fetchUserOrderHistory = createAction(`${USER_ORDER_HISTORY} ${FETCH}`);

export const setUserOrderHistory = createAction(
  `${USER_ORDER_HISTORY} ${SET}`,
  action => (userOrderHistory: UserOrderHistory) => action(userOrderHistory)
);

export type UserOrderHistoryAction = ActionType<
  typeof setUserOrderHistory | typeof fetchUserOrderHistory
>;
