/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { withTheme, Theme } from '@emotion/react';
import { useSelector } from 'react-redux';

import { getShopSettings } from '../../shop-settings/redux/shop-settings-selectors';

interface EmptyCartMessageProps {
  theme: Theme;
}

function EmptyCartMessage({ theme }: EmptyCartMessageProps) {
  const shopSettings = useSelector(getShopSettings);
  return (
    <div
      css={{
        textAlign: 'center',
        padding: theme.spacing(2),
      }}
    >
      Need more points?{' '}
      <a
        css={{ color: theme.palette.accent.color, display: 'inline-block' }}
        href={shopSettings.homeUrl}
      >
        Watch or share a video
      </a>
      .
    </div>
  );
}

export default withTheme(EmptyCartMessage);
