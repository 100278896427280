/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useCallback } from 'react';
import { Provider as ReduxProvider } from 'react-redux';

import appConfig from './app-config';
import Shop from './shop/components/shop';

import makeReduxStore from './common/redux/make-store';
import makeAxiosInstance from './common/utils/make-axios-instance';
import makeHttpClient from './common/http-client/make-http-client';
import ShopThemeProvider from './shop-theme/components/shop-theme-provider';

import makeAuthWrapper from './auth-wrapper/make-auth-wrapper';

const AuthWrapper = makeAuthWrapper({
  authStatusEndpoint: `${appConfig.apiUrl}/auth/status`,
});

export default function App() {
  /**
   * Given to AuthWrapper to render the private app to propertly authenticated users
   */
  const renderPrivateApp = useCallback((authToken: string, onAuthFailure: () => void) => {
    const axiosInstance = makeAxiosInstance(appConfig.apiUrl, authToken, onAuthFailure);
    const httpClient = makeHttpClient(axiosInstance);
    const reduxStore = makeReduxStore(httpClient, window.localStorage);
    return (
      <ReduxProvider store={reduxStore}>
        <ShopThemeProvider>
          <Shop />
        </ShopThemeProvider>
      </ReduxProvider>
    );
  }, []);

  return <AuthWrapper renderPrivateApp={renderPrivateApp} />;
}
