/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useTheme } from '@emotion/react';
import { Link } from 'react-router-dom';

interface AdminPageTitlePrpos {
  title: string;
}

export default function AdminPageTitle({ title }: AdminPageTitlePrpos) {
  const theme = useTheme();
  return (
    <h2
      css={{
        display: 'flex',
        justifyContent: 'space-between',
        margin: 0,
        lineHeight: 1,
        fontWeight: 300,
        marginBottom: theme.spacing(1),
        color: theme.palette.accent.color,
      }}
    >
      <span>{title}</span>
      <Link
        css={{
          marginTop: theme.spacing(0.5),
          fontSize: '.7em',
          color: theme.palette.accent.color,
          fontWeight: 300,
          textDecoration: 'none',
          '&:hover': {
            textDecoration: 'underline',
          },
        }}
        to=""
      >
        &larr; back to store
      </Link>
    </h2>
  );
}
