/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { withTheme, Theme } from '@emotion/react';
import { useSelector } from 'react-redux';

import { getShopSettings } from '../../shop-settings/redux/shop-settings-selectors';

interface ShopLogoProps {
  theme: Theme;
}

function ShopLogo({ theme }: ShopLogoProps) {
  const shopSettings = useSelector(getShopSettings);
  return (
    <img
      css={{
        verticalAlign: 'bottom',
        height: theme.spacing(6),
      }}
      alt={shopSettings.shopName}
      src={shopSettings.shopLogoUrl}
      data-testid="shop-logo"
    />
  );
}

export default withTheme(ShopLogo);
