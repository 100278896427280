import { Breakpoint } from './types/shop-theme';

const WHITE = '#FFFFFF';
const GREEN = '#28a745';
const RED = '#dc3545';

const DARK_GREY = '#444444';
const LIGHT_GREY = '#DDDDDD';
const LIGHTER_GREY = '#EEEEEE';

const breakpoints: { [key: string]: number } = {
  xs: 480,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
};

const shopThemeDefaults = {
  typography: {
    fontSize: 16,
    fontFamily: 'Helvetica, Arial, sans-serif',
  },
  palette: {
    common: {
      white: WHITE,
      green: GREEN,
      red: RED,
      darkGrey: DARK_GREY,
      lightGrey: LIGHT_GREY,
      lighterGrey: LIGHTER_GREY,
    },
    accent: {
      color: '#ff6160',
      contrastColor: WHITE,
    },
    card: {
      color: DARK_GREY,
      background: WHITE,
    },
    body: {
      color: DARK_GREY,
      background: LIGHTER_GREY,
    },
  },
  zIndex: {
    bg: -1,
    header: 2,
    modalOverlay: 3,
    modalBody: 4,
    alert: 5,
    notification: 6,
  },
  spacing: (size: number) => size * 8,
  breakpoint: (breakpoint: Breakpoint) => `@media (min-width: ${breakpoints[breakpoint]}px)`,
  breakpointMax: (breakpoint: Breakpoint) => `@media (max-width: ${breakpoints[breakpoint]}px)`,
};

export default shopThemeDefaults;
