import Axios, { AxiosError } from 'axios';

export default function makeAxiosInstance(
  baseURL: string,
  authToken: string,
  onAuthFailure: () => void
) {
  const axiosInstance = Axios.create({
    baseURL,
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  });

  axiosInstance.interceptors.response.use(
    res => res,
    (e: AxiosError) => {
      if (
        e.response &&
        (e.response.status === 401 || e.response.status === 403)
      ) {
        onAuthFailure();
      }
      return Promise.reject(e);
    }
  );

  return axiosInstance;
}
