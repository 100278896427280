/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { withTheme, Theme } from '@emotion/react';


interface AccentBgProps {
  theme: Theme;
}

function AccentBg(props: AccentBgProps) {
  return (
    <div
      data-testid="accent-bg"
      css={{
        top: 0,
        left: 0,
        right: 0,
        position: 'absolute',
        zIndex: props.theme.zIndex.bg,
        background: props.theme.palette.accent.color,
        bottom: 94,
        [props.theme.breakpoint('md')]: {
          bottom: 110,
        },
      }}
      {...props}
    />
  );
}

export default withTheme(AccentBg);
