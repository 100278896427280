import { createSelector } from 'reselect';

import { CATALOG } from '../../common/redux/types/feature';
import { getLoaderState } from '../../common/redux/ui/ui-selectors';
import State, { CatalogState, CatalogById, LoaderState } from '../../common/redux/types/state';
import { VariantOption } from '../types/variant';

const getCatalogState = (state: State) => state.catalog;

export const getCatalogById = createSelector(
  getCatalogState,
  (catalogState: CatalogState) => catalogState.data
);

export const getCatalogArray = createSelector(getCatalogById, (catalogById: CatalogById) => {
  return Object.values(catalogById);
});

export const getCatalogVariantOptions = createSelector(getCatalogById, (catalogById: CatalogById) => {
  let variantOptions: VariantOption[] = []
  Object.values(catalogById).forEach((item) => {
    item.variants?.forEach((variant) => {
      if (variant.id) {
        variantOptions.push({value: parseInt(variant.id), label: `${item.title} ${variant.color} ${variant.size}`})
      }
    });
  });
  return variantOptions;
});

export const getHasFetchedCatalog = createSelector(
  getCatalogState,
  (catalogState: CatalogState) => catalogState.meta.fetched
);

export const getFetchingCatalog = createSelector(
  getLoaderState,
  (loaderState: LoaderState) => loaderState[CATALOG]
);
