export const ORDER_API_URL = '/user/order';
export const CATALOG_API_URL = '/shop/catalog';
export const CATALOG_ITEM_API_URL = '/shop/catalog-item';
export const CATALOG_ITEM_VARIANT_API_URL = '/shop/catalog-item-variant';
export const INVENTORY_API_URL = '/admin/inventory'
export const SHOP_SETTINGS_API_URL = '/shop/settings';
export const USER_PROFILE_API_URL = '/user/profile';
export const USER_SEARCH_API_URL = '/admin/user/search';
export const USER_SET_POINTS_API_URL = '/admin/user/points';
export const USER_PARTICIPATION_HISTORY_API_URL = '/user/rewards';
export const USER_ORDER_HISTORY_API_URL = '/user/order';
export const AUTH_API_URL = '/auth/status';
export const UPLOAD_ITEM_IMAGE_API_URL = '/shop/catalog-item/upload-image';
export const UPLOAD_ITEM_SIZECHART_IMAGE_API_URL = '/shop/catalog-item/upload-sizechart-image';
export const UPLOAD_ITEM_VARIANT_IMAGE_API_URL = '/shop/catalog-item-variant/upload-image';

export type ApiUrl =
  | typeof ORDER_API_URL
  | typeof CATALOG_API_URL
  | typeof CATALOG_ITEM_API_URL
  | typeof CATALOG_ITEM_VARIANT_API_URL
  | typeof SHOP_SETTINGS_API_URL
  | typeof USER_PROFILE_API_URL
  | typeof USER_SEARCH_API_URL
  | typeof USER_SET_POINTS_API_URL
  | typeof USER_PARTICIPATION_HISTORY_API_URL
  | typeof USER_ORDER_HISTORY_API_URL
  | typeof AUTH_API_URL
  | typeof UPLOAD_ITEM_IMAGE_API_URL
  | typeof UPLOAD_ITEM_SIZECHART_IMAGE_API_URL
  | typeof UPLOAD_ITEM_VARIANT_IMAGE_API_URL
  | string;

export default ApiUrl;
