/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx } from '@emotion/core';

import CatalogItem from '../../../catalog/types/catalog-item';
import Card from '../../../common/components/card';
import { useTheme } from '@emotion/react';
import { ChangeEvent, FormEvent, Fragment, useCallback, useEffect, useState } from 'react';
import { Form, FormField, FormFieldRow, FormRow, Input, Textarea } from '../../../common/components/form';
import AddInventoryItemVariant from './add-inventory-item-variant';
import ImageDropzone from '../../../image-uploader/components/image-dropzone';
import EditableInventoryItemVariantList from './editable-inventory-item-variant-list';
import { ReactComponent as DeleteIcon } from '@fortawesome/fontawesome-pro/svgs/solid/trash.svg';
import { ReactComponent as RestoreIcon } from '@fortawesome/fontawesome-pro/svgs/solid/trash-undo.svg';
import Button from '../../../common/components/button';
import { useDispatch } from 'react-redux';
import { deleteCatalogItem, updateCatalogItem } from '../../../catalog-item/redux/catalog-item-action-creators';

interface EditableInventoryItemProps {
  item: CatalogItem;
  entityName: string;
  isLoading: boolean;
  showDeletedVariants: boolean;
}

export default function EditableInventoryItem({
  item,
  entityName,
  isLoading,
  showDeletedVariants

}: EditableInventoryItemProps) {
  const theme = useTheme();
  const dispatch = useDispatch();


    const [newItem, setNewItem] = useState<CatalogItem>(item);

  const updateNewItem = useCallback((key: string, value: string | number | boolean) => {
    setNewItem(prevNewItem => {
      return key in prevNewItem ? { ...prevNewItem, [key]: value } : prevNewItem;
    });
  }, []);

  const isNumber = (x: any) => {
    const parsed = parseInt(x);
    if (isNaN(parsed)) return false;
    return parsed;
  }

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
      let { id, value } = e.target;
      if (id !== "points" && id !== "availableQuantity") {
        updateNewItem(id, value);
        return;
      }
      if (value !== '-' && value !== '0') {
        if (!isNumber(value)) {
          value = '';
          return;
        }
      }
      updateNewItem(id, parseInt(value));
  }

  const onCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = e.target;
    if (checked) updateNewItem(id, true);
    else  updateNewItem(id, false);
  }

  const onTextAreaChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
      const { id, value } = e.target;
      updateNewItem(id, value);
  }

    useEffect(() => {
      setNewItem(item);
    }, [item]);

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    dispatch(updateCatalogItem(newItem));
  }

  // if a variant is missing size when another variant contains size, present a warning
  const renderItemWarning = () => {
    if(item.variants){
      const nonDeletedVariants = item.variants.filter(variant => !variant.deletedAt);
      if (nonDeletedVariants.some(variant => variant.size) && nonDeletedVariants.some(variant => !variant.size)) {
        return (
          <div css={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
            backgroundColor: theme.palette.common.red,
            borderRadius: theme.spacing(1),
            boxShadow: `0px 0px 3px ${theme.palette.common.darkGrey}`,
          }}>
            <p css={{
              padding: theme.spacing(1),
            }}>
              There are variants with size set and variants without size set.
              Please <strong>set size </strong>on all variants.
            </p>
            </div>
        );
      }
    }
  }

  return (
    <Fragment>
      <div data-testid={`editable-inventory-item-${item.id}`} css={{display: 'block', height: "auto", padding: theme.spacing(2)}}>
          <Form data-testid="add-inventory-item-variant" onSubmit={onSubmit} css={{ border: theme.palette.card.color, padding: theme.spacing(1) }}>
            <FormFieldRow label={"Title"} inputId={ 'title'}>
                <Input required id='title' value={newItem.title} onChange={onInputChange} css={{width: '100%'}} />
            </FormFieldRow>
            <FormRow>
              <FormFieldRow inputId='description' label='Description'>
                <Textarea id='description' value={newItem.description} onChange={onTextAreaChange} />
              </FormFieldRow>
            </FormRow>
            <FormRow>
              <FormField inputId='points' label='Points'>
                <Input id='points' value={newItem.points} onChange={onInputChange} />
              </FormField>

              {!item.variants &&
                <FormField inputId="availableQuantity" label="Quantity">
                  <Input id="availableQuantity" value={newItem.availableQuantity} onChange={onInputChange} />
                </FormField>
              }
              <FormField inputId="buyerItemNumber" label="Buyer Number">
                <Input id="buyerItemNumber" value={newItem.buyerItemNumber} onChange={onInputChange} />
              </FormField>
              <FormField inputId="unitOfMeasure" label="Unit of Measure">
                <Input id="unitOfMeasure" value={newItem.unitOfMeasure} onChange={onInputChange} />
              </FormField>
              <FormField inputId="visible" label="Visible">
                <Input type="checkbox" id="visible" checked={newItem.visible} onChange={onCheckboxChange} css={{width:"40px"}} />
              </FormField>
            </FormRow>
            <FormRow css={{justifyContent: 'space-between', alignItems: 'center'}}>
              <Button css={{ margin: 8, height: 36 }} type="submit" size="sm">
                Save
              </Button>
              {
                item.deletedAt ?
                <RestoreIcon
                  title="Restore item"
                  fill={theme.palette.common.darkGrey}
                  css={{
                    height: '35px',
                    cursor: 'pointer',
                    '&:hover': {
                      fill: theme.palette.common.green
                    }
                  }}
                  onClick={(e)=> {
                    if (e.ctrlKey || window.confirm(`Are you sure you want to restore this item?\nHold ctrl key while clicking restore to skip this warning.`)) {
                      dispatch(updateCatalogItem({...item, deletedAt: null}))
                    }
                  }}
                />
                :
                <DeleteIcon
                  title="Delete item"
                  fill={theme.palette.common.darkGrey}
                  css={{
                    height: '35px',
                    cursor: 'pointer',
                    '&:hover': {
                      fill: theme.palette.common.red
                    }
                  }}
                  onClick={(e) => {
                    if (e.ctrlKey || window.confirm(`Are you sure you want to delete this item?\nHold ctrl key while clicking delete to skip this warning.`)) {
                      dispatch(deleteCatalogItem(item));
                    }
                  }}
                />
              }
            </FormRow>
          </Form>
        {/* Dropzones */}
          <div css={{display: "block"}}>
            <div css={{display: "flex", height: 'auto', flexWrap: 'wrap', justifyContent: 'space-evenly'}}>
              <div data-testid={`item-image-dropzone-${item.id}`} css={{ textAlign: "center", width: '350px', maxWidth: '350px', display: 'block', maxHeight: '300px'}}>
                <strong>Image</strong>
                <ImageDropzone populateImageUrl={item.imageUrl} id={item.id} imageFor="item" onDelete={() => dispatch(updateCatalogItem({...item, imageUrl: ''}))}/>
              </div>
              <div data-testid={`item-sizechart-dropzone-${item.id}`} css={{textAlign:"center", width: '350px', maxWidth: '350px', display: 'block', maxHeight: '300px'}}>
                <strong>Size Chart</strong>
                <ImageDropzone populateImageUrl={item.sizeChart} id={item.id} imageFor="sizechart" onDelete={() => dispatch(updateCatalogItem({...item, sizeChart: ''}))}/>
              </div>
            </div>
          </div>
        {renderItemWarning()}
        <div data-testid={`item-variant-list-${item.id}`}>
          <h3 css={{color: theme.palette.accent.color, fontWeight: 300}}>Variants</h3>
          {item.variants && <EditableInventoryItemVariantList entityName={entityName} isLoading={isLoading} variants={item.variants} showDeletedVariants={showDeletedVariants} disableRestore={Boolean(item.deletedAt)}/>}
        </div>
        <Card css={{ borderRadius: '10px', marginTop: theme.spacing(2) }} title={`Add New Item Variant for: ${item.title}`} toggleable isAddNew closed>
          <AddInventoryItemVariant itemId={item.id}/>
        </Card>
      </div>

    </Fragment>
  )
}

EditableInventoryItem.defaultProps = {
    showDeletedVariants: false
}
