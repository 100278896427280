/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Page from '../../common/components/page';
import CatalogItemDetails from '../components/catalog-item-details';
import { addItemToCart } from '../../cart/redux/cart-action-creators';
import { getCatalogById, getHasFetchedCatalog } from '../redux/catalog-selectors';
import { getRemainingUserPoints } from '../../user/redux/user-selectors';
import { getShopSettings } from '../../shop-settings/redux/shop-settings-selectors';

interface CatalogItemPageProps {
  history: {
    push: (path: string) => void;
  };
  match: {
    url: string;
    params: {
      calalogItemId: string;
    };
  };
}

export default function CatalogItemPage({ history, match }: CatalogItemPageProps) {
  const dispatch = useDispatch();
  const shopSettings = useSelector(getShopSettings);
  const userPoints = useSelector(getRemainingUserPoints);
  const hasFetchedCatalog = useSelector(getHasFetchedCatalog);
  const catalogItem = useSelector(getCatalogById)[match.params.calalogItemId];

  const openCartAndAddCatalogItem = useCallback(
    (id: string) => {
      history.push(`${match.url}?cart=open`);
      dispatch(addItemToCart(id));
    },
    [history, match, dispatch]
  );

  useEffect(() => {
    /**
     * Redirect user to the catalog page if the catalog item is not found.
     */
    if (hasFetchedCatalog && !catalogItem) {
      history.push('/');
    }
  }, [catalogItem, hasFetchedCatalog, dispatch, history]);

  return hasFetchedCatalog && catalogItem ? (
    <Page title={`${shopSettings.shopName}: ${catalogItem.title}`}>
      <CatalogItemDetails
        allowNegativeStock={shopSettings.allowNegativeStock}
        item={catalogItem}
        userPoints={userPoints}
        addToCart={openCartAndAddCatalogItem}
      />
    </Page>
  ) : null;
}
