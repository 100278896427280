import React from 'react';

export default function LoginPage(props: { loginUrl: string; loginMessage: string }) {
  return (
    <div
      style={{
        color: '#444444',
        display: 'flex',
        minHeight: '100vh',
        fontFamily: 'sans-serif',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div style={{ padding: 16, background: '#eeeeee', marginBottom: 16 }}>
        {props.loginMessage}
      </div>
      <a
        style={{
          color: '#eeeeee',
          padding: '12px 24px',
          background: '#444444',
          textDecoration: 'none',
          display: 'inline-block',
        }}
        href={props.loginUrl}
      >
        Login
      </a>
    </div>
  );
}
