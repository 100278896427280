/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { RouteProps } from 'react-router';

export default function StoreSettingsModal(props: RouteProps) {
  return props.location &&
    props.location.hash.indexOf('shop-settings') === -1 ? null : (
    <div>Shop Settings</div>
  );
}
