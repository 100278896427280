import { getType, isActionOf } from 'typesafe-actions';

import { UserParticipationHistoryState } from '../../common/redux/types/state';
import {
  UserParticipationHistoryAction,
  setUserParticipationHistory,
} from './user-participation-history-action-creators';

const initialState = {
  data: [],
  meta: {
    fetched: false,
  },
};

function userParticipationHistoryReducer(
  state: UserParticipationHistoryState = initialState,
  action: UserParticipationHistoryAction
): UserParticipationHistoryState {
  switch (action.type) {
    case getType(setUserParticipationHistory):
      if (isActionOf(setUserParticipationHistory, action)) {
        return {
          ...state,
          data: action.payload,
          meta: { fetched: true },
        };
      }
      return state;
    default:
      return state;
  }
}

export default userParticipationHistoryReducer;
