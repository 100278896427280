/** @jsxRuntime classic */
/** @jsx jsx */
import { withTheme, Theme } from '@emotion/react';
import { Global, jsx, css } from '@emotion/core';


function ShopGlobalStyles({ theme }: { theme: Theme }) {
  return (
    <Global
      styles={css({
        '*': {
          boxSizing: 'border-box',
        },
        body: {
          margin: 0,
          padding: 0,
          lineHeight: 1.75,
          paddingTop: theme.spacing(8),
          fontSize: theme.spacing(2),
          color: theme.palette.body.color,
          background: theme.palette.body.background,
          fontFamily: theme.typography.fontFamily,
        },
      })}
    />
  );
}

export default withTheme(ShopGlobalStyles);
