/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { withTheme, Theme } from '@emotion/react';

import { Heading } from '../../../common/components/typography';

interface TitleProps {
  theme: Theme;
  title: string;
}

const Title = withTheme(({ theme, title }: TitleProps) => (
  <Heading
    as="h1"
    css={{
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
      [theme.breakpoint('md')]: {
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(1),
      },
      color: theme.palette.accent.contrastColor,
    }}
  >
    {title}
  </Heading>
));

export default Title;
