import { NewShippingAddress } from '../types/shipping';

export function validateAddress({
  street_address_1,
  street_address_2,
  city,
  state,
  zipcode,
}: NewShippingAddress) {
  const optional_address_2 = true;
  return !!street_address_1 && optional_address_2 && !!city && !!state && !!zipcode;
}
