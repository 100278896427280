/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { withTheme, Theme } from '@emotion/react';
import { useSelector } from 'react-redux';

import { getUser } from '../../user/redux/user-selectors';

interface ShopGreetingProps {
  theme: Theme;
}

function ShopGreeting({ theme }: ShopGreetingProps) {
  const user = useSelector(getUser);
  return (
    <p
      css={{
        fontStyle: 'italic',
        fontSize: '1.250em',
        textAlign: 'center',
        marginBottom: theme.spacing(3),
        color: theme.palette.body.color,
      }}
      data-testid="shop-greeting"
    >
      <strong>
        Welcome, {user.firstName}!
      </strong>
    </p>
  );
}

export default withTheme(ShopGreeting);
